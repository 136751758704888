<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <!--Don´t show lateral menu on several pages-->
        <div id="container-with-menu" v-if='this.$route.path !== "/permissions" && this.$route.path !== "/groupsList" 
            && this.$route.path !== "/rolesList" && this.$route.path !== "/profiles" && this.$route.path !== "/usersList"'>

            <div class="layout-main-container">
                <div class="layout-sidebar" @click="onSidebarClick">
                    <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
                </div>
                <div class="layout-main">
                    <router-view :socket="socket"/>
                </div>
                <AppFooter />
            </div>
        </div>

        <div v-else class="container-without-menu">
            <div class="layout-main">
                <router-view :socket="socket"/>
            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
    <Toast />
    <Dialog v-model:visible="display" closable="false">
        Content
        <template #footer>
            <Button label="ok" autofocus @click="closeSession"/>
        </template>
    </Dialog>

    <DownloadBox :progress="progress"/>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import DownloadBox from './components/DownloadBox.vue';
import AppFooter from './AppFooter.vue';
import { io } from "socket.io-client";
import { logout } from '@/helpers/UserHelper';
// import execute from './domain/services/interceptor.js';
 
export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            progress: 0,
            menu : [
                // {
                //     label: 'Principal',
                //     items: [{
                //         label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
                //     }]
                // },
            ],
            socket: null,
            display: false,
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        closeSession() {
            logout();
            this.display = false;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo.png" : "images/logo.png";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        DownloadBox
    },
    async mounted() {

        // this.display = execute() === 'closeSession' ? true : false;

		const { userId } = await this.$store.getters['user/getCurrentUser'];

        this.$store.dispatch('notification/getNewNotifications', userId)
		.then( async() => {
			// console.log(data);
		})
		.catch(() => {
			// console.log(e)
            this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});

		this.socket = io(`${process.env.VUE_APP_NOTIFICATIONS_SOCKET}`, { transports : ['websocket'] });
		this.socket.auth = { username: `socket#${userId}` };

		this.socket.on('connect', () => {
			console.log('connected')
		});

        this.socket.on("private notification", async ({ post }) => {
			let notificationsList = [];
            this.$store.dispatch('notification/getListNotifications')
            .then( async(data) => {
                data?.notificationList.forEach(element => {
                    notificationsList.push({ publisher: element.publisher, post: element.post, createddate: element.createddate, read: element.read })
                });
                await this.$store.dispatch('notification/addNotificationToArrayAction', notificationsList);
            })
            .catch((e) => {
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
                this.isLoading = false;
            });

			await this.$store.dispatch('notification/addNotification');
			this.$toast.add({severity:'info', summary: 'Mensaje recibido', detail: post, life: 3000});
		});

		this.socket.on('connect_error', (err) => {
			console.log(err);
		});

        this.socket.on('upload progress', (e) => {
            this.progress = e;
        })
	},
	unmounted() {
		this.socket.off("connect_error");
	},
}
</script>

<style lang="scss">
@import './App.scss';
</style>
