<template>
	<div class="isLoadingDiv" v-if="isLoadingDiv">
		<div class="spinnerDiv" id="textnode">
			<ProgressSpinner/>
		</div>
	</div>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
		</router-link>
		<!-- <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button> -->

		<Menubar :model="nestedMenuitems">
		</Menubar>
		<Button label="Nuevo" icon="pi pi-plus" iconPos="left" class="p-button-sm create-project" @click="openNew" v-if="isAdmin" />
		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<div class="searchFileInput">
			<span class="p-input-icon-left w-full">
				<i class="pi pi-search" />
				<InputText v-model="searchFile" placeholder="Buscar archivo" class="w-full" @keypress.enter.prevent="goToSearchFile()" />
			</span>
		</div>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button" @click="goToNotifications">
					<i v-if="getNotification == 0" class="pi pi-bell p-text-secondary" ></i>
					<i v-else class="pi pi-bell p-text-secondary" v-badge.info></i>
					<span>Notificaciones</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="goToCalendar">
					<i class="pi pi-calendar"></i>
					<span>Eventos</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="openIncidentDialog">
					<!-- <font-awesome-icon icon="fa-sharp fa-regular fa-bug" size="2x" />
 -->
					<i class="pi pi-question-circle"></i>
					<span>Reportar error</span>
				</button>
				<TieredMenu ref="menu_settings" :model="items_settings" :popup="true" />
			</li>
			<li>
				<button class="p-link layout-topbar-button" v-if="isAdmin" @click="toggle_settings">
					<i class="pi pi-cog"></i>
					<span>Configuración</span>
				</button>
				<TieredMenu ref="menu_settings" :model="items_settings" :popup="true" />
			</li>
			<li>
				
				<button class="p-link layout-topbar-button" @click="toggle">
					<i class="pi pi-user"></i>
					<span>Perfil</span>
				</button>
				<TieredMenu ref="menu" :model="items" :popup="true" />
			</li>
		</ul>
	</div>
	<Dialog v-model:visible="projectDialog" :style="{width: '450px'}" header="Crear nuevo proyecto" :modal="true" class="p-fluid">
		<div class="field">
			<label for="name">Título</label>
			<InputText id="name" v-model="folderName" required="true" autofocus :class="{'p-invalid': submitted}" />
			<Dropdown class="mt-3" emptyMessage="No existen proyectos plantilla" v-model="selectedCopy" :options="copied" optionLabel="name" placeholder="Sin plantilla" />
			<small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small>

			<div class="mt-6"></div>
			<Checkbox id="privateCheckbox" v-model="checkedPrivate" :binary="true" />
			<label for="privateCheckbox" class="ml-2"> Privado </label>
		</div>
		<template #footer>
			<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
			<Button label="Guardar" :disabled="folderName.trim().length < 3" icon="pi pi-check" class="p-button-text" @click="saveProject" />
		</template>
	</Dialog>
	<Dialog v-model:visible="reportBug" :style="{width: '450px'}" header="Reportar una incidencia" :modal="true" class="p-fluid">
		<div class="field">
			<Dropdown class="mb-3" emptyMessage="" v-model="selectedIncidentType" :options="incidentTypes" optionLabel="name" placeholder="Selecciona un tipo" />
			<label for="title">Título</label>
			<InputText id="title" v-model="incidentTitle" required="true" autofocus :class="` ${submitted ? 'p-invalid' : ''} mb-3`" />
			<label for="description">Descripción</label>
			<Textarea id="description" v-model="incidentDescription" rows="5" cols="30"/>
		</div>
		<template #footer>
			<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideIncidentDialog"/>
			<Button label="Guardar" :disabled="selectedIncidentType != null && incidentTitle.trim().length <= 3" icon="pi pi-check" class="p-button-text" @click="saveIncident" />
		</template>
	</Dialog>
	<Toast />
</template>

<script>
import { logout } from '@/helpers/UserHelper';

//TODO:
 // - Add i18n

export default {
	data() {
        return {
			selectedCopy: null,
			copied: [
			],
			incidentTypes: [
				{
					name: 'Incidencia', 
					code: 'BUG'
				},
				{
					name: 'Mejora', 
					code: 'IDEA'
				}
			],
			selectedIncidentType: null,
			reportBug: false,
			incidentTitle: '',
			incidentDescription: '',
			// modules: [
			// 	{
			// 		name: 'Login', 
			// 		code: 'LOGIN'
			// 	},
			// 	{
			// 		name: 'Recuperar contraseña', 
			// 		code: 'IDEA'
			// 	}
			// ],
			nestedMenuitems: [
					{
						label:'Inicio',
						icon:'pi pi-fw pi-home',
						command: () => { this.goToDashboard() }
					},
					{
						label:'Proyectos',
						icon:'pi pi-fw pi-folder',
						command: () => { this.goToProjects() }
					}
				],
            items: [
                {
                    label:'Mi Perfil',
                    icon:'pi pi-fw pi-pencil',
					command: () => { this.goToEditProfile() }
                },
                {
                    separator:true
                },
                {
                    label:'Salir',
                    icon:'pi pi-fw pi-power-off',
					command: () => { logout() }
                }
            ],
			items_settings: [
				{
                    label:'Usuarios',
                    icon:'pi pi-fw pi-user',
					command: () => { this.goToUsersList() }
                },
                {
                    label:'Grupos',
                    icon:'pi pi-fw pi-users',
					command: () => { this.goToGroupList() }
                }                ,
				{
                    label:'Permisos',
                    icon:'pi pi-fw pi-key',
					command: () => { this.goToPermissionsList() }
                },
				{
                    label:'Perfiles',
                    icon:'pi pi-fw pi-user',
					command: () => { this.goToProfilesList() }
                },
				{
                    label:'Roles',
                    icon:'pi pi-fw pi-user-plus',
					command: () => { this.goToRolesList() }
                },
				{
                    label:'Editar plantillas',
                    icon:'pi pi-fw pi-pencil',
					command: () => { this.goToeditTemplates() }
                },
				
            ],
			submitted: false,
			projectDialog: false,
			checkedPrivate: false,
			folderName: '',
			searchFile: '',
			isLoadingDiv: false,
        }
    },
	async mounted() {
		await this.loadDataTemplate();
	},
    methods: {
		goToSearchFile(){
			this.$router.push('/searchList/' + this.searchFile); 
		},
		goToeditTemplates(){
			this.$router.push('/editTemplates'); 
		},
		hideIncidentDialog() {
			this.reportBug = false;
		},
		saveIncident() {
			this.reportBug = false;
		},
		openIncidentDialog() {
			this.reportBug = true;
		},
		loadDataTemplate() {
			this.copied = [];

			this.$store.dispatch('project/getAllProjectTemplate').then((e) => {
				e.forEach(d => {
					this.copied.push({name: d.name, code: d.id});
				})
			}).catch(() => {
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			});
		},
		async openNew() {
			this.loadDataTemplate();
			this.submitted = false;
			this.checkedPrivate = false;
			this.projectDialog = true;
		},
		hideDialog() {
			this.projectDialog = false;
			this.submitted = false;
		},
		async saveProject() {
			this.submitted = true;
			this.projectDialog = false;
			this.isLoadingDiv = true;

			this.submitted = true;
			this.projectFolderDialog = false;
			//Reload folder tree
			await this.$store.dispatch('azure/createFolder', {
				name: this.folderName.replace(/^\/\*-\.;,+/i, ''),
				creationdate: new Date(),
				selectedCopy: this.selectedCopy ? parseInt(this.selectedCopy.code) : false,
				type: 'PROJECT',
				createdby: this.getUser.id,
				planned: false,
				privateType: this.checkedPrivate,
				priority: 'Low',
				idparent: null,
			}).then(
				() => {
					this.folderName = '';
					this.$toast.add({severity:'success', summary: 'Proyecto creado', detail:'Proyecto creado correctamente', life: 3000});
					this.isLoadingDiv = false;
				}
			).catch(() => {
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				this.isLoadingDiv = false;
			})
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
		},
		goToEditProfile(){
			this.$router.push('/editProfile'); 
		},
		goToUsersList(){
			this.$router.push('/usersList'); 
		},
		goToGroupList(){
			this.$router.push('/groupsList'); 
		},
		goToPermissionsList(){
			this.$router.push('/permissions'); 
		},
		goToProfilesList(){
			this.$router.push('/profiles'); 
		},
		goToRolesList(){
			this.$router.push('/rolesList'); 
		},
		goToDashboard(){
			this.$router.push('/'); 
		},
		goToProjects(){
			this.$router.push('/projects'); 
		},
		goToNotifications(){
			this.$router.push('/notifications'); 
		},
		goToCalendar(){
			this.$router.push('/calendar'); 
		},
		toggle(event) {
			this.$refs.menu.toggle(event);
		},
		toggle_settings(event) {
			this.$refs.menu_settings.toggle(event);
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		isAdmin() {
			return this.$store.getters['user/isAdmin'];
		},
		getUser() {
			return this.$store.getters['user/getCurrentUser'];
		},
		getNotification() {
			return this.$store.getters['notification/getCount'];
		},
	}
}
</script>

<style>

.p-menubar {
	margin-left: 25px;
	border: none!important;
	background: transparent!important;
}

.p-submenu-list{
	z-index: 2!important;
}

@media (max-width: 991px) {
	.create-project {
		display: none;
	}
}

.searchFileInput {
	margin-left: 22vw; width: 650px;
}

@media (max-width: 1400px) {
	.searchFileInput {
		margin-left: 8vw; width: 350px;
	}
}


</style>