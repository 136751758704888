import {
  createVerification,
  getVerifications,
  createMilestones,
  updateMilestones,
  deleteVerification,
  deleteMilestone,
  updateMilestonesChecked,
  addLinkVerificationFile,
  updateVerification,
  getVerificationsByUserId,
  removeLinkVerificationFile,
} from './verificationActions';

const tempo = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    createVerification,
    getVerifications,
    createMilestones,
    updateMilestones,
    deleteVerification,
    deleteMilestone,
    updateMilestonesChecked,
    getVerificationsByUserId,
    addLinkVerificationFile,
    updateVerification,
    removeLinkVerificationFile,
  },
  getters: {
  },
};

export default tempo;
