<template>
<div id="live-chat" class="shadow-3">
		
        <header class="clearfix">
            
            <a href="#" @click.prevent="resize" class="chat-close" ref="icon">{{ icon }}</a>
    
            <h4>Subidas</h4>
    
            <span class="chat-message-counter">3</span>
    
        </header>
    
        <div class="chat" ref="uploads">
            
            <div class="upload" ref="boxAnimation">
    <div class="text" v-if="name.length > 0">
        <strong>{{ name }}</strong>
        <div>
            <small>%</small>
            <!-- <div>
                <small>
                    <span data-seconds>0</span> s aprox.
                </small>
                <small>Pausado</small>
            </div> -->
        </div>
    </div>
    <div class="text" v-else>
        <strong>No estas realizando ninguna subida</strong>
    </div>
    <div class="percent">
        <span></span>
        <div>
            <svg preserveAspectRatio="none" viewBox="0 0 600 12" width="50px">
                <path d="M0,1 L200,1 C300,1 300,11 400,11 L600,11" stroke="currentColor" fill="none"></path>
            </svg>
        </div>
    </div>
</div>
        </div> <!-- end chat -->
    
    </div> <!-- end live-chat -->
</template>

<script>

export default {
    data() {
        return {
            currentUserId: '1234',
            rooms: [],
            messages: [],
            icon: '+',
            roomActions: [
            { name: 'inviteUser', title: 'Invite User' },
            { name: 'removeUser', title: 'Remove User' },
            { name: 'deleteRoom', title: 'Delete Room' }
            ],
            name: '',
        }
    },
    props: {
        progress: {
            type: Object || Number,
            default: 0,
        }
    },
    mounted() {
        console.log(this.progress);
        this.$refs.boxAnimation.style.setProperty('--percent', Math.floor(
            this.progress
        ));
    },
    methods: {
        resize() {
            if(this.icon === '-') {
                this.$refs.uploads.style.height = '0';
                this.icon = '+';
            } else {
                this.$refs.uploads.style.height = '125px';
                this.icon = '-';
            }
        },
    },
    watch: {
        progress(e) {
            console.log(e)
            if(e?.name) {
                if(this.icon === '+') this.resize();
                this.name = e.name;
                this.$refs.boxAnimation.style.setProperty('--percent', Math.floor(
                    0
                ));
            }
            if(e?.done) {
                this.$refs.boxAnimation.style.setProperty('--percent', Math.floor(
                    100
                ));

                this.$store.commit('azure/addFiles', e.id);
            }
            if(e?.range) {
                this.$refs.boxAnimation.style.setProperty('--percent', Math.floor(
                    (e.range.minValue / e.range.maxValue) * 100
                ));
            }
        },
    }
}
</script>

<style scoped>

#live-chat {
	bottom: 0;
	font-size: 12px;
	right: 24px;
	position: fixed;
	width: 300px;
    z-index: 999999999999;
}

#live-chat header {
	background: #002680;
	border-radius: 5px 5px 0 0;
	color: #fff;
	cursor: pointer;
	padding: 4px 26px;
}

#live-chat h4 {
	font-size: 14px;
}

#live-chat h5 {
	font-size: 10px;
}

#live-chat form {
	padding: 24px;
}

#live-chat input[type="text"] {
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 8px;
	outline: none;
	width: 234px;
}

.chat-message-counter {
	background: #e62727;
	border: 1px solid #fff;
	border-radius: 50%;
	display: none;
	font-size: 12px;
	font-weight: bold;
	height: 28px;
	left: 0;
	line-height: 28px;
	margin: -15px 0 0 -15px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 28px;
}

.chat-close {
    background: #002680;
    border-radius: 50%;
    color: #fff;
    display: block;
    float: right;
    font-size: 22px;
    height: 16px;
    line-height: 12px;
    margin: 20px 0 0 0;
    text-align: center;
    width: 16px;
}

.chat {
    height: 0;
	background: #fff;
    transition: all 300ms linear;
}

.chat-history {
	height: 252px;
	padding: 8px 24px;
	overflow-y: scroll;
}

.chat-message {
	margin: 16px 0;
}

.chat-message img {
	border-radius: 50%;
	float: left;
}

.chat-message-content {
	margin-left: 56px;
}

.chat-time {
	float: right;
	font-size: 10px;
}

.chat-feedback {
	font-style: italic;	
	margin: 0 0 0 80px;
}
</style>

<style lang="scss" scoped>
:root {
    --primary: #5628EE;
    --success: #0EAC70;
    --grey-light: #99A3BA;
    --grey: #6C7486;
    --grey-dark: #3F4656;
    --light: #CDD9ED;
    --lighter: #E4ECFA;
    --lightest: #EEF4FF;
    --pale: #F5F9FF;
    --shadow: rgba(18, 22, 33, .05);
}

.upload {
    --percent: 0;
    counter-increment: percent var(--percent);
    background: #fff;
    // border-radius: 8px;
    box-shadow: 0 4px 16px -1px var(--shadow);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 32px 20px;
    font-family: Roboto, Arial;
    //Safari fix
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    .percent {
        background: #F5F9FF;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        transform-origin: 0 50%;
        overflow: hidden;
        transition: background .6s ease, transform .16s ease;
        transform: scaleX(calc(var(--percent) / 100));
        span {
            display: block;
            position: absolute;
            right: 0;
            width: 100%;
            bottom: 19px;
            height: 2px;
            opacity: 0;
            transform: translateY(.5px);
            transition: transform .8s ease;
            &:before,
            &:after {
                --r: 0;
                --s: .5;
                content: '';
                position: absolute;
                top: 0;
                height: 2px;
                border-radius: 1px;
                background: var(--primary);
                transition: background .8s ease, transform .8s ease, height .3s ease;
                transform: rotate(var(--r)) scaleY(var(--s));
            }
            &:before {
                right: 0;
                width: 64%;
                transform-origin: 0 50%;
            }
            &:after {
                left: 0;
                width: 38%;
                transform-origin: 100% 50%;
            }
        }
        div {
            --x: 0;
            transform: translateX(var(--x));
            transition: transform 1s ease;
            position: absolute;
            left: 0;
            bottom: 8px;
            width: 300%;
        }
        svg {
            display: block;
            height: 12px;
            width: 100%;
            stroke-width: 1.2px;
            color: var(--primary);
            transition: color .5s ease;
        }
    }
    &.paused {
        &:not(.finished) {
            .percent {
                div {
                    --x: -66.66%;
                    svg {
                        color: var(--light);
                        animation: down .8s linear forwards;
                    }
                }
            }
            .text {
                & > div {
                    div {
                        small {
                            &:first-child {
                                opacity: 0;
                            }
                            &:last-child {
                                opacity: 1;
                                transition-delay: .4s;
                            }
                        }
                    }
                }
            }
        }
    }
    &.finished {
        .percent {
            background: #fff;
            span {
                opacity: 1;
                transform: translate(-20px, -19px);
                &:before,
                &:after {
                    --s: 1;
                    background: var(--grey-light);
                    transition: background .6s ease, transform .6s ease .45s;
                    animation: check .4s linear forwards .6s;
                }
                &:before {
                    --r: -50deg;
                }
                &:after {
                    --r: 38deg;
                }
            }
            svg {
                opacity: 0;
            }
        }
        .text {
            --y: 0;
            & > div {
                opacity: 0;
            }
        }
        nav {
            opacity: 0;
            pointer-events: none;
        }
    }
    .text {
        --y: -18px;
        position: relative;
        z-index: 1;
        transform: translateY(var(--y));
        transition: transform .6s ease;
        strong {
            font-weight: 400;
            font-size: 14px;
            display: block;
            color: var(--grey-dark);
        }
        & > div {
            position: absolute;
            left: 0;
            top: 100%;
            transform: translateY(6px);
            line-height: 20px;
            display: flex;
            align-items: center;
            transition: opacity .4s ease;
            small {
                white-space: nowrap;
                vertical-align: top;
                display: block;
                font-size: 12px;
                color: var(--grey-light);
            }
            & > small {
                width: 30px;
                text-align: center;
                &:before {
                    content: counter(percent);
                }
            }
            div {
                vertical-align: top;
                display: inline-block;
                position: relative;
                margin-left: 4px;
                &:before {
                    content: '';
                    width: 2px;
                    height: 2px;
                    display: block;
                    border-radius: 50%;
                    background: var(--grey-light);
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 9px;
                }
                small {
                    position: absolute;
                    top: 0;
                    left: 8px;
                    transition: opacity .3s ease;
                    &:first-child {
                        transition-delay: .4s;
                    }
                    &:last-child {
                        opacity: 0;
                    }
                }
            }
        }
    }
    nav {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;
        transition: opacity .4s ease;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            &:not(:last-child) {
                margin-right: 16px;
            }
            &:first-child {
                --y: 8px;
                opacity: 0;
                transform: translateY(var(--y));
                transition: opacity .3s ease, transform .4s ease;
            }
            li {
                &:not(:last-child) {
                    margin-right: 12px;
                }
                a {
                    --r: 0deg;
                    --s: 1.01;
                    display: block;
                    transform: rotate(var(--r)) scale(var(--s)) translateZ(0);
                    transition: transform .6s ease, background .4s ease;
                    svg {
                        display: block;
                        width: 14px;
                        height: 14px;
                        color: var(--grey-light);
                    }
                    &:active {
                        --s: .84;
                        transition: transform .3s ease, background .4s ease;
                    }
                    &.dots {
                        --r: 90deg;
                    }
                    &.btn {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        position: relative;
                        background: var(--lightest);
                        svg {
                            position: absolute;
                            left: 6px;
                            top: 6px;
                            width: 12px;
                            height: 12px;
                        }
                        &:hover {
                            background: var(--lighter);
                        }
                        &.play {
                            --r: 90deg;
                            svg {
                                &:last-child {
                                    transform: scale(-1,) translateZ(0);
                                }
                            }
                            &.active {
                                --r: 0;
                            }
                        }
                        &.cancel {
                            &:before,
                            &:after {
                                --r: -45deg;
                                content: '';
                                display: block;
                                width: 2px;
                                border-radius: 1px;
                                height: 14px;
                                background: var(--grey-light);
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                margin: -7px 0 0 -1px;
                                transform: rotate(var(--r)) scale(.9) translateZ(0);
                            }
                            &:after {
                                --r: 45deg;
                            }
                        }
                    }
                }
            }
        }
    }
    &.isMobile,
    &:hover {
        nav {
            ul {
                &:first-child {
                    --y: 0;
                    opacity: 1;
                }
            }
        }
    }
}

@keyframes down {
    40% {
        transform: translateY(2px);
    }
}

@keyframes check {
    100% {
        background: var(--success);
    }
}

.upload {
    &.finished {
        & + .restart {
            opacity: 1;
            visibility: visible;
        }
    }
}

.restart {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--grey-light);
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease;
    svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        display: inline-block;
        vertical-align: top;
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

// Center & dribbble
body {
    min-height: 100vh;
    font-family: Roboto, Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--light);
    padding: 20px;
    .dribbble {
        position: fixed;
        display: block;
        right: 20px;
        bottom: 20px;
        img {
            display: block;
            height: 28px;
        }
    }
}
</style>