<template>
	<div class="isLoadingDiv" v-if="isLoadingDiv">
		<div class="spinnerDiv" id="textnode">
			<ProgressSpinner/>
		</div>
	</div>
		<div class="col-12">
			<div class="flex mb-4 align-content-center justify-content-between flex-wrap card-container purple-container">
				<h3>Proyectos</h3>
				<!-- al click poner id como seleccionado para que aparezca el azulito de seleccionado -->
				<div class="flex">
					<!-- <div class="flex align-content-center"> -->
						<!-- <ToggleButton v-if="treeValue.length === 1" v-model="selectedClick" onIcon="pi pi-angle-down" offIcon="pi pi-angle-down" @click="selectClick" class="mr-2"/> -->
						<Button type="button" icon="pi pi-users" label="" @click="showAssignedTo" class="mr-2"/>

						<Button v-if="treeValue && treeValue.length === 1" icon="pi pi-angle-down" @click="selectClick" class="mr-2"/>
						<Button type="button" icon="pi pi-angle-up" label="" @click="collapseAll" class="mr-2"/>
					<!-- </div> -->
					<Button icon="pi pi-filter-slash" class="mr-2" v-if="withFilter" aria-label="Search" @click="cleanFilter" />
					<Button icon="pi pi-search" aria-label="Search" @click="openSearchModal" />
				</div>
			</div>
			<!-- <div class="flex flex-wrap justify-content-between align-content-center gap-2 mb-4">
				<div class="flex align-content-center">
					<label>
						<Checkbox v-model="selectedClick" name="selectClick" :binary="true" />
						<span class="ml-2">Apertura múltiple </span>
					</label>
					
				</div>
				<Button type="button" icon="pi pi-plus" label="Expandir" @click="expandAll" /> 
				
			</div> -->
			<!-- :filter="true" filterMode="lenient" -->
			<Tree :value="treeValue" lazy :filter="true" filterMode="lenient"  :expandedKeys="expandedKeys" :selectionKeys="selectedKeys" @contextmenu="onRightClick" v-model:selectionKeys="selectedTreeValue" selectionMode="single" :loading="isLoading" @nodeExpand="onNodeExpand" @node-select="onNodeSelect" scrollHeight="500px">
				<template #default="slotProps">
					<b><span :title="slotProps.node.labelhover">{{ showassignedTo ? slotProps.node.labelhover : slotProps.node.label }}</span></b>
				</template>
				<template #url="slotProps">
					<a :href="slotProps.node.data"><span :title="slotProps.node.labelhover">{{ showassignedTo ? slotProps.node.labelhover : slotProps.node.label }}</span></a>
				</template>
			</Tree> <!-- selectionMode="checkbox" -->
			<ContextMenu ref="menu" :model="items" />

			<DynamicDialog />
		</div>
</template>

<script>
	import EventBus from './AppEventBus';
	// import { markRaw } from 'vue';
	import SearchModalComponent from './components/SearchModalComponent';
	// import ButtonDialogComponent from './components/ButtonDialogComponent';
	export default {
		data() {
			return {
				treeValue: null,
				selectedTreeValue: null,
				plainTree: null,
				treeTableValue: null,
				selectedTreeTableValue: null,
				isLoading: true,
				withFilter: false,
				treeValueSelected: null,
				showassignedTo: false,
				selectedClick: false,
				custom: false,
				save: true,
				parentid: [],
				expandedKeys: {},
				selectedKeys: {},
				isLoadingDiv: false,
				idOpens: [],
				items: [
					{ label: 'expandir', icon: 'pi pi-plus' },
				]
			}
		},
		nodeService: null,
		created() {
		},
		async mounted() {
			this.cleanFilter();
		},
		methods: {
			onRightClick (event) {
				this.menu.value.show(event);
			},
			async cleanFilter () {
				try {
					const data = await this.$store.dispatch('azure/getRootTree', { userId: this.getUser.id })
					this.treeValue = data;
					this.withFilter = false;
					this.save = false;
					this.custom = [];
					this.parentid = [];
					this.idOpens = [];
					this.isLoading = false;
				} catch (e) {
					console.log(e)
					this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
					this.isLoading = false;
				}
			},
			selectClick () {
				this.onNodeSelect(this.treeValue[0], true);
			},
			orderCustom (a, b){
				return a.localeCompare(b, 'en', { numeric: true })
			},
			openSearchModal() {
				this.$dialog.open(SearchModalComponent, { // pass value used before
					props: {
						header: 'Buscador',
						style: {
							width: '50vw'
						},
						breakpoints: {
							'960px': '75vw',
							'640px': '90vw'
						},
						modal: true
					},
					// templates: {
					// 	// footer: markRaw(ButtonDialogComponent)//componet yes or not <Button label="Prueba" @click="dialogRef.close({ buttonType: 'No' })" />
					// },
					onClose: async (selection) => { //options

						// console.log(selection)
						if(!selection.data) return false;

						this.isLoadingDiv = true;

						// for (const iterator of selection.data) {
							// if(iterator.type === 'PROJECT' && !this.custom.includes(iterator.id)) this.custom.push(iterator.id);

							// if(iterator.idparent && !this.parentid.includes(iterator.idparent)) this.parentid.push(iterator.idparent);
						
						let copyTree = [];
						if(this.save){
							copyTree = [...this.treeValue];
						} else {
							this.treeValue = [];
						}

						console.log(selection.data)

						this.custom = selection.data;

						let newRoot = null;

						try {
							const data = await this.$store.dispatch('azure/getRootTree', { userId: this.getUser.id, custom: this.custom })
							newRoot = data;
							this.treeValueSelected = data;

							if(data.length === 0) {
								this.isLoading = false;
								this.isLoadingDiv = false;
								this.cleanFilter();
								this.$router.push( { path: '/' }	);
								this.$toast.add({severity:'error', summary: 'Error', detail: 'La carpeta fue eliminada', life: 3000});
								return;
							}
							

							if(!this.idOpens.includes(data[0].key)) {
								this.idOpens.push(data[0].key);
								// copyTree.push(...data);
								this.treeValue = data;
								this.treeValue.push(...copyTree);
							} else {
								this.treeValue = [...copyTree];
							}

							this.save = true;
								

							// si ya esta abierto no cargar
							//fixed cabecera del proyectos buscador etc.

							// cambiar foto borrar antigua

						} catch (e) {
							console.log(e)
							this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
							this.isLoading = false;
						}

						// , parentId: this.parentid.length > 0 ? this.parentid : false

						this.withFilter = true;

						this.collapseAll();
						this.onNodeSelect(newRoot[0], true);
						this.selectedKeys = {};
						this.selectedKeys[selection.data.id] = true;
						this.isLoadingDiv = false;
						this.$router.push(
							{ path: `/projects/${selection.data.id}` }
						);

						this.isLoading = false;

						// 	this.isLoadingDiv = true;

						// 	this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
					}
				});
			},
			showAssignedTo() {
				this.showassignedTo = !this.showassignedTo;
			},
			async onNodeSelect(node, onlyOne = false) {
				if(this.selectedClick || onlyOne) {
					this.expandAllNode(node)
					// setTimeout(() => {
						this.isLoading = false;
					// }, "18000");
				}
				else {
					// console.log(node);
					// if(this.$router.currentRoute.value.name != 'ProjectsView') {
					this.selectedKeys = {};
					this.selectedKeys[node.key] = true;
					if (node.child > 0) {
						await this.onNodeExpand(node);
						// this.expandedKeys = {}
						this.expandedKeys[node.key] = true;
					}
					this.$router.push(
						{ path: `/projects/${node.key}` }
					);
					// }
					EventBus.emit('proyect-node', node);
				}
			},
			async onNodeExpand(node, noLoading = false) {
				if (!node.children) {
					if(!noLoading) this.isLoading = true;

					let _node = {...node};
					_node.children = [];

					await this.$store.dispatch('azure/getChildTree', {id: _node.key, users_id: this.getUser.id})
						.then( async(data) => {
							// console.log(data);
							_node.children = data;
							if(!noLoading) this.isLoading = false;
						})
						.catch((e) => {
							console.log(e)
							if(!noLoading) this.isLoading = false;
					});

					let _nodes = [...this.treeValue];

					let obj = _nodes.find(x => x.key === _node.key)

					if(obj) {
						obj.children = [..._node.children];//capítulo
					} else {
						_nodes.forEach(element => {
							if(element.child > 0 && element?.children) {
								element?.children.forEach(elementx => {
									if(elementx.key === _node.key) obj = elementx;
								});
							}
						});

						if(obj) {

							obj.children = [..._node.children];//subcapítulo
						} else {
							_nodes.forEach(element => {
								if(element.child > 0 && element?.children) {
									element?.children.forEach(elementx => {
										if(elementx.child > 0 && elementx?.children) {
											elementx?.children.forEach(elementy => {
												if(elementy.key === _node.key) obj = elementy;
											});
										}
									});
								}
							});

							if(obj) {

								obj.children = [..._node.children];//expediente
							}
						}
					}


					// _nodes = [...this.treeValue, ..._node.children];




					// _nodes.forEach(element => {
					// 	if(element.key === _node.key) element = { ...element, _node };
					// });
					this.treeValue = _nodes;
					if(!this.plainTree) this.plainTree = [...this.treeValue, ..._node.children];
					else this.plainTree = [...this.plainTree, ..._node.children];

					await this.$store.dispatch('azure/savePlaneRoot', this.plainTree);

					// this.isLoading = false;

				}
			},
			//Lunes
			async expandAll() { // hacer en backend
				return new Promise(resolve => {
					this.expandedKeys = {};
					this.isLoading = true;
					for (let node of this.treeValue) {
						this.onNodeExpand(node, true);
						this.expandNodeChilds(node);
						this.expandedKeys[node.key] = true;
					}

					// this.isLoadingDiv = false;
					// this.isLoading = false;
					resolve()
				})
			},
			async expandAllSelected() {
				return new Promise(resolve => {
					this.expandedKeys = {};
					this.isLoading = true;
					for (let node of this.treeValueSelected) {
						this.onNodeExpand(node, true);
						this.expandNodeChilds(node);
						this.expandedKeys[node.key] = true;
					}

					// this.isLoadingDiv = false;
					// this.isLoading = false;
					resolve()
				})
			},
			async expandAllNode(node) {
				// console.log('nodosss:', node);
				this.isLoading = true;
				await this.onNodeExpand(node, true);
				await this.expandNodeChilds(node);
				this.expandedKeys[node.key] = true;

				// this.isLoadingDiv = false;
				// this.isLoading = false;
				// this.isLoading = false;
			},
			collapseAll() {
				this.expandedKeys = {};
			},
			async expandNodeChilds(node) {
				// console.log('mis nodos:', node);
				if (node.children && node.children.length) {
					// console.log('entro:', node.key);

					for (let child of node.children) {
						await this.onNodeExpand(child, true);
						this.expandedKeys[child.key] = true;
						this.expandNodeChilds(child);
					}
				}
			},
			//Lunes

		},
		computed: {
            getAzureList() {
                return this.$store.getters['azure/getTree'].root;
            },
			getUser() {
				return this.$store.getters['user/getCurrentUser'];
			},
        },
		watch: {
			getAzureList(newValue) {
				this.treeValue = newValue;
				// this.expandedKeys = {...this.expandedKeys}
			}
		}
	}
</script>
<style >
.isLoadingDiv {
	/* positions to point 0,0 - required for z-index */
	position: fixed; top: 0; left: 0;
    /* above all elements, even if z-index is used elsewhere
       it can be lowered as needed, but this value surpasses
       all elements when used on YouTube for example. */
    z-index: 9999999999;
    /* takes up 100% of page */
    width: 100%; height: 100%;
    /* dim the page with 50% black background when visible */
    background-color: rgba(0,0,0,0.5);
    /* a nice fade effect, visibility toggles after 175ms, opacity will animate for 175ms. note display:none cannot be animated.  */
    transition: visibility 175ms, opacity 175ms;
	display: table;
	
}

/* .p-treenode:hover .p-treenode-label {
    width: auto;
    overflow: visible;
    z-index: 11;
} */


</style>
