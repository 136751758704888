import StorageManager from '../../helpers/StorageManager';

export default function logUser(state, auth) {
  const { accessToken, refreshToken, changePassword } = auth;

  state.token = accessToken;
  state.refreshToken = refreshToken;
  state.changePassword = changePassword;

  StorageManager.saveToken(accessToken);
  StorageManager.saveRefreshToken(refreshToken);
}
