import {getProfile} from './profileGetters';
import { getProfileList, asignProfileToUsers } from './profileActions';

const profile = {
  namespaced: true,
  state: () => ({
    id: null,
    name: null
  }),
  actions: {
    getProfileList,
    asignProfileToUsers,
  },
  getters: {
    getProfile
  },
};

export default profile;
