const getDefaultState = {
  auth: {
    token: null,
    refreshToken: null,
  },
  user: {
    uuid: null,
    email: null,
    name: null,
    permission: null,
    avatar: null,
    phone: null,
  },
  error: {
    title: null,
    message: null,
    showError: false,
  },
  specialities: [],
  countries: [],
};

export default getDefaultState;
