<template>
  <div class="surface-section p-5 mb-4 card">
    <div
      class="
        flex
        align-items-start
        flex-column
        lg:flex-row lg:justify-content-between
      "
    >
      <div class="flex w-full justify-content-between flex-column md:flex-row">
		<!-- Hover to change image 
		<div class="col-12 mt-2 flex justify-content-center">
                <FileUpload
                  class="btn-upload-img"
                  mode="basic"
                  chooseLabel="Imágen perfil"
                  name="demo[]"
                  url="./upload"
                  :auto="true"
                  accept="image/*"
                  :maxFileSize="1000000"
                  :fileLimit="3"
                />
              </div> -->
			<!-- <Image
				src=""
				alt="Image Text"
				imageClass="imageProfile"
			/> -->
      <div class="flex flex-row">
        <input type="file" ref="fileUpload" style="display: none" @change="openCropper" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
			<div class="profilepic" @click="$refs.fileUpload.click()">
				<img class="profilepic__image" :src="`${newpicture ? newpicture : (getUser.profilepicture ?? 'images/profileTest.jpg')}`" width="150" height="150" alt="Profibild" />
				<div class="profilepic__content">
					<span class="profilepic__icon"><i class="pi pi-camera"></i></span>
					<span class="profilepic__text">Cambiar imágen</span>
				</div>
			</div>
        <div>
          <span class="text-900 font-medium text-3xl">{{ getUser.name }} {{ getUser.lastname }}</span>
         <!-- <span class="text-900 font-medium text-3xl">{{getUser.name}}  {{getUser.lastname ?? ''}}</span> -->
          <div class="flex align-items-center flex-wrap text-sm">
            <!-- <div class="mr-5 mt-3">
              <span class="font-medium text-500">Mensajes</span>
              <div class="text-700 mt-2">333</div>
            </div>
            <div class="mr-5 mt-3">
              <span class="font-medium text-500">Proyectos</span>
              <div class="text-700 mt-2">26</div>
            </div> -->
            <!-- <div class="mr-5 mt-3">
              <span class="font-medium text-500">Próximo cambio de contraseña</span>
              <div class="text-700 mt-2">28/10/2022</div>
            </div> -->
            <div class="mt-3">
              <span class="font-medium text-500">Última vez conectado</span>
              <div class="text-700 mt-2">{{ formatDate(new Date()) }}</div>
            </div>
          </div>
        </div>
      </div>
        <div class="flex align-content-center flex-wrap">
          <div>
            <Button label="Conectar cuenta Microsoft" icon="pi pi-microsoft" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5 class="mb-6">Mi Perfil</h5>
        <div class="p-fluid formgrid grid">
          <div class="col-12 xl:col-12">
            <Fieldset legend="Información de la cuenta">
              <div class="grid">
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Nombre</p>
                  <p>{{ getUser.name }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Apellidos</p>
                  <p>{{ getUser.lastname }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Email</p>
                  <p>{{ getUser.email }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Perfiles</p>
                  <p>{{ profiles.find(x => x.code == getUser.users_has_users_profiles[0]?.users_profiles_id)?.name ?? '' }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Permisos</p>
                  <p>{{ permissions.find(x => x.code == getUser.users_has_permissions[0]?.permissions_id)?.name ?? '' }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Grupos</p>
                  <div class="flex align-items-center">
                    <div v-for="(element, i) in groups" :key="i">
                      <Chip :label="element.name" />
                    </div>
                  </div>
                </div>
                <!-- <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Roles</p>
                  <p>{{ getUser.email }}</p>
                </div> -->
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">DNI</p>
                  <p>{{ getUser.dni }}</p>
                </div>
                <div class="field col-12 md:col-6">
                  <p class="font-bold text-xl">Empresa</p>
                  <p>{{ getUser.empresa }}</p>
                </div>
              </div>
            </Fieldset>

            <Fieldset legend="Gestión de Contraseñas" class="mg-top">
              <div class="grid">
                <div class="field col-12 md:col-12">
                  <label for="password">Nueva Contraseña</label>
                  <InputText id="password" type="password" v-model="password" />
                </div>
                <!-- <div class="field col-12 md:col-12">
                  <label for="password">Antigua Contraseña</label>
                  <InputText id="password" type="password" />
                </div> -->
              </div>
              <div class="flex justify-content-end">
                <div>
                  <Button label="Guardar nueva contraseña" icon="pi pi-save" iconPos="left" class="p-button-sm create-project mr-3" @click="savePassword" />
                </div>
              </div>
            </Fieldset>
          </div>
          <!-- <div class="field col-12 md:col-3">
						<label for="state">CCAA</label>
						<Dropdown id="state" v-model="dropdownItem" :options="dropdownItems" optionLabel="name" placeholder="Select One"></Dropdown>
					</div> -->
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="visibleCropper" header="Editar foto" :style="{ width: '100vw' }">
    <cropper ref="cropper" class="example-cropper" :src="imageSelected" :stencil-component="$options.components.CircleStencil" :stencil-props="{
		aspectRatio: 1/1,
	}" @change="change" />

    <template #footer>
        <Button label="Guardar foto perfil" icon="pi pi-check" @click="cropImage" autofocus class="mt-4" />
    </template>
  </Dialog>

  <!-- <Toast /> -->
</template>

<script>
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';
import { uuid } from 'vue-uuid';

export default {
  data() {
    return {
      dropdownItems: [
        { name: "Option 1", code: "Option 1" },
        { name: "Option 2", code: "Option 2" },
        { name: "Option 3", code: "Option 3" },
      ],
      permissions: [
      { name: 'Administrador', code: 1 },
      { name: 'Externo', code: 2 }, 
      ],
      profiles: [
      { name: 'Administrador', code: 1 },
      { name: 'Interno', code: 2 }, 
      { name: 'Externo', code: 3 }, 
      ],
      dropdownItem: null,
      password: '',
      groups: null,
      visibleCropper: false,
      imageSelected: null,
      coordinates : null,
      refreshKey: 0,
      newpicture: null,
    };
  },
  components: {
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
  },
  async mounted() {
    await this.$store.dispatch('user/getUser');
    this.getGroups();
  },
  methods: {
    change({coordinates}) {
      console.log(coordinates);
      this.coordinates = coordinates
    },
    formatDate(value) {
			if(value)
			return value.toLocaleDateString('es-ES', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
    async savePassword() {
      await this.$store.dispatch('user/createUser', { 
					firstname: this.getUser.name, lastname: this.getUser.lastname, dni: this.getUser.dni, 
					email: this.getUser.email, password: this.password, changePassword: true,
      }).then(async () => {
        this.password = '';
        this.$toast.add({severity:'success', summary: 'Usuario actualizado', detail:'Usuario actualizado correctamente', life: 3000});
      }).catch(() => {
        this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
      })
    },
    async getGroups() {
      await this.$store.dispatch('group/getGroupsListByUserId', { 
				userID: this.getUser.id, 
      }).then(async (d) => {
        this.groups = d.data.collection;
      }).catch(() => {
        this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
      })
    },
    openCropper(e) {
      if(e.target.files[0].size > 2097152){
        this.$toast.add({severity:'error', summary: 'Algo salió mal', detail: 'Se supero el tamaño permitido', life: 3000});
        return false;
      }
      this.selectedFile = e.target.files[0]
      this.imageSelected = URL.createObjectURL(this.selectedFile)
      this.visibleCropper = true;

      e.target.value = ''
    },
    cropImage() {
      this.visibleCropper = false;
      const { canvas } = this.$refs.cropper.getResult();

      if (canvas) {
				const form = new FormData();
				canvas.toBlob(async (blob) => {
					form.append('file', blob);
          form.append('userId', this.getUser.id);
          form.append('name', this.getUser.id);
          form.append('type', blob.type);
          
          await this.$store.dispatch('azure/uploadSmallFile', form).then( async(e) => {
            this.refreshKey++;
            this.newpicture = e + '?updated=' + uuid.v4();
            this.$toast.add({severity:'success', summary: 'Imagen subida', detail: 'Foto de perfil actualizada', life: 3000});
            // await this.$store.dispatch('user/getUser')
					}).catch(() => {
            this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
          })
				}, 'image/jpeg');
			}
    }
  },
  computed: {
    getUser() {
      // this.refreshKey;
      return this.$store.getters["user/getCurrentUser"];
    },
  },
};
</script>

<style>
.imageProfile {
  /* margin-top: 1rem;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 50%; */
  /* margin-right: 50px; */
}

.mg-top {
  margin-top: 1.5rem;
}
.example-cropper {
  border: solid 1px #eee;
  min-height: 300px;
  width: 100%;
  height: 85vh;
}

.profilepic {
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111;
  margin-right: 50px;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic:hover .profilepic__image {
  opacity: .5;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.pi {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}
</style>