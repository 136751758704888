import {
  login, userPasswordReset, userPasswordUpdate, verifyUser, sendforgotPassword,
} from './authActions';

import logUser from './authMutations';
import { hasAuth, getToken } from './authGetters';
import StorageManager from '../../helpers/StorageManager';

const auth = {
  namespaced: true,
  state: () => ({
    token: StorageManager.getToken() || null,
    refreshToken: StorageManager.getRefreshToken() || null,
    changePassword: null,
  }),
  mutations: {
    logUser,
  },
  actions: {
    login,
    verifyUser,
    sendforgotPassword,
    userPasswordReset,
    userPasswordUpdate,
  },
  getters: {
    hasAuth,
    getToken
  },
};

export default auth;
