<template>
     <div class="flex justify-content-center">
        <div class="flex-none">
            <Dropdown v-model="selectedType" :class="`${isNotValidType ? 'p-invalid' : ''}`" :options="types" optionLabel="name" placeholder="Tipo de busqueda" class="w-full md:w-14rem" />
        </div>
        <div class="ml-4 flex w-full">
            <Dropdown v-model="selectedTypeOption" :options="typeLabelOptions" optionLabel="name" placeholder="Selecciona tipo" class="w-full mr-4 md:w-14rem" />
            <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText v-model="searchValue" :class="`${isNotValid ? 'p-invalid' : ''}`" placeholder="Buscar" @keypress.enter="load" />
            </span>
        </div>
        <div class="">
            <Button type="button" label="Buscar" icon="pi pi-search" :loading="loading" @click="load" />
        </div>
    </div>
    <div class="mt-8">
        <p><b>{{ searchResult.length }} resultados</b></p>
        <DataTable @row-click="closeDisplay($event)" :value="searchResult" dataKey="id">
            <!-- <Column selectionMode="multiple" scrollable scrollHeight="400px" headerStyle="width: 3rem"></Column> -->
            <Column field="prinexId" header="Identificador Prinex"></Column>
            <Column field="id" header="id" style="display: none"></Column>
            <Column field="project.name" header="Proyecto"></Column>
            <Column field="deprecatedid" header="Referencia"></Column>
            <Column field="name" header="Nombre"></Column>
            <Column field="type" header="Tipo">
                <template #body="slotProps">
                    <p :class="`${typeLabel.find(x => x.code === slotProps.data.type).color}`">{{ 
                        typeLabel.find(x => x.code === slotProps.data.type).name }}</p>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- <div class="p-dialog-footer mt-6">
        <Button label="Abrir nuevo árbol" @click="closeDisplay" />
    </div> -->
</template>

<script>
// import NodeService from '../service/NodeService';
export default {
    inject: ['dialogRef'],
    data() {
        return {
            selectedType: null,
            selectedTypeOption: null,
            isNotValid: false,
            value: '',
            typeLabelOptions: [
                {name: 'Proyecto', code: 'PROJECT'},
                {name: 'Capítulo', code: 'CHAPTER'},
                {name: 'Subcapítulo', code: 'SUBCHAPTER'},
                {name: 'Expediente', code: 'RECORD'},
            ],
            typeLabel: [
                {name: 'Proyecto', code: 'PROJECT', color: ''},
                {name: 'Capítulo', code: 'CHAPTER', color: 'text-blue-500'},
                {name: 'Subcapítulo', code: 'SUBCHAPTER', color: 'text-green-500'},
                {name: 'Expediente', code: 'RECORD', color: 'text-orange-500'},
            ],
            types: [
                { name: 'Identificador Prinex', code: 'prinexId' },
                { name: 'Nombre', code: 'name' },
                { name: 'Referencia', code: 'deprecatedid' },
                // { name: 'Tipo', code: 'type' },
                // { name: '', code: 'id' },
            ],
            loading: false,
            typeSelected: [],
            searchValue: '',
            searchResult: [],
            resultSelection: [],
            isNotValidType: false,
        }
    },
    methods: {
        async load() {
            if(this.searchValue.trim() === '' || this.searchValue.length < 4 )  {
                this.isNotValid = true;
                return false
            }

            if(!this.selectedType) {
                this.isNotValidType = true;
                return false
            }

            this.isNotValid = false;
            this.isNotValidType = false;

            this.loading = true
            this.typeSelected = [];

            this.selectedTypeOption ? this.typeSelected.push(this.selectedTypeOption.code) : this.typeSelected.push(...['PROJECT', 'CHAPTER', 'SUBCHAPTER', 'RECORD']);
            this.value = this.searchValue;

            await this.$store.dispatch('project/searchProjectContainsName', {searchType: this.selectedType.code, value: this.value, userID: this.getUser.id, typeSelected: this.typeSelected})
            .then( async(data) => {
                this.searchResult = data;
                this.loading = false;
            })
            .catch((e) => {
                console.log(e)
                this.loading = false;
            });


        },
        closeDisplay(e) {
            // e.preventDefault();
            // console.log(e.data.id);
            this.dialogRef.close(e.data);
        }
    },
    computed: {
        getUser() {
            return this.$store.getters['user/getCurrentUser'];
        },
    },
}
</script>

<style scoped>
button {
    margin-right: 0.5rem;
}
</style>