<template>
	<div class="grid" style="display: flex">
		<!--Lista de roles-->
		<div class="col-4 jove-card">
					<p class="permission-list-title">Listado de Roles</p>
					<ul class="jove-items-list">
						<p class="fw-500 border-item-style" @click="seeAllGroupsUsers()">
							Todos
						</p>
						<p class="border-item-style" v-for="group in groupList" v-bind:key="group.id" @click="seeGroupUser(group)" >
							{{ group.name }}
						</p>
					</ul>
		</div>
		<!--Lista de roles de Usuarios-->
		<div id="usersDataTable" ref="usersDataTable" class="col-8 jove-card card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button v-if="!currentGroup?.id" label="Crear Rol" icon="pi pi-plus" class="p-button-jove-primary mr-2 bolder" @click="openNew" />
							<Button label="Editar Rol" icon="pi pi-pencil" class="p-button-jove-primary mr-2 bolder" @click="openNew" v-else />
							<Button label="Eliminar Rol" icon="pi pi-trash" class="p-button-jove-tertiary" @click="confirmDeleteGroupSelected" v-if="currentGroup?.id" />
						</div>
					</template>
				</Toolbar>
				<!-- Datatable group selected-->
				<DataTable v-if="currentGroup" :value="groupUsers" :dataKey="id" paginator="true" :rows="15"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
						currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords}">
						<!--Avatar-->
						<Column field="avatar" header="Usuario">
							<template #body="{data}">
								<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
								<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
							</template>
						</Column>
						<!--Nombre-->
						<Column field="name" header="Nombre">
							<template #body="{data}">
								{{data.name}} {{ data.lastname ?? '' }}
							</template>
						</Column>
						<!--Email-->
						<Column field="email" header="Email">
							<template #body="{data}">
								{{data.email}}
							</template>
						</Column>
						<!--Eliminar del Rol-->
						<Column field="delete" header="Eliminar del Rol" class="">
							<template #body="{data}">
								<Button icon="pi pi-trash" class="p-button-rounded p-button-jove-tertiary mt-2" @click="confirmDeleteUserFromGroup(data.id, data.representative.id)" />
							</template>
						</Column>
						<template #paginatorstart>
							<Button type="button" icon="pi pi-refresh" class="p-button-text" />
						</template>
				</DataTable>
				<!-- // Datatable group selected-->

				<!-- Datatable all groups-->
				<DataTable v-else :value="groupListUsers" rowGroupMode="rowspan"  groupRowsBy="representative.id" sortMode="single" 
					sortField="representative.name" :sortOrder="1" responsiveLayout="scroll">
                    <!-- <Column field="representative.id" header="Seleccionar">
                        <template #body="slotProps">
							<input type="checkbox" class ="cb-size2" :value="slotProps.data.representative.id" :id="slotProps.data.representative.id" v-on:click="addtoSelectedGroups($event, slotProps.data)"/>
                        </template>
                    </Column> -->
					<Column field="representative.id" header="Rol">
                        <template #body="slotProps">
                            <!-- <img :alt="slotProps.data.representative.name" :src="'demo/images/avatar/' + slotProps.data.representative.image" width="32" style="vertical-align: middle" /> -->
                            <span class="image-text">{{slotProps.data.representative.name}} {{ slotProps.data.representative.lastname ?? '' }}</span>
                        </template>
                    </Column>
					<Column field="avatar" header="Usuario">
						<template #body="{data}">
							<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
							<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
						</template>
					</Column>
                    <Column field="name" header="Nombre"></Column>
                    <Column header="Eliminar del Rol">
						<template #body="slotProps">
							<Button v-if="notMe(slotProps.data)" icon="pi pi-trash" class="p-button-rounded p-button-jove-tertiary mt-2" @click="confirmDeleteUserFromGroup(slotProps.data.id, slotProps.data.representative.id)" />
						</template>
                    </Column>
                </DataTable>

				<!--Modal create group and insert users-->
				<Dialog v-model:visible="productDialog" :style="{width: '650px'}" header="Datos del Rol" :modal="true" class="p-fluid">
					<!-- <img :src="'images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
					<div class="field">
						<label for="name">Nombre del Rol</label>
						<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
						<small class="p-invalid" v-if="submitted && !product.name">Nombre es obligatorio.</small>
					</div>
					<!--Select Users-->
					<div class="container mg-top-btn">
						<Card>
							<template v-slot:content>
								<DataTable :value="usersList" :dataKey="id" responsiveLayout="scroll">
									<Column field="checked" header="Seleccionar">
										<template #body="{data}">
										<input type="checkbox" :checked="product?.users && product?.users.find(e=>e.users_id == data.id)" class ="cb-size2" :value="data.id" :id="data.id" v-on:click="addUserToGroup($event, data)">
										</template>
									</Column>
									<!--Avatar-->
									<Column field="avatar" header="Usuario">
										<template #body="{data}">
										<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
										<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
										</template>
									</Column>
									<!--Nombre-->
									<Column field="name" header="Nombre">
										<template #body="{data}">
										{{data.name}} {{ data.lastname ?? '' }}
										</template>
									</Column>
								</DataTable>
							</template>
						</Card>
					</div>
					<!--//Select Users-->

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button :disabled="!product.name || product.name.length == 0 || usersSelect.length == 0" label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveGroup" :loading="sended"/>
					</template>
				</Dialog>

				<!--Delete groups modal-->
				<Dialog v-model:visible="deleteGroupsDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Seguro que quieres eliminar a los roles seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteGroupsDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteselectedGroups" />
					</template>
				</Dialog>
				<!--// Delete groups modal-->

				<!--Delete users from groups modal-->
				<Dialog v-model:visible="deleteUserFromGroupDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Seguro que quieres eliminar al usuario del rol?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUserFromGroupDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteselectedUsers" />
					</template>
				</Dialog>
				<!--// Delete users from groups modal-->
			</div>
		<Toast />
	</div>

</template>

<script>
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import CustomerService from "../service/CustomerService";

//Change Product to users
export default {
	data() {
		return {
			usersList: null,
			productDialog: false,
			deleteUserFromGroupDialog: false,
			deleteGroupsDialog: false,
			product: {},
			currentGroup: null,
			selectedGroups: [],
			groupUsers: null,
			userGroupSelect: null,
			usersSelect: [],
			selectedUsers: null,
			groupList: null,
			representatives: [],
			filters: {},
			pagination: 1,
			submitted: false,
			customer3: null,
			userSelect: null,
			groupListUsers: [],
			loading: true,
			sended: false,
			// users: null,
			statuses: [
				{label: 'Administrador', value: 'admin'},
				{label: 'Externo', value: 'extern'},
				{label: 'Interno', value: 'intern'}
			]
		}
	},
	customerService: null,
	created() {
		this.customerService = new CustomerService();
		this.initFilters();
	},
	async mounted() {
	
		await this.$store.dispatch('rol/getRolList')
		.then( async(data) => {
			this.groupList = data.rolesList;
			this.isTableLloadingoading = false;
		})
		.catch((e) => {
			console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
			this.isTableLloadingoading = false;
		});


		for (const iterator of this.groupList) {
			console.log(iterator)
			this.representatives.push({id: iterator.id, name: iterator.name, lastname: iterator.lastname});
			for (const user of iterator.users) {
				if(this.groupListUsers.indexOf(user.users.id) === -1) this.groupListUsers.push({id: user.users.id, name: user.users.firstname, 
					email: user.users.email, dni: user.users.dni, representative: {id: iterator.id, name: iterator.name}});
			}
		}

		await this.$store.dispatch('user/getUsersList')
		.then( async(data) => {
			this.usersList = data.usersList;
			this.isTableLloadingoading = false;
		})
		.catch((e) => {
			console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
			this.isTableLloadingoading = false;
		});
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			console.log(this.currentGroup)
			this.product = this.currentGroup ?? {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		addUserToGroup(e, data) {
			data.userId = data.id;

			if (e.target.checked) {
				this.usersSelect.push(data);
			}else{
				for( let i=0; i < this.usersSelect.length; i++){     
					if ( this.usersSelect[i].id === data.id) { 
						this.usersSelect.splice(i, 1);
						i--; 
					}
				}
			}
		},
		// Open modal of selected groups to delete
		addtoSelectedGroups(e, data){
			if (e.target.checked) {
				this.selectedGroups.push(data);
			}else{
				for( let i=0; i < this.selectedGroups.length; i++){     
					if ( this.selectedGroups[i].representative.id === data.id) { 
						this.selectedGroups.splice(i, 1);
						i--; 
					}
				}
			}
		},
		// Crear rol
		async saveGroup() {
			this.submitted = true;
			this.sended = true;
			let usersInfo = [];
			let dataToSend = {
				name: this.product.name,
			};

			for (const iterator of this.usersSelect) {
				usersInfo.push({id: iterator.id})
			}
			dataToSend.infoUsersGroup = usersInfo;
			if(this.currentGroup) dataToSend.id = this.product?.id
			await this.$store.dispatch('rol/createGroupWithUsers', dataToSend);
			await this.$store.dispatch('rol/getRolList')
			.then( async(data) => {
				this.groupList = data.rolesList;
				this.isTableLloadingoading = false;
			})
			.catch((e) => {
				console.log(e)
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				this.isLoading = false;
				this.isTableLloadingoading = false;
			});

			this.groupListUsers = [];	

			for (const iterator of this.groupList) {
				for (const user of iterator.users) {
					if(this.groupListUsers.indexOf(user.users.id) === -1) this.groupListUsers.push({id: user.users.id, name: user.users.firstname, 
						email: user.users.email, dni: user.users.dni, users_id: user.users.id, representative: {id: iterator.id, name: iterator.name}});
				}
			}

			console.log(this.currentGroup)
			if(this.currentGroup) {
				console.log(this.groupListUsers)
				this.groupUsers = this.groupListUsers.filter(item => item.representative.id === dataToSend.id);
				this.currentGroup = {
					name: this.product.name,
					id: dataToSend.id,
					users: [...this.groupUsers],
				}
			}
			this.productDialog = false;
			this.sended = false;

			// this.$toast.add({severity:'success', summary: 'Usuario creado', detail:'Usuario creado correctamente', life: 3000});
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
			this.products = this.products.filter(val => val.id !== this.product.id);
			this.deleteProductDialog = false;
			this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'representative': {value: null, matchMode: FilterMatchMode.IN},
				'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
				'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
			}
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.groupListUsers) {
				for (let customer of this.groupListUsers) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}
			return total;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		async deleteselectedUsers() {
			await this.$store.dispatch('rol/deleteUserFromGroups', {
				users_groups_id: this.userGroupSelect, users_id: this.userSelect
			}).then(async () => {
				await this.$store.dispatch('rol/getRolList')
				.then( async(data) => {
					this.groupList = data.rolesList;
					this.isTableLloadingoading = false; 
				})
				.catch((e) => {
					console.log(e)
					this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
					this.isLoading = false;
					this.isTableLloadingoading = false;
				});

				this.representatives = [];
				this.groupListUsers = [];


				for (const iterator of this.groupList) {
					// console.log(iterator.users)
					this.representatives.push({id: iterator.id, name: iterator.name, lastname: iterator.lastname});
					for (const user of iterator.users) {
						if(this.groupListUsers.indexOf(user.users.id) === -1) this.groupListUsers.push({id: user.users.id, name: user.users.firstname, 
							email: user.users.email, dni: user.users.dni, representative: {id: iterator.id, name: iterator.name}});
					}
				}

				if(this.currentGroup) {
					this.groupUsers = this.groupListUsers.filter(item => (item.id !== this.userSelect && item.representative.id === this.userGroupSelect));
				}
				this.deleteUserFromGroupDialog = false;
			});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		notMe(data) {
			let me = this.$store.getters['user/getCurrentUser'] 
			return data.id != me.id;
		},
		seeGroupUser(group){
			this.currentGroup = group;
			this.usersSelect = [];
			this.currentGroup.users.forEach((e) => {
				this.usersSelect.push({id: e.users_id});
			})
			console.log(this.currentGroup.users)
			this.groupUsers =  this.groupListUsers.filter(item => item.representative.id === group.id);
		},
		seeAllGroupsUsers(){
			this.currentGroup = null;
			console.log('usersList -> ' + this.usersList);
		},
		//DELETIONS
		confirmDeleteGroupSelected() {
			this.deleteGroupsDialog = true;
		},
		confirmDeleteUserFromGroup(id, group_id) {
			this.userSelect = id;
			this.userGroupSelect = group_id;
			this.deleteUserFromGroupDialog = true;
		},
		async deleteselectedGroups(){
			let selectedGroupsIDs = [];
			console.log(this.selectedGroups);
			selectedGroupsIDs.push(...this.selectedGroups.map(emp => emp.representative.id));
			if(this.currentGroup) selectedGroupsIDs.push(this.currentGroup.id);
			this.currentGroup = null;
			await this.$store.dispatch('rol/deleteGroups', {
				selectedGroups: selectedGroupsIDs
			}).then(async () => {
				await this.$store.dispatch('rol/getRolList')
				.then( async(data) => {
					this.groupList = data.rolesList;
					this.isTableLloadingoading = false;
				})
				.catch((e) => {
					console.log(e)
					this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
					this.isLoading = false;
					this.isTableLloadingoading = false;
				});

				this.representatives = [];
				this.groupListUsers = [];


				for (const iterator of this.groupList) {
					// console.log(iterator.users)
					this.representatives.push({id: iterator.id, name: iterator.name});
					for (const user of iterator.users) {
						if(this.groupListUsers.indexOf(user.users.id) === -1) this.groupListUsers.push({id: user.users.id, name: user.users.firstname, 
							email: user.users.email, dni: user.users.dni, representative: {id: iterator.id, name: iterator.name}});
					}
				}
				this.deleteGroupsDialog = false;
			});
		}

	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}
::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

.col-8 {
    width: 62.6667% !important;
}

.jove-card {
    margin: 1%;
}
</style>
