/* eslint-disable no-unused-vars */
import AzureAPI from '../../domain/azure/AzureApi';

export function getRootTree({ commit }, data) {
  const azureApi = new AzureAPI();
  const rootTree = azureApi.getRootTree(data);

  return rootTree
    .then((res) => {
      commit('saveRootTree', res);
      commit('savePlainRoot', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createFolder({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.createFolder(data);

  return folder
    .then((res) => {
      commit('saveNewRootTree', res);
      console.log(res)
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function upLoadFile({ commit }, data) {
  const azureApi = new AzureAPI();
  console.log(data)
  const folder = azureApi.uploadFiles(data);

  return folder
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function uploadSmallFile({ commit }, data) {
  const azureApi = new AzureAPI();
  console.log(data)
  const folder = azureApi.uploadSmallFile(data);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createSubFolder({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.createSubFolder(data);

  return folder
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getFiles({ commit }, id) {
  const azureApi = new AzureAPI();
  const folder = azureApi.getFiles(id);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function protectFile({ commit }, id) {
  const azureApi = new AzureAPI();
  const file = azureApi.protectFile(id);

  return file
    .then((res) => {
      return res
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function getProtectFile({ commit }, id) {
  const azureApi = new AzureAPI();
  const file = azureApi.getProtectFile(id);

  return file
    .then((res) => {
      return res
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function removeProtectFile({ commit }, id) {
  const azureApi = new AzureAPI();
  const file = azureApi.removeProtectFile(id);

  return file
    .then((res) => {
      return res
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function copyFileToAnotherFolder({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.copyFileToAnotherFolder(data);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchFiles({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.searchFiles(data);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function removeFile({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.removeFile(data);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

// getFiles

export function getChildTree({ commit }, id) {
  const azureApi = new AzureAPI();
  const childTree = azureApi.getChildTree(id);

  return childTree
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function removeProject({ commit }, data) {
  const azureApi = new AzureAPI();
  const folder = azureApi.removeProject(data);

  return folder
    .then((res) => {
      // commit('saveNewRootTree', res);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function savePlaneRoot({ commit }, plainRoot) {
  // console.log(plainRoot);
  commit('savePlainRoot', plainRoot);
}