
<template>
	<div class="grid">
		<!--Listado Permisos-->
		<div class="col-4">
			<Card>
				<template v-slot:content>
					<p class="permission-list-title">Listado de Permisos</p>
					<ul class="jove-items-list">
						<li class="border-item-style" v-for="perm in permissions" v-bind:key="perm.id" @click="seeUsersWithPermission(perm)" >
							{{ perm.name }}
						</li>
					</ul>
				</template>
			</Card>
		</div>
		<!--// Listado Permisos-->
		
		<!--Listado Usuarios-->
		<div v-if="showDataTable" id="usersDataTable" ref="usersDataTable" class="col-8">
			<Card>
				<template #header>
					<div class="header-buttons-container">
						<Button label="Añadir Permiso" v-html="this.permissionSelectedTxt" icon="pi pi-plus" class="p-button-jove-primary mr-2 bolder" @click="showUserWithoutPermissionDialog()" />
					</div>
				</template>
				<template v-slot:content>
					<DataTable :value="usersListWithPermissions" :dataKey="id" paginator="true" :rows="15"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
						<!--Avatar-->
						<Column field="avatar" header="Usuario">
							<template #body="{data}">
								<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
								<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
							</template>
						</Column>
						<!--Nombre-->
						<Column field="name" header="Nombre">
							<template #body="{data}">
								{{data.firstname}}
							</template>
						</Column>
						<!--Email-->
						<Column field="email" header="Email">
							<template #body="{data}">
								{{data.email}}
							</template>
						</Column>
						<!--Eliminar permiso-->
						<Column field="delete" header="Eliminar permiso" class="">
							<template #body="{data}">
								<Button icon="pi pi-trash" class="p-button-rounded p-button-jove-tertiary mt-2" @click="confirmDeletePermission(data.id)" />
							</template>
						</Column>
						<template #paginatorstart>
							<Button type="button" icon="pi pi-refresh" class="p-button-text" />
						</template>
						<template #paginatorend>
							<Button type="button" icon="pi pi-cloud" class="p-button-text" />
						</template>
					</DataTable>
				</template>
			</Card>
		</div>
		<!--// Listado Usuarios-->
		<ConfirmDialog></ConfirmDialog>
	</div>

	<!--MODAL USUARIOS SIN PERMISO SELECCIONADO-->
	<Dialog v-model:visible="usersWithoutPermissionDialog" :style="{width: '750px'}" header="Usuarios" :modal="true" class="p-fluid">
		<div class="header-buttons-container">
			<Button label="Añadir Permiso" icon="pi pi-plus" class="p-button-jove-primary mr-2 bolder" :disabled="!this.usersIDToAddPermissionList || !this.usersIDToAddPermissionList.length" @click="addPermissionToUsersSelected()" />
		</div>
		<div class="container mg-top-btn">
			<Card>
				<template v-slot:content>
					<DataTable :value="usersListWithoutPermissions" :dataKey="id" responsiveLayout="scroll">
						<Column field="checked" header="Seleccionar">
							<template #body="data">
							<input type="checkbox" class ="cb-size2" :value="data.data.id" :id="data.data.id" v-on:click="addUserToPermission($event)">
							</template>
						</Column>
						<!--Avatar-->
						<Column field="avatar" header="Usuario">
							<template #body="data">
							<Image v-if="data.data.profilepicture" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
							<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
							</template>
						</Column>
						<!--Nombre-->
						<Column field="name" header="Nombre">
							<template #body="data">
							{{data.data.firstname + ' ' +  (data.data?.lastname !== null ? data.data?.lastname : '') }}
							</template>
						</Column>
					</DataTable>
				</template>
			</Card>
		</div>
	</Dialog>
</template>


<script>
import {FilterMatchMode} from 'primevue/api';
import CustomerService from "../service/CustomerService";


//Change Product to users
export default {
	data() {
		return {
			permissions: null,
			permissionList: null,
			currentPermission: null,
			permissionSelectedTxt: null,
			usersList: [],
			usersIDToAddPermissionList: [],
			usersListWithPermissions: [],
			usersListWithoutPermissions: [],
			usersSelectedToAddPerm: [],
			showDataTable: null,
			usersWithoutPermissionDialog: false,
		}
	},
	customerService: null,
	created() {
		this.customerService = new CustomerService();
		this.initFilters();
	},
	async mounted() {
		//Crear flujo de session con token y refreshtoken
		// Get User/Permission List
		Promise.all([
				await this.$store.dispatch('user/getUsersList'), 
				await this.$store.dispatch('permission/getPermissionList')
		])
		.then( async(data) => {
			console.log(data);
			this.usersList = data[0].usersList;
			console.log(this.usersList);
			this.permissions = data[1].permissionsList;
		})
		.catch((e) => {
			console.log(e)
			this.isLoading = false;
		});
	},

	methods: {
		async seeUsersWithPermission(permission){
			const permissionID = permission.id;
			this.currentPermission = permission;
			this.permissionSelectedTxt = '+ Añadir ' + permission.name;
			this.showDataTable = true;

			// Get userList with that Permission and userList without that permission
			this.loadData(permissionID);
		},
		async loadData(permissionID) {
			await this.$store.dispatch('user/getUsersListByPermission', {permissionID}
			).then( async(data) => {
				this.usersListWithPermissions = [];
				const usersListId = [];

				for (const user_has_permission of data.data) {
					this.usersListWithPermissions.push(user_has_permission.users);
					usersListId.push(user_has_permission.users.id);
				}
				//Users Without Permission
				await this.$store.dispatch('user/getUsersListWithoutPermission', {permissionID, usersListId}
				).then( async(dataPerm2) => {
					// let usersWithoutPerm = []
					console.log(dataPerm2);
					this.usersListWithoutPermissions = [...dataPerm2.data];

					// for (const user_has_permission of dataPerm2.data) {
					// 	if (!usersWithoutPerm.includes(user_has_permission)) {
					// 		usersWithoutPerm.push(user_has_permission.users);
					// 	}
					// }

					// Filter users by userId and delete duplicates
					// const result = usersWithoutPerm.filter(user => usersListId.indexOf(user.id) === -1)
					// 	.filter((user, index, self) =>
					// 		index === self.findIndex((t) => 
					// 			(JSON.stringify(t) === JSON.stringify(user)
					// 		)
					// 	)
					// );
					// this.usersListWithoutPermissions = [...usersWithoutPerm];

					console.log(this.usersListWithoutPermissions);
				})
				.catch((e) => {
					console.log(e)
					this.isLoading = false;
				});
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});
		},
		addUserToPermission (e) {
			if (e.target.checked) {
				this.usersIDToAddPermissionList.push(Number(e.target.id));
			}else{
				for( let i=0; i < this.usersIDToAddPermissionList.length; i++){     
					if ( this.usersIDToAddPermissionList[i] === Number(e.target.id)) { 
						this.usersIDToAddPermissionList.splice(i, 1);
						i--; 
					}
				}
			}
		},
		// Add the permission to the users selected
		async addPermissionToUsersSelected(){
			let filteredUsers = [];
			this.usersSelectedToAddPerm = [];
			// Filter users by ID
			filteredUsers = this.usersListWithoutPermissions.filter(user => this.usersIDToAddPermissionList.includes(user.id))
			console.log(filteredUsers)
			// Prepare JSON data to send (permissions_id users_id) / this.currentPermission.id
			filteredUsers.forEach((userF) => {
				this.usersSelectedToAddPerm.push({permissions_id: Number(this.currentPermission.id), users_id: Number(userF.id)});
			});
			await this.$store.dispatch('permission/asignPermissionToUsers', { userHasPermission: this.usersSelectedToAddPerm })
				.then(() => { // res error o not
					// console.log('res -> ' + JSON.stringify(res));
					// if(res){
						this.$toast.add({severity:'success', summary: 'Añadidos', detail: 'Insercción realizada con éxito', life: 3000});
						this.usersWithoutPermissionDialog = false;
						this.usersListWithPermissions.push(...filteredUsers);
						// this.created();
						// this.mounted();
					// }else{
					// 	this.$toast.add({severity:'info', summary: 'Añadidos', detail: 'La insercción no se pudo realizar', life: 3000});
					// }
				}
			)
		},
		confirmDeletePermission(id) {
			this.$confirm.require({
                message: '¿Seguro que quieres eliminarlo?',
                header: 'Confirmar',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when user confirms the action
					this.deleteUser(id);
					this.$toast.add({severity:'success', summary: 'Eliminado', detail: 'Eliminado con éxito', life: 3000});
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
                onShow: () => {
                    //callback to execute when dialog is shown
                },
                onHide: () => {
                    //callback to execute when dialog is hidden
                }
            });
		},
		async deleteUser(id) {
			await this.$store.dispatch('permission/deleteUserFromPermissions', {permissions_id: parseInt(this.currentPermission.id), users_id: id}
			).then( async(data) => { 
				this.loadData(parseInt(this.currentPermission.id))
				console.log(data) 
			})
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
			this.showDataTable = false;
        },
		notMe(data) {
			let me = this.$store.getters['user/getCurrentUser'] 
			return data.id != me.id;
		},
		showUserWithoutPermissionDialog() {
			this.usersWithoutPermissionDialog = true;
			this.usersIDToAddPermissionList = [];	// Init users with future permissions added
		}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
