import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import EventFactory from './EventFactory';

const events = '/api/v1/events';

class EventAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  // Get all events
  getEventList(data){
    const url = `${events}/eventsList`; // getEventList

    return this.client
      .post(url, data)
      .then((res) => res.data) //EventFactory.getEventsList(res.data)
      .catch((error) => Promise.reject(error));
  }

  createEvent (data){
    const url = `${events}/createEvent`;
    console.log(data)
    
    return this.client
      .post(url, data)
      .then(() => data)
      .catch((error) => Promise.reject(error));
  }

  //Insert on users_has_Events
  asignEventToUsers(data) {
    console.log('API - asignEventToUsers data -> ' + data);
    const url = `${events}/asignEventToUsers`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data.success;
      })
      .catch((error) => Promise.reject(error));
  }
}

export default EventAPI;
