import {
  getRootTree,
  // addRoot,
  getChildTree,
  createFolder,
  // addChild,
  // getFilesFromRoot,
  // addFiles,
  // addDescription,
  savePlaneRoot,
  getFiles,
  uploadSmallFile,
  protectFile,
  getProtectFile,
  removeProtectFile,
  searchFiles,
  createSubFolder,
  removeFile,
  upLoadFile,
  removeProject,
  copyFileToAnotherFolder,
} from './azureActions';

import { getTree, getRootTreeCount, getUplaodedFiles } from './azureGetters';
import { saveRootTree, saveNewRootTree, savePlainRoot, addFiles } from './azureMutations';

const azure = {
  namespaced: true,
  state: () => ({
    root: null,
    rootChild: null,
    plainRoot: null,
    rootTreeCount: 0,
    description: '',
    filesCount: 0,
    filesUploaded: [],
  }),
  mutations: {
    saveRootTree,
    saveNewRootTree,
    savePlainRoot,
    addFiles,
  },
  actions: {
    getRootTree,
    createFolder,
    savePlaneRoot,
    // addRoot,
    getChildTree,
    protectFile,
    getProtectFile,
    removeProtectFile,
    removeFile,
    getFiles,
    createSubFolder,
    upLoadFile,
    removeProject,
    searchFiles,
    copyFileToAnotherFolder,
    uploadSmallFile,
    // addChild,
    // getFilesFromRoot,
    // addFiles,
    // addDescription,
  },
  getters: {
    getTree,
    getRootTreeCount,
    getUplaodedFiles,
  },
};

export default azure;
