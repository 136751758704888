import ProfileModel from './ProfileModel';

class ProfileFactory {
  constructor() {
    this.profile = {};
  }

  getProfilesList(data) {
    console.log(data)
    const profilesResponse = {
      profilesList: this.getCollection(data),
      pagination: 1,
    };
    return profilesResponse;
  }

  getCollection(profilesCollection) {
    const profileList = profilesCollection.map(
      (profile) => this.getProfile(profile),
    );

    return profileList;
  }

  getProfile(data) {
    console.log(data)
    this.profile = new ProfileModel({
      id: data.id,
      name: data.name
    });

    return this.profile;
  }
}

export default new ProfileFactory();