/* eslint-disable no-unused-vars */
import UserApi from '../../domain/user/UserApi';

//Profile *for current user*

export function resetPassword({ commit }, resetData) {
  const userApi = new UserApi();
  const apiAddUser = userApi.resetPassword(resetData);

  return apiAddUser
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUser({ commit }, data) {
  const userApi = new UserApi();
  const getRequest = userApi.getUser(data);

  return getRequest
    .then((res) => {
      commit('user/setUser', res, { root: true });
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createUser({ commit }, data) {
  const userApi = new UserApi();
  const createRequest = userApi.createUser(data);

  return createRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateUser({ commit }, data) {
  const userApi = new UserApi();
  const updateRequest = userApi.updateUser(data);

  return updateRequest
    .then((res) => {
      commit('user/setUser', res, { root: true });
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteUser({ commit }, data) {
  // console.log(data)
  const userApi = new UserApi();
  const deleteRequest = userApi.deleteUser(data);

  //maybe can send userslist in res

  return deleteRequest
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}


//Users *for all users*
export function getUsersList({ commit }, data) {
  const userApi = new UserApi();
  const usersList = userApi.getUsersList(data);

  return usersList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUsersListByPermission({ commit }, data) {
  const userApi = new UserApi();
  console.log('user Actions -> ' + JSON.stringify(data));
  const usersList = userApi.getUsersListByPermission(data);
  // console.log('actions: getUsersListByPermission : usersList -> ' + JSON.stringify(usersList));

  return usersList
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUsersListByProfile({ commit }, data) {
  const userApi = new UserApi();
  const usersList = userApi.getUsersListByProfile(data);
  // console.log('actions: getUsersListByPermission : usersList -> ' + JSON.stringify(usersList));

  return usersList
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUsersListWithoutPermission({ commit }, data) {
  const userApi = new UserApi();
  console.log('getUsersListWithoutPermission data ->' + JSON.stringify(data))
  const usersList = userApi.getUsersListWithoutPermission(data);

  return usersList
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUsersListWithoutProfile({ commit }, data) {
  const userApi = new UserApi();
  const usersList = userApi.getUsersListWithoutProfile(data);

  return usersList
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}