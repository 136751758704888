import EventApi from '../../domain/events/EventApi';

// Return all the events
export function getEventList({ commit }, data) {
  const eventApi = new EventApi();
  const eventList = eventApi.getEventList(data);

  return eventList
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createEvent({ commit }, data) {
  const eventApi = new EventApi();
  const createRequest = eventApi.createEvent(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

// Asing Event to User/Users
// Create record on table 'users_has_events
export function asignEventToUsers({ commit }, data) {
  console.log('asignEventToUsers - Actions');
  const  eventApi = new EventApi();
  const createRequest = eventApi.asignEventToUsers(data);

  return createRequest
    .then( (res) => { 
      return res
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}