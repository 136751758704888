<template>
    <div class="mt-4">
        <div style="margin-bottom: 1em">
            <!-- <Button type="button" icon="pi pi-plus" label="Abrir todo el árbol" @click="expandAll" />
            <Button type="button" icon="pi pi-minus" label="Cerrar todo el árbol" @click="collapseAll" /> -->
        </div>
        <Tree :value="treeValue" lazy :filter="true" filterMode="lenient" :expandedKeys="expandedKeys" v-model:selectionKeys="selectedTreeValue" selectionMode="single" :loading="isLoading" @nodeExpand="onNodeExpand" @node-select="onNodeSelect"></Tree>
    </div>
</template>

<script>
import NodeService from '../service/NodeService';
export default {
    inject: ['dialogRef'],
    data() {
        return {
            folders: null,
            selectedKey: null,
            expandedKeys: {},
            treeValue: null,
            selectedTreeValue: null,
            plainTree: null,
            treeTableValue: null,
            selectedTreeTableValue: null,
            isLoading: true,
        };
    },
    treeService: null,
    created() {
        this.treeService = new NodeService();
    },
    mounted() {
        this.treeService.getTreeNodes().then((data) => (this.treeValue = data)); // add to .env now it is public
        this.isLoading = false;
    },
    methods: {
        async onNodeSelect(data) {
            // console.log(node);
            // if(this.$router.currentRoute.value.name != 'ProjectsView') {
            this.dialogRef.close(data);
            // }
        },
        async onNodeExpand(node) {
            if (!node.children) {
                this.isLoading = true;

                let _node = {...node};
                _node.children = [];

                await this.$store.dispatch('azure/getChildTree', {id: _node.key, templateFolder: true})
                    .then( async(data) => {
                        console.log(data);
                        _node.children = data;
                        this.isLoading = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        this.isLoading = false;
                });

                console.log(_node);
                
                let _nodes = [...this.treeValue];
                console.log(_nodes);

                let obj = _nodes.find(x => x.key === _node.key)

                console.log(obj);

                
                if(obj) {
                    obj.children = [..._node.children];//capítulo
                    console.log(1)

                } else {
                    _nodes.forEach(element => {
                        console.log('testea:', element);
                        if(element?.children && element?.children.length > 0) {
                            element?.children.forEach(elementx => {
                                if(elementx.key === _node.key) obj = elementx;
                            });
                        }
                    });

                    if(obj) {

                        obj.children = [..._node.children];//subcapítulo
                        console.log(2)
                    } else {
                        _nodes.forEach(element => {
                            console.log('prueba:', element);
                            if(element?.children && element?.children.length > 0) {
                                element?.children.forEach(elementx => {
                                    console.log('elementx:', elementx)
                                    if(elementx?.children && elementx?.children.length > 0) {
                                        elementx?.children.forEach(elementy => {
                                            if(elementy.key === _node.key) obj = elementy;
                                        });
                                    }
                                });
                            }
                        });

                        if(obj) {

                            obj.children = [..._node.children];//expediente
                            console.log(3)
                        }
                    }
                }

                this.treeValue = _nodes;
            }
        },
    
    },
    // computed: {
    //     // getAzureList() {
    //     //     console.log(this.$store.getters['azure/getTree'].root);
    //     //     return this.$store.getters['azure/getTree'].root;
    //     // },
    //     getUser() {
    //         return this.$store.getters['user/getCurrentUser'];
    //     },
    // },
    // watch: {
    //     getAzureList(newValue) {
    //         this.treeValue = newValue;
    //         // this.expandedKeys = {...this.expandedKeys}
    //     }
    // }
};
</script>

<style scoped>
button {
    margin-right: 0.5rem;
}
</style>