import { PROFILE } from '@/helpers/UserHelper';

export function getCurrentUser(state) {
  return state;
}

export function isAdmin(state) {
  return (state?.users_has_users_profiles ? (state?.users_has_users_profiles.find(x => x.users_profiles_id == PROFILE.ADMIN) ? true : false) : false)
}

export function getId(state) {
  // console.log(state)
  return state?.id;
}