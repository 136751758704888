/* eslint-disable no-unused-vars */
import RolAPI from '../../domain/rol/RolApi';

// Return all the roles
export function getRolList({ commit }) {
  const rolApi = new RolAPI();
  const rolList = rolApi.getRolList();

  return rolList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

// export function getGroupsList({ commit }, data) {
//   const groupAPI = new RolAPI();
//   const getAllRequest = groupAPI.getAllGroups(data);

//   return getAllRequest
//     .then((res) => res)
//     .catch((error) => {
//       // commit('error/setError', error, { root: true });
//       return Promise.reject(error);
//     });
// }

export function getGroupsListByUserId({ commit }, data) {
  const groupAPI = new RolAPI();
  const getAllRequest = groupAPI.getGroupsListByUserId(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createGroupWithUsers({ commit }, data) {
  const groupAPI = new RolAPI();
  const createRequest = groupAPI.createGroupWithUsers(data);

  return createRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteGroups({ commit }, data) {
  const groupAPI = new RolAPI();
  const deleteRequest = groupAPI.deleteGroups(data);

  return deleteRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteUserFromGroups({ commit }, data) {
  const groupAPI = new RolAPI();
  const deleteRequest = groupAPI.deleteUserFromGroups(data);

  return deleteRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
