import {
  createProject,
  getProject,
  saveProject,
  saveProjectProbablyDate,
  getProjectsStatus,
  changeProjectsStatus,
  addUsersPermisionToProject,
  getUsersPermisionInProject,
  getProjectDays,
  getLastProjects,
  getAllProjects,
  removeUsersPermisionToProject,
  getImportantFile,
  createTemplate,
  sendEmailWithFile,
  deleteImportantFile,
  addImportantFile,
  getParentsProjectById,
  searchProjectContainsName,
  renameProject,
  getAllProjectTemplate,
} from './projectActions';

const project = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    createProject,
    getProject,
    saveProject,
    createTemplate,
    saveProjectProbablyDate,
    getProjectsStatus,
    changeProjectsStatus,
    searchProjectContainsName,
    addUsersPermisionToProject,
    getUsersPermisionInProject,
    getProjectDays,
    getLastProjects,
    getAllProjects,
    sendEmailWithFile,
    renameProject,
    getImportantFile,
    removeUsersPermisionToProject,
    deleteImportantFile,
    addImportantFile,
    getParentsProjectById,
    getAllProjectTemplate,
  },
  getters: {
  },
};

export default project;
