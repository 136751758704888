<script>

// TODO: lista notificaciones y si fueron o no leidas.
    const issues = [
        {
            value: 123,
            label: 'Error with foo bar',
            searchText: 'foo bar'
        },
        {
            value: 42,
            label: 'Cannot read line',
            searchText: 'foo bar line'
        },
        {
            value: 77,
            label: 'I have a feature suggestion',
            searchText: 'feature'
        }
    ];

    export default {
        props: {
            socket: Object,
        },
        data () {
            return {
                text: '',
                items: [],
                title: '',
                selectedRow: null,
                selectedUsers: null,
                userslist: [],
                users: [],
                usersTest: [],
                notifications: [],
                notificationsSended: [],
                submitted: false,
                isLoadingNotification: false,
                notificationDialog: false,
            }
        },
        async mounted() {
            const tag = this.getUser.email.substring(0, this.getUser.email.indexOf("@"))
            console.log(tag)
            await this.$store.dispatch('notification/getListNotifications', { id:this.getUser.id, tag: '@' + tag })
            .then( async(data) => {
                console.log(data)
                data?.collection.forEach(element => {
                    this.notifications.push({ publisher: element.users.firstname + ' ' + element.users.lastname ?? '', post: element.post, createddate: element.createddate, read: element?.read ?? false })
                });
                console.log(this.notifications)
                await this.$store.dispatch('notification/addNotificationToArrayAction', this.notifications);
            })
            .catch((e) => {
                console.log(e)
                // this.isLoading = false;
            });

            await this.$store.dispatch('notification/getSendedNotifications')
            .then( async(data) => {
                console.log(data)
                data?.collection.forEach(element => {
                    this.notificationsSended.push({ to: element.users_has_posts.length > 0 ? element.users_has_posts[0].users.firstname + ' ' + element.users_has_posts[0].users.lastname ?? '' : element.folder, post: element.post, createddate: element.createddate, read: element.read })
                });
            })
            .catch((e) => {
                console.log(e)
                // this.isLoading = false;
            });

            // await this.$store.dispatch('notification/changeToRead');

            await this.$store.dispatch('user/getUsersList')
            .then( async(data) => {
                data.usersList.forEach(element => {
                    console.log(element)
                    this.userslist.push({ name: element.name + ' ' + (element.lastname ?? ''), code: element.id })
                    this.users.push({ value: element.email.substring(0, element.email.indexOf("@")), firstName: element.name + ' ' + (element.lastname ?? '') })
                });
                console.log(this.userslist)
                this.pagination = data.pagination;
                this.isTableLloadingoading = false;
            })
            .catch((e) => {
                console.log(e)
                this.isLoading = false;
            });
        },
        methods: {
            onOpen (key) {
            this.items = key === '@' ? this.users : issues
                // if(key === '@') {
                //     this.items = this.users
                // }
            },
            async saveNotification() {
                this.submitted = true;
                if(this.selectedUsers && this.text.trim().length > 0 ){
                    this.notificationDialog = false;
                    this.isLoadingNotification = true;

                    const { userId } = await this.$store.getters['user/getCurrentUser'];

                    console.log(this.selectedUsers);

                    this.socket.emit('notification', {
                        post: this.text,
                        to: this.selectedUsers,
                        who: userId
                    });
                    
                    this.notificationsSended.unshift({ to: this.selectedUsers.name, post: this.text, createddate: new Date(), read: false })
                    this.$toast.add({severity:'success', summary: 'Notificación enviada', detail:`Notificación enviada a ${this.selectedUsers.name} correctamente`, life: 3000});

                    this.selectedUsers = [];
                    this.text = '';
                }
            },
            openNew() {
                this.submitted = false;
                this.isLoadingNotification = false;
                this.notificationDialog = true;
            },
            handleApply(e){
                console.log(e)
            },
            onRowSelect() {
                if(this.selectedRow.to?.id){
                    this.goto(this.selectedRow.to.id)
                }
            },
            goto(id){
                this.$router.push(
                    { path: `/projects/${id}` }
                ); 
            },
            hideDialog() {
                this.notificationDialog = false;
                this.isLoadingNotification = false;
                this.submitted = false;
            },
            formatDate(value) {
                return value.toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            },
        },
        computed: {
            getnotificationsList() {
                return this.$store.getters['notification/getListArrayNotifications'];
            }, 
            getUser() {
                return this.$store.getters["user/getCurrentUser"];
            },
        }
    }
</script>

<template>
  <div class="card">
		<div class="col-12">
                <div class="flex justify-content-end flex-wrap card-container">
                    <Button label="Nuevo" icon="pi pi-plus" iconPos="left" class="p-button-sm" @click="openNew" />
                </div>
                <br>
                <TabView>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-inbox"></i>
                            <span class="ml-2"> Recibidos</span>
                        </template>
                        <DataTable :value="getnotificationsList" responsiveLayout="scroll">
                            <Column field="publisher" header="Emisor"></Column>
                            <Column field="post" header="Contenido"></Column>
                            <Column field="createddate" header="Fecha envio">
                                <template #body="data">
                                    {{formatDate(new Date(data.data.createddate))}}
                                </template>
                            </Column>
                            <!-- <Column field="read" header="Leído">
                                <template #body="data">
                                    <Chip v-if="!data.data.read" label="Nuevo" class="mr-2" />
                                </template>
                            </Column> -->
                        </DataTable>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-send"></i>
                            <span class="ml-2"> Enviados</span>
                        </template>
                        <DataTable v-model:selection="selectedRow" selectionMode="single" :value="notificationsSended" @rowSelect="onRowSelect"  responsiveLayout="scroll">
                            <Column field="to.name" header="Enviado a"></Column>
                            <Column field="post" header="Contenido"></Column>
                            <Column field="createddate" header="Fecha envio">
                                <template #body="data">
                                    {{formatDate(new Date(data.data.createddate))}}
                                </template>
                            </Column>
                            <!-- <Column field="read" header="Leído">
                                <template #body="data">
                                    <Chip v-if="data.data.read" label="Leido" class="mr-2" />
                                </template>
                            </Column> -->
                        </DataTable>
                    </TabPanel>
                </TabView>
                <br>
                <Dialog v-model:visible="notificationDialog" :style="{width: '450px'}" header="Crear nueva notificación" :modal="true" class="p-fluid">
                    <h5>Envíar a:</h5>
                    <Dropdown v-model="selectedUsers" :options="userslist" optionLabel="name" placeholder="Selecciona usuarios" required="true" autofocus :class="{'p-invalid': submitted}" />
                    <br>
                    <br>
                    <!-- <Mentionable
                        :keys="['@', '#']"
                        :items="items"
                        offset="6"
                        insert-space
                        @apply="handleApply($event)"
                        @open="onOpen"
                    > -->
                        <Textarea v-model="text" :autoResize="true" rows="5" cols="30" required="true" :class="{'p-invalid': submitted}"/>

                        <!-- <template #no-result>
                        <div class="dim">
                            No result
                        </div>
                        </template>

                        <template #item-@="{ item }">
                        <div class="user">
                            {{ item.value }}
                            <span class="dim">
                            ({{ item.firstName }})
                            </span>
                        </div>
                        </template>

                        <template #item-#="{ item }">
                        <div class="issue">
                            <span class="number">
                            #{{ item.value }}
                            </span>
                            <span class="dim">
                            {{ item.label }}
                            </span>
                        </div>
                        </template> -->
                    <!-- </Mentionable> -->
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveNotification" :loading="isLoadingNotification" />
                    </template>
                </Dialog>
			</div>
		</div>
</template>

<style lang="scss">
// @import '../../public/themes/lara-light-indigo/theme.css';
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #6366F1;
  color: #fff;
}
</style>