import GroupModel from './GroupModel';
// import PaginationFactory from '../pagination/PaginationFactory';

class GroupFactory {
  constructor() {
    this.group = {};
  }

  getAllGroups(data) {
    const groupResponse = {
      groupList: this.getCollection(data?.collection),
      // pagination: PaginationFactory.getPagination(data?.pagination),
      pagination: 1
    };

    return groupResponse;
  }

  getGroup(group) {

    this.group = new GroupModel({
      id: group.id,
      name: group.name,
      users: group.users_groups_has_users,
    });

    return this.group;
  }

  getCollection(groupCollection) {
    console.log(groupCollection)
    const groupList = groupCollection.map(
      (group) => this.getGroup(group),
    );
    return groupList;
  }
}
export default new GroupFactory();
