import Vuex from 'vuex';
import auth from './auth/auth';
import user from './user/user';
import notification from './notification/notification';
import group from './group/group';
import azure from './azure/azure';
import tempo from './tempo/tempo';
import rol from './rol/rol';
import permission from './permission/permission';
import profile from './profile/profile';
import project from './project/project';
import verification from './verification/verification';
import mutations from './mutations';
import state from './state';
import createPersistedState from "vuex-persistedstate";
import event from './event/event';

export default new Vuex.Store({
  modules: {
    auth,
    user,
    group,
    notification,
    permission,
    profile,
    rol,
    project,
    azure,
    event,
    verification,
    tempo,
  },
  state,
  mutations,
  actions: {},
  plugins: [createPersistedState()],
});
