<template>
    <div class="flex h-screen align-content-center overflow-hidden">
        <div class="flex flex-1 justify-content-center flex-wrap align-content-center"><h1>Página no encontrada</h1></div>
        <div class="flex flex-1"><Vue3Lottie :animationData="Animation404" /></div>
    </div>
</template>

<script>
import Animation404 from '../../public/animations/36395-lonely-404.json'

export default {
components: {
},
data() {
    return {
    Animation404,
    }
},
}
</script>