/* eslint-disable no-unused-vars */
import PermissionApi from '../../domain/permission/PermissionApi';

// Return all the permissions
export function getPermissionList({ commit }) {
  const permissionApi = new PermissionApi();
  const permissionList = permissionApi.getPermissionList();

  return permissionList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getPermissionListByUserId({ commit }, data) {
  const permissionApi = new PermissionApi();
  const permissionList = permissionApi.getPermissionListByUserId(data);

  return permissionList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteUserFromPermissions({ commit }, data) {
  const permissionApi = new PermissionApi();
  const permissionList = permissionApi.deleteUserFromPermissions(data);

  return permissionList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

// Asing Permission to User/Users
// Create record on table 'users_has_permissions
export function asignPermissionToUsers({ commit }, data) {
  console.log('asignPermissionToUsers - Actions');
  const  permissionApi = new PermissionApi();
  const createRequest = permissionApi.asignPermissionToUsers(data);

  return createRequest
    .then( (res) => { 
      return res
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}