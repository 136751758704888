import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import GroupFactory from './GroupFactory';

const GROUP = '/api/v1/groups';

class groupAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllGroups() { //currentPage
    // const parameterUrl = getParameterUrl(GROUP, currentPage.page,
    //   [], [], currentPage.itemsPerPage);

    const url = `${GROUP}/getGroupsList`;

    return this.client
      .get(url)
      .then((res) => GroupFactory.getAllGroups(res.data))
      .catch((error) => Promise.reject(error));
  }

  getGroupsListByUserId(data) {

  const url = `${GROUP}/getGroupsListByUserId`;

  return this.client
    .post(url, data)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
  }

  createGroupWithUsers(data) {
    // const idData = getDataWithUuid(data);

    const url = `${GROUP}/createGroupWithUsers`;

    return this.client
      .post(url, data)
      .then(() => data)
      .catch((error) => Promise.reject(error));
  }

  deleteGroups(data) {
    console.log('deleteGroups from API -> ' + JSON.stringify(data));
    const url = `${GROUP}/deleteGroups`;

    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteUserFromGroups(data) {
    const url = `${GROUP}/deleteUserFromGroups`;

    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default groupAPI;
