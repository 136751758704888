import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import NotificationFactory from './NotificationFactory';

const NOTIFICATIONS = '/api/v1/notifications';

class notificationAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllNotification(data) {
    const url = `${NOTIFICATIONS}/getNotifications`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getAllNotificationSended(data) {
    const url = `${NOTIFICATIONS}/getSendedNotifications`;

    return this.client
      .get(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  changeToRead(data) {
    const url = `${NOTIFICATIONS}/changeToRead`;

    return this.client
      .get(url, data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  getSendedNotificationsById(data) {
    console.log(data)
    const url = `${NOTIFICATIONS}/getSendedNotificationsById`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getNotificationsCount(data) {
    const url = `${NOTIFICATIONS}/getNotificationsCount`;

    return this.client
      .get(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  saveNotificationsById(data) {
    console.log(data)
    const url = `${NOTIFICATIONS}/savePostWithFolder`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getNewNotification(data) {
    const url = `${NOTIFICATIONS}/getNewNotifications`;

    return this.client
      .get(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }
}

export default notificationAPI;
