import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import AzureFactory from './AzureFactory';

const TEMPO = '/api/v1/tempo';

class TempoAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  saveWorkingTime(data) {
    const url = `${TEMPO}/addWorkingTime`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getWorkingTime(data) {
    const url = `${TEMPO}/getWorkingTime`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

}

export default TempoAPI;
