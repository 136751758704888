<template>
  <div class="flex justify-content-center flex-wrap card-container mt-8">
      <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
           <div class="text-center mb-5">
              <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
              <div class="text-900 text-3xl font-medium mb-3">Debes cambiar tu contraseña</div>
          </div>
          <form @submit.prevent="login(!v$.$invalid)" class="p-fluid">
              <div class="field mb-3">
                  <div class="p-float-label">
                      <Password id="password" :feedback="false" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask/>
                      <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Nueva contraseña</label>
                  </div>
                  <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ message }}</small>
              </div>
              <!-- <div class="field-checkbox">
                  <Checkbox id="accept" name="accept" value="Accept" v-model="v$.accept.$model" :class="{'p-invalid':v$.accept.$invalid && submitted}" />
                  <label for="accept" :class="{'p-error': v$.accept.$invalid && submitted}">I agree to the terms and conditions*</label>
              </div> -->
              <Button type="submit" label="Entrar" class="mt-2" :loading="isLoading"/>
              <small v-if="credentialsError" class="p-error">{{ message }}</small>
          </form>
      </div>
  </div>
</template>

<script>

import Button from 'primevue/button';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "ResetPasswordView",
  components: {
    Button
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
      return {
          password: '',
          accept: null,
          submitted: false,
          message: '',
          isLoading: false,
          credentialsError: false,
      }
  },
  props: ['email'],
  validations() {
      return {
          password: {
              required
          },
      }
  },
  methods: {
    async login(isFormValid) {

      this.submitted = true;
      this.credentialsError = false;

      console.log(isFormValid)

      if (!isFormValid) {
        return;
      }

      this.isLoading = true;
      
      await this.$store.dispatch('auth/userPasswordReset', {password: this.password, email: this.email})
      .then( async(data) => {
        // get info has to go in the url

        // had hash to enter in this page or add write current password too.

        console.log(data);
        this.isLoading = false;

        if(data.data?.message) {
          this.message = data.data?.message
          this.credentialsError = true;
        } else {
          await this.$store.dispatch('user/getUser');
          this.$router.push({ name: 'Dashboard' });
        }
      
      })
      .catch((e) => {
        this.isLoading = false;

        if(e.response.data.message === "Invalid login credentials.") {
          this.credentialsError = true;
        }
        
        // this.resetState();
      });

      

    }
  }
};
</script>