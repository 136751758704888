<template>
	<div class="grid">
		<div class="col-12">
			<div class="">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:end>
						<div class="my-2">
							<Button label="Dar permisos" icon="pi pi-plus" class="mr-2" @click="openNewAccess" />
							<Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedAccess || !selectedAccess.length" />
						</div>
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="accessList" v-model:selection="selectedAccess" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entrandas" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Accesos y permisos</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText style="width:100%" v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Nombres" :sortable="true" headerStyle="width:14%; min-width:14rem;">
						<template #body="slotProps">
							<span class="p-column-title">Nombres</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="price" header="Lector" :sortable="true" headerStyle="width:14%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Lector</span>
							<i v-if="slotProps.data.isReader" class="pi pi-check ml-5"></i>
						</template>
					</Column>
					<Column field="category" header="Editor" :sortable="true" headerStyle="width:14%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Editor</span>
							<i v-if="slotProps.data.isEditor" class="pi pi-check ml-5"></i>
						</template>
					</Column>
					<Column field="rating" header="Administrador" :sortable="true" headerStyle="width:14%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Administrador</span>
							<i v-if="slotProps.data.isOwner" class="pi pi-check ml-5"></i>
						</template>
					</Column>
					<Column headerStyle="min-width:2rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editAccess(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteAccess(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="accessDialog" :style="{width: '450px'}" header="Dar permisos" :modal="true" class="p-fluid">
					<div class="field">
						<Dropdown v-model="userSelected" id="assinegTo" :options="usersList" optionLabel="name" required="true" autofocus :class="{'p-invalid': submitted}" :filter="true" placeholder="Selecciona usuario" :showClear="true">
						<template #value="slotProps">
									<div v-if="slotProps.value" class="country-item country-item-value grid">
									
										<div class="field col-12 md:col-12 mb-0">
											<div>{{ slotProps.value.name }} {{ slotProps.value.lastname }}</div>
										</div>
									</div>
								<span v-else>
									{{ slotProps.placeholder }}
								</span>
							</template>
							<template #option="slotProps">
								<div class="country-item grid">
									<div class="field col-12 md:col-12">
										<div>{{ slotProps.option.name }}  {{ slotProps.option.lastname }}</div>
									</div>
								</div>
							</template>
						</Dropdown>
					</div>

					<div class="field">
						<label for="accessType" class="mb-3">Permiso</label>
						<Dropdown id="accessType" v-model="accessTypeSeleted" :options="accessType" optionLabel="label" placeholder="Selecciona un permiso">
							<template #value="slotProps">
								<div v-if="slotProps.value && slotProps.value.value">
									<span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
								</div>
								<div v-else-if="slotProps.value && !slotProps.value.value">
									<span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
						</Dropdown>
					</div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideAccessDialog"/>
						<Button label="Guardar" :disabled="accessTypeSeleted?.length === 0 && userSelected?.length === 0" icon="pi pi-check" class="p-button-text" @click="saveAccessAccess" :loading="loadingSave" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteaccessDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="access">¿Quieres eliminar acceso a <b>{{access.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteaccessDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteAccess" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteAccessDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedAccess">¿Estás seguro que quieres eliminar estos accesos?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteAccessDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedAccess" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
// import { uuid } from 'vue-uuid';

export default {
	props: {
		projectId: {
			type: String,
			default: null
		},
		type: {
			type: String,
			default: 'PROJECT'
		},
		parentsId: {
			type: Array,
			default: (() => []),
		}
	},
	data() {
		return {
			accessDialog: false,
			deleteaccessDialog: false,
			deleteAccessDialog: false,
			userSelected: null,
			accessList: [],
			accessTypeSeleted: null,
			usersList: null,
			selectedAccess: null,
			filters: {},
			access: null,
			submitted: false,
			loadingSave: false,
			accessType: [
				{label: 'Solo lectura', value: 'Reader'},
				{label: 'Editor', value: 'Editor'},
				{label: 'Administrador', value: 'Owner'}
			]
		}
	},
	created() {
		this.initFilters();
	},
	async mounted() {
		await this.$store.dispatch('user/getUsersList')
		.then( async(data) => {
			this.usersList = data.usersList;
			// this.isTableLloadingoading = false;
		})
		.catch((e) => {
			console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});

		await this.$store.dispatch('project/getUsersPermisionInProject', { folder_id: this.$route.params.id })
		.then( async(data) => {

			data.forEach(element => {
				const user = this.usersList.find(val => val.id === element.users_id)
				if(user) {
					this.accessList.push({
						id: element.users_id,
						name: user.name + ' ' + (user.lastname ?? ''),
						isOwner: element.metadata, 
						isReader: element.read,
						isEditor: element.write,
						accessType: element.rol,
					})
				}
			});
			// this.isTableLloadingoading = false;
		})
		.catch((e) => {
			console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});
			
	},
	methods: {
		openNewAccess() {
			this.userSelected = null;
			this.accessTypeSeleted = null;
			this.submitted = false;
			this.accessDialog = true;
		},
		hideAccessDialog() {
			this.accessDialog = false;
			this.submitted = false;
		},
		async saveAccessAccess() {
			this.submitted = true;
			this.loadingSave = true;
			if (this.userSelected) {
				let userId = this.accessList.find(val => val.id === this.userSelected.id);
				if (userId) {
					userId.isOwner = this.accessTypeSeleted.value === 'Owner';
					userId.isReader = this.accessTypeSeleted.value === 'Reader';
					userId.isEditor = this.accessTypeSeleted.value === 'Editor';
					userId.accessType = this.accessTypeSeleted.value;

					// no se guarda es visual

					this.$toast.add({severity:'success', summary: 'Actualizado', detail: 'Permisos actualizados', life: 3000});
				}
				else {
					this.accessList.push({
						id:this.userSelected.id,
						name: this.userSelected.name + ' ' + (this.userSelected.lastname ?? ''),
						isOwner: this.accessTypeSeleted.value === 'Owner',
						isReader: this.accessTypeSeleted.value === 'Reader',
						isEditor: this.accessTypeSeleted.value === 'Editor',
						accessType: this.accessTypeSeleted.value,
					})

					await this.$store.dispatch('project/addUsersPermisionToProject', { folder_id: this.$route.params.id,
					users_id: this.userSelected.id,
					read: this.accessTypeSeleted.value === 'Reader',
					write: this.accessTypeSeleted.value === 'Editor',
					metadata: this.accessTypeSeleted.value === 'Owner',
					rol: this.accessTypeSeleted.value,
					type: this.type,
					projectId: this.projectId,
					parents: this.parentsId,
					})
					.then( async(data) => {
							this.loadingSave = false;
							this.$toast.add({severity:'success', summary: 'Añadido', detail: 'Permisos añadidos', life: 3000});
							console.log(data);
						})
						.catch((e) => {
							console.log(e)
							this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
							// this.isLoading = false;
							this.loadingSave = false;
							this.accessDialog = false;
					});
					
				}
			}
			this.loadingSave = false;
			this.accessDialog = false;
		},
		editAccess(access) {
			this.submitted = false;
			this.userSelected = this.usersList.find(val => val.id === access.id)
			this.accessTypeSeleted = this.accessType.find(val => val.value === access.accessType)
			// saveAccessAccess
			this.accessDialog = true;
		},
		confirmDeleteAccess(access) {
			this.access = access;
			this.deleteaccessDialog = true;
		},
		async deleteAccess() {
			await this.$store.dispatch('project/removeUsersPermisionToProject', {data: [{ 
			folder_id: this.$route.params.id,
			users_id: this.access.id,
			}]}).then( (data) => {
				console.log(data);
				this.accessList = this.accessList.filter(val => val.id !== this.access.id);
				this.access = null;
				this.deleteaccessDialog = false;
				this.$toast.add({severity:'success', summary: 'Permiso borrado', detail: 'Permiso borrado correctamentee', life: 3000});
			})
			.catch((e) => {
				console.log(e)
				this.deleteaccessDialog = false;
				this.access = null;
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				// this.isLoading = false;
			});
		},
		confirmDeleteSelected() {
			this.deleteAccessDialog = true;
		},
		async deleteSelectedAccess() {
			let data = [];
			this.selectedAccess.forEach(e => {
				data.push({ 
					folder_id: this.$route.params.id,
					users_id: e.id,
				})
			})

			await this.$store.dispatch('project/removeUsersPermisionToProject', {data}).then( () => {
				this.accessList = this.accessList.filter(val => !this.selectedAccess.includes(val));
				this.deleteAccessDialog = false;
				this.selectedAccess = null;
				this.$toast.add({severity:'success', summary: 'Permisos borrados', detail: 'Permisos borrados correctamente', life: 3000});
			})
			.catch(() => {
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				this.deleteAccessDialog = false;
				this.selectedAccess = null;
				// this.isLoading = false;
			});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>