<template>
    <div class="mt-4">
        <div style="margin-bottom: 1em">
            <!-- <Button type="button" icon="pi pi-plus" label="Abrir todo el árbol" @click="expandAll" />
            <Button type="button" icon="pi pi-minus" label="Cerrar todo el árbol" @click="collapseAll" /> -->
        </div>
        <Tree :value="treeValue" lazy :filter="true" filterMode="lenient" :expandedKeys="expandedKeys" v-model:selectionKeys="selectedTreeValue" selectionMode="single" :loading="isLoading" @nodeExpand="onNodeExpand" @node-select="onNodeSelect"></Tree>

        <!-- Menu Folder Start -->
		<Dialog v-model:visible="selectNameDialog" :style="{width: '450px'}" :header="`Escribe el nombre del archivo `" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Nombre</label>
				<InputText v-model="templateName" id="templateName" required="true" autofocus :class="{'p-invalid': submitted}" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideSelectName"/>
				<Button label="Crear" icon="pi pi-check" class="p-button-text" :disabled="templateName && templateName.trim().length < 0" @click="saveSelectName" />
			</template>
		</Dialog>
		<!-- Menu Folder End -->
    </div>
</template>

<script>
import NodeService from '../service/NodeService';
export default {
    inject: ['dialogRef'],
    data() {
        return {
            folders: null,
            selectedKey: null,
            expandedKeys: {},
            treeValue: null,
            selectedTreeValue: null,
            plainTree: null,
            treeTableValue: null,
            selectedTreeTableValue: null,
            isLoading: true,
            templateName: '',
            selectNameDialog: false,
            nodeSelected: null,
        };
    },
    treeService: null,
    created() {
        this.treeService = new NodeService();
    },
    mounted() {
        this.treeService.getTreeNodes().then((data) => (this.treeValue = data)); // add to .env now it is public
        this.isLoading = false;
    },
    methods: {
        hideSelectName (){
            this.selectNameDialog = false;
            this.templateName = '';
        },
        onNodeSelect(data) {
            console.log(data);
            this.nodeSelected = data;
            this.templateName = data.label;
            this.selectNameDialog = true;
        },
        async saveSelectName() {
            this.nodeSelected.rename = this.templateName;
            this.dialogRef.close(this.nodeSelected);
        },
        async onNodeExpand(node) {
            if (!node.children) {
                this.isLoading = true;

                let _node = {...node};
                _node.children = [];

                await this.$store.dispatch('azure/getChildTree', {id: _node.key, templateFolder: true, withFiles: true})
                    .then( async(data) => {
                        console.log(data);
                        _node.children = data;
                        this.isLoading = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        this.isLoading = false;
                });

                console.log(_node);
                
                let _nodes = [...this.treeValue];
                console.log(_nodes);

                let obj = _nodes.find(x => x.key === _node.key)

                console.log(obj);

                
                if(obj) {
                    obj.children = [..._node.children];//capítulo
                    console.log(1)

                } else {
                    _nodes.forEach(element => {
                        console.log('testea:', element);
                        if(element?.children && element?.children.length > 0) {
                            element?.children.forEach(elementx => {
                                if(elementx.key === _node.key) obj = elementx;
                            });
                        }
                    });

                    if(obj) {

                        obj.children = [..._node.children];//subcapítulo
                        console.log(2)
                    } else {
                        _nodes.forEach(element => {
                            console.log('prueba:', element);
                            if(element?.children && element?.children.length > 0) {
                                element?.children.forEach(elementx => {
                                    console.log('elementx:', elementx)
                                    if(elementx?.children && elementx?.children.length > 0) {
                                        elementx?.children.forEach(elementy => {
                                            if(elementy.key === _node.key) obj = elementy;
                                        });
                                    }
                                });
                            }
                        });

                        if(obj) {

                            obj.children = [..._node.children];//expediente
                            console.log(3)
                        }
                    }
                }

                this.treeValue = _nodes;
            }
        },
    
    },
};
</script>

<style scoped>
button {
    margin-right: 0.5rem;
}
</style>