import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import ProjectsView from './components/ProjectsView.vue';
import MainProjectView from './components/MainProjectView.vue';
import ProjectInformation from './components/ProjectInformation.vue';
import Dashboard from './components/Dashboard.vue';
import EditProfile from './components/EditProfile.vue';
import UsersList from './components/UsersList.vue';
import Backlog from './components/Backlog.vue';
import GroupsList from './components/GroupsList.vue';
import RolesList from './components/RolesList.vue';
import EditTemplatesFolder from './components/EditTemplatesFolder.vue';
import NotificationsView from './components/NotificationsView.vue';
import SearchFileComponent from './components/SearchFileComponent.vue';
import Permisssions from './components/Permissions.vue';
import Profiles from './components/Profiles.vue';
import Login from './pages/Login.vue';
import ForgotPassword from './pages/ForgotPassword.vue';
import NotFound from './pages/NotFound.vue';
import ResetPassword from './pages/ResetPassword.vue';
import store from './store/index';
import Calendar from './components/Calendar.vue';

//TODO: Change some routes to private
const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/projects',
                name: 'MainProjectView',
                component: MainProjectView,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/projects/:id',
                name: 'ProjectView',
                component: ProjectsView,
                meta: {
                    isPublic: false,
                },
            },
            {
                path: '/searchList/:search',
                name: 'SearchFileComponent',
                component: SearchFileComponent,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/projectInformation/:id',
                name: 'ProjectInformation',
                component: ProjectInformation,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/editProfile',
                name: 'EditProfile',
                component: EditProfile,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/permissions',
                name: 'Permisssions',
                component: Permisssions,
                meta: {
                    isPublic: true,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/profiles',
                name: 'Profiles',
                component: Profiles,
                meta: {
                    isPublic: true,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/usersList',
                name: 'UsersList',
                component: UsersList,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/groupsList',
                name: 'GroupsList',
                component: GroupsList,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/rolesList',
                name: 'RolesList',
                component: RolesList,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/notifications',
                name: 'NotificationsView',
                component: NotificationsView,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/calendar',
                name: 'Calendar',
                component: Calendar,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/editTemplates',
                name: 'EditTemplatesFolder',
                component: EditTemplatesFolder,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
            {
                path: '/backlog',
                name: 'Backlog',
                component: Backlog,
                meta: {
                    isPublic: false,
                    title: 'Project Jove Gestión',
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isPublic: true,
            title: 'Project Jove Gestión',
        },
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            isPublic: true,
            title: 'Project Jove Gestión',
        },
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        props: true,
        component: ResetPassword,
        meta: {
            isPublic: true,
            title: 'Project Jove Gestión',
        },
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        meta: {
            isPublic: true,
            title: 'Project Jove Gestión',
        }, 
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const routeIsPublic = to.matched.some((record) => record.meta.isPublic);
    if (routeIsPublic) {
        next();
        return;
    }
    //check permissions if not to dashboard
  
    if (store.getters['auth/hasAuth']) {
        // check is valid auth or lose session. maybe cookies?
        store.commit('user/setUserAuth', store.state.auth);

        document.title = to.meta.title ?? 'Project Jove Gestión';

    //   if(to.name && to.name === 'ProjectInformation') {
    //     console.log(to.params.id);
    //     to.meta.title = to.params.id;
    //   }

        next();
        return;
    }
  
    next({ name: 'Login' });
});

export default router;
