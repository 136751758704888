/* eslint-disable no-unused-vars */
import NotificationAPI from '../../domain/notification/NotificationApi';


// Get new notifications
// Get notifications with new or not. and total as int
// remove new notifications.

export function getListNotifications({ commit }, tag) {
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.getAllNotification(tag);

  return notificationList
    .then((res) => {
      commit('setNotification', 0);
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function changeToRead({ commit }) {
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.changeToRead();

  return notificationList
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getSendedNotifications({ commit }, userId) {
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.getAllNotificationSended(userId);

  return notificationList
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getSendedNotificationsById({ commit }, projectId) {
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.getSendedNotificationsById(projectId);

  return notificationList
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function saveNotificationsById({ commit }, data) {
  console.log(data)
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.saveNotificationsById(data);

  return notificationList
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getNotificationsCount({ commit }, data) {
  const notificationApi = new NotificationAPI();
  const notificationList = notificationApi.getNotificationsCount(data);

  return notificationList
    .then((res) => {
      return res
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getNewNotifications({ commit }, userId) {
  const notificationApi = new NotificationAPI();
  const notifications = notificationApi.getNewNotification(userId);


  return notifications
    .then((res) => {
      commit('setNotification', res.notifications);
      return res.notifications;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function addNotification({ commit }) {
  commit('upNotification');
  
}

export function addNotificationToArrayAction({ commit }, array) {
  commit('addNotificationArrayToArray', array);
}