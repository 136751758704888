import UserModel from './UserModel';

class UserFactory {
  constructor() {
    this.user = {};
  }

  getUser(data) {
    this.user = new UserModel({
      id: data.id,
      profilepicture: data.profilepicture,
      email: data.email,
      phone: data.phone,
      empresa: data.empresa,
      name: data.firstname,
      dni: data.dni,
      lastname: data.lastname,
      users_has_permissions: data?.users_has_permissions,
      users_groups_has_users: data?.users_groups_has_users,
      users_has_eventos: data?.users_has_eventos,
      users_has_posts: data?.users_has_posts,
      users_has_users_profiles: data?.users_has_users_profiles,
      users_has_users_roles: data?.users_has_users_roles,
      // permissionList: data?.permissionList ? data?.permissionList[0] : null,
      // creation_date: data.creation_date,
    });

    return this.user;
  }

  getUsersList(data) {
    const usersResponse = {
      usersList: this.getCollection(data),
      pagination: 1,
    };
    return usersResponse;
  }
  

  getCollection(usersCollection) {
    const usersList = usersCollection.map(
      (user) => this.getUser(user),
    );
    return usersList;
  }
}

export default new UserFactory();
