import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import UserFactory from './UserFactory';

const USERS = '/api/v1/users';
const PROFILE = `${USERS}/profile`;
const USER__RESET__PASSWORD = `${USERS}/password`;
const AUTH = '/api/v1/auth';

class userAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  resetPassword(resetData) {
    return this.client
      .put(USER__RESET__PASSWORD, resetData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  //test with more users
  getUser() {
    return this.client
      .get(PROFILE)
      .then((res) => UserFactory.getUser(res.data))
      .catch((error) => Promise.reject(error));
  }

  createUser(data) {
    //users id has to be a uuid

    return new Promise((resolve) => {

      const url = `${AUTH}/register`;

      const d = this.client
      .post(url, data)

      resolve(d)
    });
  }

  updateUser(data) {
    return this.client
      .put(USERS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteUser(data) {
    const url = `${USERS}/removeUser`;

    return this.client
      .post(url, data) //delete
      .then()
      .catch((error) => Promise.reject(error));
  }

  // get all users
  getUsersList(){
    const url = `${USERS}/usersList`;
    return this.client
      .get(url)
      .then((res) => UserFactory.getUsersList(res.data))
      .catch((error) => Promise.reject(error));
  }

  // Get all users with a specific permission
  getUsersListByPermission(data){
    const url = `${USERS}/usersListWithPermission`;

    return this.client
      .post(url, data) //delete
      .then((res) => {
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  getUsersListByProfile(data){
    const url = `${USERS}/getUsersListByProfile`;

    return this.client
      .post(url, data) //delete
      .then((res) => {
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  // Get all users without a specific permission
  getUsersListWithoutPermission(data){
    const url = `${USERS}/usersListWithoutPermission`;
  
    return this.client
      .post(url, data)
      .then((res) => {
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  getUsersListWithoutProfile(data){
    const url = `${USERS}/getUsersListWithoutProfile`;
  
    return this.client
      .post(url, data)
      .then((res) => {
        return res;
      })
      .catch((error) => Promise.reject(error));
  }
}

export default userAPI;
