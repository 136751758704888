/* eslint-disable no-unused-vars */
import verificationAPI from '../../domain/verification/VerificationApi';

export function createVerification({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.createVerification(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getVerifications({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.getVerifications(data);

  return verificationObject
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getVerificationsByUserId({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.getVerificationsByUserId(data);

  return verificationObject
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createMilestones({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.createMilestones(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateMilestones({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.updateMilestones(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateVerification({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.updateVerification(data);

  return verificationObject
    .then((res) => {
      // console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteVerification({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.deleteVerification(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function addLinkVerificationFile({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.addLinkVerificationFile(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function removeLinkVerificationFile({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.removeLinkVerificationFile(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteMilestone({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.deleteMilestone(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateMilestonesChecked({ commit }, data) {
  const VerificationAPI = new verificationAPI();
  const verificationObject = VerificationAPI.updateMilestonesChecked(data);

  return verificationObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}