import axios from 'axios';
import { logout } from '@/helpers/UserHelper';

// import store from '../../store/auth/auth';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL, 
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          console.log("perdio sesión");
          logout();
          // const rs =
        //    await axios.post("auth/refresh", {
        //   //   refreshToken: store.state.refreshToken
        //   // });
        //   // const {
        //   //   token,
        //   //   refreshToken
        //   // } = rs.data;
        //   // store.dispatch('setToken', token)
        //   // store.dispatch('setRefToken', refreshToken)
        //   // err.config.headers[
        //   //   "Authorization"
        //   // ] = `Bearer ${token}`;
        //   // return new Promise((resolve, reject) => {
        //   //   axios.request(originalConfig).then(response => {
        //   //     resolve(response);
        //   //   }).catch((err) => {
        //   //     reject(err);
        //   //   })
        //   // });
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;