export function getTree(state) {
  // console.log("estado:", state)
  return {root: state.root, childRoot: state.rootChild, plainRoot: state.plainRoot};
}

export function getRootTreeCount(state) {
  // console.log("estado:", state)
  return state.rootTreeCount;
}

export function getUplaodedFiles(state) {
  // console.log("estado:", state)
  return state.filesUploaded;
}