/* eslint-disable no-unused-vars */
import projectAPI from '../../domain/project/ProjectApi';

export function getProject({ commit }, id) {
  const ProjectAPI = new projectAPI();
  console.log(id);
  const getProjectInfo = ProjectAPI.getProject(id);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAllProjectTemplate({ commit }) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getAllProjectTemplate();

  return getProjectInfo
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getProjectsStatus({ commit }, idList) {
  const ProjectAPI = new projectAPI();
  console.log(idList);
  const getProjectInfo = ProjectAPI.getProjectsStatus(idList);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getLastProjects({ commit }, userid) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getLastProjects(userid);

  return getProjectInfo
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAllProjects({ commit }, userid) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getAllProjects(userid);

  return getProjectInfo
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getProjectDays({ commit }, idList) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getProjectDays(idList);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getParentsProjectById({ commit }, id) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getParentsProjectById(id);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchProjectContainsName({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.searchProjectContainsName(data);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function sendEmailWithFile({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const createEmail = ProjectAPI.sendEmailWithFile(data);

  return createEmail
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function changeProjectsStatus({ commit }, data) {
  const ProjectAPI = new projectAPI();
  console.log(data);
  const getProjectInfo = ProjectAPI.changeProjectsStatus(data);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function addUsersPermisionToProject({ commit }, data) {
  const ProjectAPI = new projectAPI();
  console.log(data);
  const getProjectInfo = ProjectAPI.addUsersPermisionToProject(data);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getUsersPermisionInProject({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const getProjectInfo = ProjectAPI.getUsersPermisionInProject(data);

  return getProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function saveProject({ commit }, project) {
  const ProjectAPI = new projectAPI();
  console.log(project);
  const savedProjectInfo = ProjectAPI.saveProject(project);

  return savedProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function saveProjectProbablyDate({ commit }, project) {
  const ProjectAPI = new projectAPI();
  console.log(project);
  const savedProjectInfo = ProjectAPI.saveProjectProbablyDate(project);

  return savedProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createTemplate({ commit }, project) {
  const ProjectAPI = new projectAPI();
  const savedProjectInfo = ProjectAPI.createTemplate(project);

  return savedProjectInfo
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createProject({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.createProject(data.name, data.id, data.createdBy, data.createdDate, data.type);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function removeUsersPermisionToProject({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.removeUsersPermisionToProject(data);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getImportantFile({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.getImportantFile(data);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteImportantFile({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.deleteImportantFile(data);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function addImportantFile({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.addImportantFile(data);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function renameProject({ commit }, data) {
  const ProjectAPI = new projectAPI();
  const ProjectRequest = ProjectAPI.renameProject(data);

  return ProjectRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}