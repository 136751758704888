import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import AzureFactory from './AzureFactory';

const PROJECT = '/api/v1/projects';
const AZURE = '/api/v1/microsoftAPI';

class ProjectAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getProject(data) {
    const url = `${PROJECT}/getProjectInformation`;
    console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getProjectsStatus(data) {
    const url = `${PROJECT}/getStatusProject`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  searchProjectContainsName(data) {
    const url = `${PROJECT}/searchProjectContainsName`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getLastProjects(data) {
    const url = `${PROJECT}/getLastProjects`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getAllProjects(data) {
    const url = `${PROJECT}/getAllProjects`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getProjectDays(data) {
    const url = `${PROJECT}/getProjectDays`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  addUsersPermisionToProject(data) {
    const url = `${PROJECT}/addUsersPermisionToProject`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getAllProjectTemplate() {
    const url = `${PROJECT}/getAllProjectTemplate`;
    return this.client
      .get(url)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getUsersPermisionInProject(data) {
    const url = `${PROJECT}/getUsersPermisionInProject`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  changeProjectsStatus(data) {
    const url = `${PROJECT}/changeStatusProject`;
    // console.log(data)
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  saveProject(data) {
    const url = `${PROJECT}/saveProjectInformation`;
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  createTemplate(data) {
    const url = `${PROJECT}/createTemplate`;
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  saveProjectProbablyDate(data) {
    const url = `${PROJECT}/saveProjectProbablyDate`;
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getParentsProjectById(data) {
    const url = `${PROJECT}/getParentsProjectById`;
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  renameProject(data) {
    const url = `${AZURE}/renameFolder`;
    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  createProject(data) {
    const url = `${PROJECT}/createProject`;

    return this.client
      .post(url, data)
      .then((res) => {
        console.log(res.data);
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  removeUsersPermisionToProject(data) {
    const url = `${PROJECT}/removeUsersPermisionToProject`;

    return this.client
      .post(url, data)
      .then((res) => {
        console.log(res.data);
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  getImportantFile(data) {
    const url = `${PROJECT}/getImportantFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  addImportantFile(data) {
    const url = `${PROJECT}/addImportantFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  sendEmailWithFile(data) {
    const url = `${PROJECT}/sendEmailWithFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

   deleteImportantFile(data) {
    const url = `${PROJECT}/deleteImportantFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }
}

export default ProjectAPI;
