import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import ProfileFactory from './ProfileFactory';

const PROFILES = '/api/v1/profiles';

class ProfileAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  // Get all profiles
  getProfileList(){
    const url = `${PROFILES}/getProfileList`;

    return this.client
      .get(url)
      .then((res) => ProfileFactory.getProfilesList(res.data))
      .catch((error) => Promise.reject(error));
  }

  // Get all users with a specific profile
  getUsersListByProfile(data){
    console.log('data - prof -> ' + JSON.stringify(data));
    const url = `${PROFILES}/usersListWithProfile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  asignProfileToUsers(data) {
    const url = `${PROFILES}/asignProfileToUsers`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data.success;
      })
      .catch((error) => Promise.reject(error));
  }
}
export default ProfileAPI;
