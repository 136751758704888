<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Añadir" icon="pi pi-plus" class="p-button-jove-primary mr-2" @click="openNew" />
							<Button label="Eliminar" icon="pi pi-trash" class="p-button-jove-tertiary" @click="confirmDeleteSelected" :disabled="!selectedUsers || !selectedUsers.length" />
						</div>
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="usersList" v-model:selection="selectedUsers" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} usuarios" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Lista usuarios</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem">
						<!-- <template #body="data">
							<Checkbox v-if="notMe(data.data)" name="userList" :value="data.data.id" />
						</template> -->
					</Column>
					<Column field="name" header="Nombre" :sortable="true" headerStyle="width:30%; min-width:10rem;">
						<template #body="data">
							<span class="p-column-title">Nombre</span>
							{{data.data.name}}
						</template>
					</Column>
					<Column field="category" header="Email" :sortable="true" headerStyle="width:30%; min-width:10rem;">
						<template #body="data">
							<span class="p-column-title">Email</span>
							{{data.data.email}}
						</template>
					</Column>
					<Column field="inventoryStatus" header="Perfil" :sortable="true" headerStyle="width:30%; min-width:10rem;">
						<template #body="data">
							<span class="p-column-title">Perfil</span>
							<span :class="'customer-badge status-' + `${data.data.lastwarning === 'Administrador' ? 'new' : data.data.lastwarning === 'Interno' ? 'qualified' : 'proposal'}`">{{data.data.dni}}</span>
						</template>
					</Column>
					<Column headerStyle="min-width:10rem;min-heigth:20rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-jove-primary mr-2" @click="editUser(slotProps.data)" />
							<Button v-if="notMe(slotProps.data)" icon="pi pi-trash" class="p-button-rounded p-button-jove-tertiary mt-2" @click="confirmDeleteUser(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<!--MODAL DATOS USUARIO-->
				<Dialog v-model:visible="userDialog" :style="{width: '450px'}" header="Datos usuario" :modal="true" class="p-fluid">
					<!-- <img :src="'images/user/' + user.image" :alt="user.image" v-if="user.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
					<div class="field">
						<div class="mg">
							<label for="name">Nombre</label>
						</div>
						<InputText id="name" v-model.trim="user.name" required="true" autofocus :class="{'p-invalid': submitted && !user.name}" />
						<small class="p-invalid" v-if="submitted && !user.name">Nombre es obligatorio.</small>
					</div>

					<div class="field">
						<div class="mg">
							<label for="name">Apellidos</label>
						</div>
						<InputText id="lastname" v-model.trim="user.lastname" required="true" autofocus :class="{'p-invalid': submitted && !user.lastname}" />
						<small class="p-invalid" v-if="submitted && !user.lastname">Apellidos es obligatorio.</small>
					</div>

					<div class="field">
						<div class="mg">
							<label for="dni">DNI</label>
						</div>
						<InputText id="dni" v-model.trim="user.dni" required="true" :class="{'p-invalid': submitted && !user.dni}" />
						<small class="p-invalid" type="text" v-if="submitted && !user.dni">DNI es obligatorio.</small>
					</div>

					<div class="field" v-if="!editingUser">
						<div class="mg">
							<label for="email">Email</label>
						</div>
						<InputText id="email" v-model.trim="user.email" required="true" :class="{'p-invalid': submitted && !user.email}" />
						<small class="p-invalid" type="email" v-if="submitted && !user.email">Email es obligatorio.</small>
					</div>

					<div class="field">
						<div class="mg">
							<label for="phone">Teléfono</label>
						</div>
						<InputText id="phone" type="phone" v-model.trim="user.phone" required="true" :class="{'p-invalid': submitted && !user.phone}" />
						<small class="p-invalid" type="phone" v-if="submitted && !user.phone">Este campo es obligatorio.</small>
					</div>

					<div class="field" v-if="!editingUser">
						<div class="mg">
							<label for="password">Contraseña</label>
						</div>
						<InputText id="password" type="password" v-model.trim="user.password" :class="{'p-invalid': submitted && !user.password}"/>
						<small class="p-invalid" type="password" v-if="submitted && !user.password">Contraseña es obligatoria.</small>
					</div>

					<div class="field">
						<div class="mg">
							<label for="company">Empresa</label>
						</div>
						<InputText id="company" type="text" v-model.trim="user.empresa"/>
					</div>

					<!-- PERMISOS-->
					<!-- <div class="field">
						<div class="mg" >
							<label for="inventoryStatus" class="mb-3">Permisos</label>
						</div>
						<AutoComplete :multiple="true" v-model="selectedPermissions" :suggestions="filteredPermissionsList" 
						@complete="searchPermission($event)" field="name" dropdown/>
					</div> -->
					<!--// PERMISOS-->

					<!--ROL-->
					<div class="field"  v-if="!editingUser">
						<div class="mg">
							<label for="inventoryStatus" class="mb-3">Rol</label>
						</div>
						<AutoComplete emptySearchMessage="No existen roles" v-model="selectedRoles" :suggestions="filteredRolesList"
						@complete="searchRol($event)" field="name" dropdown/>
					</div>
					<!--// ROL-->

					<!--PERFIL-->
					<div class="field"  v-if="!editingUser">
						<div class="mg">
							<label for="inventoryStatus" class="mb-3">Perfil</label>
						</div>
						<AutoComplete v-model="selectedProfiles" :suggestions="filteredProfilesList"
						@complete="searchProfile($event)" field="name" dropdown/>
					</div>
					<!--// PERFIL-->

					<!--GRUPO-->
					<!-- <div class="field">
						<div class="mg">
							<label for="inventoryStatus" class="mb-3">Grupo</label>
						</div>
						<AutoComplete :multiple="true" v-model="selectedGroups" :suggestions="filteredGroupsList"
						@complete="searchGroup($event)" field="name" dropdown/>
					</div> -->
					<!--// GRUPO -->
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-jove-tertiary" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-jove-primary" @click="saveUser" :loading="sended"/>
					</template>
				</Dialog>
				<!--// MODAL DATOS USUARIO-->

				<Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="user">¿Seguro que quieres eliminar a <b>{{user.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-jove-tertiary" @click="deleteUserDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-jove-primary" :loading="sended" @click="deleteUser" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="user">¿Seguro que quieres eliminar a los usuario seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-jove-tertiary" @click="deleteUsersDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-jove-primary" @click="deleteselectedUsers" />
					</template>
				</Dialog>
			</div>
		</div>
		<Toast />
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';


export default {
	data() {
		return {
			usersList: null,
			userDialog: false,
			deleteUserDialog: false,
			deleteUsersDialog: false,
			user: {},
			selectedUsers: null,
			filters: {},
			pagination: 1,
			submitted: false,
			loading: true,
			sended: false,
			// users: null,
			statuses: [
				{label: 'Administrador', value: 'admin'},
				{label: 'Externo', value: 'extern'},
				{label: 'Interno', value: 'intern'}
			],
			// New Vars
			//Permissions
			permissionsList: null,
			selectedPermissions: [],
			filteredPermissionsList: null,
			// Roles
			rolesList: null,
			selectedRoles: [],
			filteredRolesList: null,
			// Profiles
			profilesList: null,
			selectedProfiles: [],
			filteredProfilesList: null,
			//Groups
			groupsList: null,
			selectedGroups: [],
			filteredGroupsList: null,
			editingUser: false,
		}
	},
	customerService: null,
	created() {
		// this.customerService = new CustomerService();
		this.initFilters();
	},
	async mounted() {
		await this.$store.dispatch('user/getUsersList')
		.then( async(data) => {
			this.usersList = data.usersList;
			this.pagination = data.pagination;

			// Permissions, Roles, Profiles, Groups
			Promise.all([
				await this.$store.dispatch('permission/getPermissionList'), 
				await this.$store.dispatch('rol/getRolList'),
				await this.$store.dispatch('profile/getProfileList'),
				await this.$store.dispatch('group/getGroupsList')
			])
			.then( async(values) => {
					this.permissionsList = values[0].permissionsList;
					this.rolesList = values[1].rolesList;
					this.profilesList = values[2].profilesList;
					this.groupsList = values[3].groupList;

			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});	
		})
		.catch((e) => {
			console.log(e)
			this.isLoading = false;
		});
	},

	methods: {
		searchPermission(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredPermissionsList = [...this.permissionsList];
                }
                else {
                    this.filteredPermissionsList = this.permissionsList.filter((perm) => {
                        return perm.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
		searchRol(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredRolesList = [...this.rolesList];
                }
                else {
                    this.filteredRolesList = this.rolesList.filter((rol) => {
                        return rol.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
		searchProfile(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProfilesList = [...this.profilesList];
                }
                else {
                    this.filteredRofilteredProfilesListlesList = this.profilesList.filter((prof) => {
                        return prof.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
		searchGroup(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredGroupsList = [...this.groupsList];
                }
                else {
                    this.filteredGroupsList = this.groupsList.filter((group) => {
                        return group.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
		goToPermissions() {
			this.$router.push('/permissions'); 
		},
		goToRoles(){
			this.$router.push('/rolesList'); 
		},
		goToProfiles(){
			this.$router.push('/profiles'); 
		},
		goToGroups(){
			this.$router.push('/groupsList'); 
		},
		formatDate(value) {
			if(value)
			return value.toLocaleDateString('es-ES', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		openNew() {
			this.user = {};
			this.editingUser = false;
			this.submitted = false;
			this.userDialog = true;
		},
		hideDialog() {
			//console.log('!! user ' + JSON.stringify(this.user));
			this.userDialog = false;
			this.submitted = false;
			this.selectedProfiles = [];
			this.selectedRoles = [];
		},
		async saveUser() {
			console.log('va')
			this.submitted = true;

			let edit = ((!this.editingUser && this.user?.password.replace(/\s+/g, '').length > 0) || this.editingUser);
			console.log(edit)
			if(
				this.user?.name.replace(/\s+/g, '').length > 0 &&
				this.user?.lastname.replace(/\s+/g, '').length > 0 &&
				this.user?.dni.replace(/\s+/g, '').length > 0 &&
				this.user?.phone.replace(/\s+/g, '').length > 0 &&
				edit &&
				this.user?.email.replace(/\s+/g, '').length > 0
			) {

			console.log('no va')

				
				this.sended = true;

				let userData = !this.editingUser ? { 
					firstname: this.user.name, dni: this.user.dni, lastname: this.user.lastname, 
					email: this.user.email, password: this.user.password, phone: this.user.phone, empresa: this.user.empresa
				} : { 
					firstname: this.user.name, dni: this.user.dni, lastname: this.user.lastname, 
					email: this.user.email, phone: this.user.phone, empresa: this.user.empresa
				}

				//create users
				let user = null;
				await this.$store.dispatch('user/createUser', userData).then(async (data) => {
					if(data.error) {
						this.$toast.add({severity:'danger', summary: 'Error', detail:'Falta algún dato o sucedió un error', life: 3000});
						this.sended = false;
						this.submitted = false;
					}
					user = data;
				})

				if(this.selectedProfiles.length > 0) {
					// Permission
					let usersSelectedToAddPerm = [];
					console.log(user.data)
					usersSelectedToAddPerm.push({users_profiles_id: Number(this.selectedProfiles.id), users_id: Number(user.data.userId)});
					await this.$store.dispatch('profile/asignProfileToUsers', { userHasProfile: usersSelectedToAddPerm })
							.then(() => { // res error o not
							
							}
						)
				}

				if(this.editingUser) this.$toast.add({severity:'success', summary: 'Usuario actualizado', detail:'Usuario actualizado correctamente', life: 3000});
				else this.$toast.add({severity:'success', summary: 'Usuario creado', detail:'Usuario creado correctamente', life: 3000});

				//add catch to close and show error if is something with the server if not show email or dni is in used
				await this.$store.dispatch('user/getUsersList')
				.then( async(data) => {
					this.usersList = data.usersList;
					this.pagination = data.pagination;
					this.isTableLloadingoading = false;
				})
				.catch((e) => {
					console.log(e)
					this.isLoading = false;
				});

				//Todo: show errors, is email is used show message, etc.
				//console.log(this.user);
				this.userDialog = false;
				this.editingUser = false;
				this.sended = false;
				this.submitted = false;
			}
		},
		editUser(user) {
			this.user = {...user};
			this.editingUser = true;
			this.submitted = false;
			this.userDialog = true;
		},
		confirmDeleteUser(user) {
			this.user = user;
			this.deleteUserDialog = true;
		},
		async deleteUser() {
			this.sended = true;
			console.log(this.user.id)
			await this.$store.dispatch('user/deleteUser', { 
				userId: this.user.id
			});

			//add catch to close and show error if is something with the server if not show email or dni is in used

			await this.$store.dispatch('user/getUsersList')
			.then( async(data) => {
				this.usersList = data.usersList;
				this.pagination = data.pagination;
				this.isTableLloadingoading = false;
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});

			//Todo: show errors, is email is used show message, etc.

			this.deleteUserDialog = false;
			this.sended = false;

			this.$toast.add({severity:'success', summary: 'Usuario eliminado', detail:'Usuario eliminado correctamente', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteUsersDialog = true;
		},
		deleteselectedUsers() {
			this.users = this.users.filter(val => !this.selectedUsers.includes(val));
			this.deleteUsersDialog = false;
			this.selectedUsers = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Users Deleted', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		notMe(data) {
			let me = this.$store.getters['user/getCurrentUser'] 
			return data.id != me.id;
		}
	}
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';

	.mg{
		margin: 1.25rem 0rem 0.75rem;
		font-weight: 700;
	}
</style>
