import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import AzureFactory from './AzureFactory';

const AZURE = '/api/v1/microsoftAPI';

class azureAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getRootTree(data) {
    const url = `${AZURE}/getRootTree`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  getFiles(data) {
    const url = `${AZURE}/site`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }

  uploadFiles(data) {
    const url = `${AZURE}/upLoadFile`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  } 

  removeFile(data) {
    const url = `${AZURE}/removeFile`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  } 

  uploadSmallFile(data) {
    const url = `${AZURE}/uploadProfilePicture`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  } 

  removeProject(data) {
    const url = `${AZURE}/deleteFolder`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }
  
  searchFiles(data) {
    const url = `${AZURE}/search`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getRootTree(
      .catch((error) => Promise.reject(error));
  }
  
  createFolder(data) {
    const url = `${AZURE}/create-folder-root`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  protectFile(data) {
    const url = `${AZURE}/protectFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  getProtectFile(data) {
    const url = `${AZURE}/getProtectFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  removeProtectFile(data) {
    const url = `${AZURE}/removeProtectFile`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  copyFileToAnotherFolder(data) {
    const url = `${AZURE}/copyFileToAnotherFolder`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => Promise.reject(error));
  }

  createSubFolder(data) {
    const url = `${AZURE}/create-folder`;

    return this.client
      .post(url, data)
      .then((res) => {
        res.data
      })
      .catch((error) => Promise.reject(error));
  }

  getChildTree(data) {
    const url = `${AZURE}/item-children`;

    return this.client
      .post(url, data)
      .then((res) => res.data) //AzureFactory.getChildTree(res.data)
      .catch((error) => Promise.reject(error));
  }
}

export default azureAPI;
