<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="surface-section p-5 mb-4 card">
        <div class="mt-4">
            <div class="flex mb-4">
                <FileUpload mode="basic" :multiple="false" :customUpload="true" :auto="true" chooseLabel="Subir archivos" name="file" class="mr-3"  :disabled="blockUploadButton"  @uploader="(file) => onUpload(file, false)" />
                <Button :disabled="BlockFolderButtons" label="Crear carpeta" icon="pi pi-plus" class="mr-3" @click="createFolder" />
                <Button :disabled="BlockButtons" label="Editar nombre" icon="pi pi-pencil" class="mr-3" @click="editName" />
                <Button :disabled="BlockButtons" label="Eliminar" icon="pi pi-trash" @click="confirmDelete" />
                <Button :disabled="BlockEyeButton" label="Ver archivo" icon="pi pi-eye" @click="openFile" />
            </div>
            <Tree :value="treeValue" lazy :filter="true" filterMode="lenient" v-model:selectionKeys="selectedTreeValue" selectionMode="single" :loading="isLoading" @nodeExpand="onNodeExpand" @nodeCollapse="onNodeCollapse" @node-select="onNodeSelect">
                <!-- :expandedKeys="expandedKeys" -->
                <template #default="slotProps">
					<b><span :title="slotProps.node.label">{{ slotProps.node.label }}</span></b>
				</template>
				<template #url="slotProps">
					<a :href="slotProps.node.data"><span :title="slotProps.node.labelhover">{{ slotProps.node.label }}</span></a>
				</template>
            </Tree>

            <!-- Menu Folder Start -->
            <Dialog v-model:visible="selectNameDialog" :style="{width: '450px'}" :header="`Escribe el nombre del archivo `" :modal="true" class="p-fluid">
                <div class="field">
                    <label for="name">Nombre</label>
                    <InputText v-model="templateName" id="templateName" required="true" autofocus :class="{'p-invalid': submitted}" />
                    <!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideSelectName"/>
                    <Button label="Crear" icon="pi pi-check" class="p-button-text" :disabled="templateName && templateName.trim().length < 0" @click="saveSelectName" />
                </template>
            </Dialog>
            <!-- Menu Folder End -->

            <!-- Menu Folder Start -->
            <Dialog v-model:visible="selectNameFolderDialog" :style="{width: '450px'}" :header="`Escribe el nombre de la carpeta`" :modal="true" class="p-fluid">
                <div class="field">
                    <label for="name">Nombre</label>
                    <InputText v-model="folderName" id="templateName" required="true" autofocus :class="{'p-invalid': submitted}" />
                    <!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideCreateFolder"/>
                    <Button label="Crear" icon="pi pi-check" class="p-button-text" :disabled="folderName && folderName.trim().length < 0" @click="saveSelectFolderName" />
                </template>
            </Dialog>
            <!-- Menu Folder End -->
            <Dialog v-model:visible="deleteAccessDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedAccess">¿Estás seguro que quieres eliminar estos accesos?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteAccessDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedAccess" />
					</template>
				</Dialog>
        </div>
    </div>
</template>

<script>
import NodeService from '../service/NodeService';
export default {
    inject: ['dialogRef'],
    data() {
        return {
            folders: null,
            selectedKey: null, // guardar en session y abrir para mantener el arbol como deberia estar 
            selectNameFolderDialog: false,
            expandedKeys: [], // guardar en session y abrir para mantener el arbol como deberia estar 
            treeValue: null,
            deleteAccessDialog: false,
            selectedTreeValue: null,
            plainTree: null,
            treeTableValue: null,
            selectedTreeTableValue: null,
            isLoading: true,
            blockUploadButton: true,
            BlockFolderButtons: true,
            submitted: false,
            folderName: '',
            templateName: '',
            selectNameDialog: false,
            nodeSelected: null,
            BlockButtons: true,
            BlockEyeButton: true,
        };
    },
    treeService: null,
    created() {
        this.treeService = new NodeService();
    },
    mounted() {
        this.treeService.getTreeNodes().then((data) => (this.treeValue = data)); // add to .env now it is public
        this.isLoading = false;
    },
    methods: {
        openFile () {
            window.open(this.nodeSelected.url, '_blank');
        },
        confirmDelete() {
			this.$confirm.require({
                message: '¿Seguro que quieres continuar?',
                header: 'Confirmar',
                acceptLabel: 'Sí',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    await this.$store.dispatch('azure/removeFile', {userId: this.getUser.id, file_id: this.nodeSelected.key, editTemplates: true})
                    this.$toast.add({severity:'success', summary: 'Archivo eliminado', detail:'Archivo eliminado correctamente', life: 3000});
                    this.treeService.getTreeNodes().then((data) => (this.treeValue = data));
                    this.selectedKey= null;
                    this.expandedKeys= [];
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
                onShow: () => {
                    //callback to execute when dialog is shown
                },
                onHide: () => {
                    //callback to execute when dialog is hidden
                }
            })
		},
        editName() {
            this.submitted = false;
            this.selectNameDialog = true;
            this.templateName = this.nodeSelected.label;
        },
        createFolder() {
            this.submitted = false;
            this.selectNameFolderDialog = true;
        },
        async saveSelectFolderName () {
            await this.$store.dispatch('azure/createSubFolder', {
                name: this.folderName.replace(/^\/\*-\.;,+/i, ''), 
                id: this.nodeSelected.key,
                editTemplates: true,
            })
            this.folderName = '';
            this.$toast.add({severity:'success', summary: 'Carpeta creada', detail:'Carpeta creada correctamente', life: 3000});
            this.treeService.getTreeNodes().then((data) => (this.treeValue = data));
            this.selectedKey= null;
            this.expandedKeys= [];
            this.selectNameFolderDialog = false;
        },
        hideCreateFolder () {
            this.selectNameFolderDialog = false;
        },
        async onUpload(e, replace) {
            console.log(this.nodeSelected)
            if(this.nodeSelected.data === 'Documents Folder') {
                if (e.files[0] && e.files[0].name) {

                    let formy = new FormData();
                    formy.append('file', e.files[0]);
                    formy.append('folder_id', this.nodeSelected.key ?? '01OJ57ZBN5HBIK2J554NC2BXT2NX6JLVCY');
                    formy.append('name', e.files[0].name);
                    console.log(this.getUser)
                    formy.append('replace', replace);
                    formy.append('userId', this.getUser.id);

                    this.blockUploadButton = true;

                    await this.$store.dispatch('azure/upLoadFile', formy).then( async(data) => {
                        this.blockUploadButton = false;
                        // if(this.expandedKeys.find((e) => e.key == this.nodeSelected.key)) {
                        //     this.nodeSelected.children.push({
                        //         key: data.id,
                        //         label: e.files[0].name,
                        //         creationDate: data.createdDateTime,
                        //         data:'File',
                        //         icon: 'pi pi-fw pi-file',
                        //         child: 0,
                        //         leaf: true,
                        //         url: data.webUrl,
                        //     })
                        // }  
                        
                        console.log(data)
                    }).catch(() => {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
                        this.blockUploadButton = false;
                    });

                    // this.treeService.getTreeNodes().then((data) => (this.treeValue = data));

                         

                    // if(this.expandedKeys.includes(this.nodeSelected.key)) {
                    //     await this.$store.dispatch('azure/getFiles', {
                    //     id: this.nodeSelected.key
                    //     }).then( async(data) => {
                    //         this.files = data;
                    //     });
                    // }

                    if(this.expandedKeys.includes(this.nodeSelected.key))  {
                        this.onNodeExpand(this.nodeSelected);    
                    }
                    this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
                    
                }
            } else {
                this.$toast.add({ severity: 'info', summary: 'Error', detail: 'Solo puedes subir archivo a una carpeta', life: 3000 });
            }
        },
        hideSelectName (){
            this.selectNameDialog = false;
            this.templateName = '';
        },
        onNodeSelect(data) {
            this.nodeSelected = data;
            if(this.nodeSelected.key !== '01OJ57ZBN5HBIK2J554NC2BXT2NX6JLVCY') this.BlockButtons = false; 
            else this.BlockButtons = true;
            if(this.nodeSelected.data === 'Documents Folder') {
                this.blockUploadButton = false;
                this.BlockEyeButton = true;
                this.BlockFolderButtons = false;
            } else {
                this.BlockEyeButton = false;
                this.blockUploadButton = true;
                this.BlockFolderButtons = true;
            }
            // this.templateName = data.label;
            // this.selectNameDialog = true;
        },
        async saveSelectName() {
            this.selectNameDialog = false;
            this.nodeSelected.label = this.templateName;
            await this.$store.dispatch('project/renameProject', {
                name: this.nodeSelected.label , folder_id: this.nodeSelected.key, isFile: true
            });
            // this.dialogRef.close(this.nodeSelected);
        },
        onNodeCollapse () {
            // console.log(node)
            // this.expandedKeys = this.expandedKeys.filter((x) => x.key !== node.key);
            // console.log(this.expandedKeys)
        },
        async onNodeExpand(node) {
            console.log(node)
            if (!node.children) {
                this.isLoading = true;

                let _node = {...node};
                _node.children = [];

                if(!this.expandedKeys.find((e) => e.key == node.key)) this.expandedKeys.push({key: node.key})

                console.log(this.expandedKeys)

                await this.$store.dispatch('azure/getChildTree', {id: _node.key, templateFolder: true, withFiles: true})
                    .then( async(data) => {
                        console.log(data);
                        _node.children = data;
                        this.isLoading = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        this.isLoading = false;
                });

                console.log(_node);
                
                let _nodes = [...this.treeValue];
                console.log(_nodes);

                let obj = _nodes.find(x => x.key === _node.key)

                console.log(obj);

                
                if(obj) {
                    obj.children = [..._node.children];//capítulo
                    console.log(1)

                } else {
                    _nodes.forEach(element => {
                        console.log('testea:', element);
                        if(element?.children && element?.children.length > 0) {
                            element?.children.forEach(elementx => {
                                if(elementx.key === _node.key) obj = elementx;
                            });
                        }
                    });

                    if(obj) {

                        obj.children = [..._node.children];//subcapítulo
                        console.log(2)
                    } else {
                        _nodes.forEach(element => {
                            console.log('prueba:', element);
                            if(element?.children && element?.children.length > 0) {
                                element?.children.forEach(elementx => {
                                    console.log('elementx:', elementx)
                                    if(elementx?.children && elementx?.children.length > 0) {
                                        elementx?.children.forEach(elementy => {
                                            if(elementy.key === _node.key) obj = elementy;
                                        });
                                    }
                                });
                            }
                        });

                        if(obj) {

                            obj.children = [..._node.children];//expediente
                            console.log(3)
                        }
                    }
                }

                this.treeValue = _nodes;
            }
        },
    },
    computed: {
        getUser() {
            return this.$store.getters['user/getCurrentUser'];
        },
    }
};
</script>

<style scoped>
button {
    margin-right: 0.5rem;
}
</style>