import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import PermissionFactory from './PermissionFactory';

const PERMISSIONS = '/api/v1/permissions';

class PermissionAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  // Get all permissions
  getPermissionList(){
    const url = `${PERMISSIONS}/getPermissionList`;

    return this.client
      .get(url)
      .then((res) => PermissionFactory.getPermissionsList(res.data))
      .catch((error) => Promise.reject(error));
  }

  getPermissionListByUserId(data){
    const url = `${PERMISSIONS}/getPermissionsListByUserId`;

    return this.client
      .post(url,data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  //Insert on users_has_permissions
  asignPermissionToUsers(data) {
    // console.log('API - asignPermissionToUsers data -> ' + data);
    const url = `${PERMISSIONS}/asignPermissionToUsers`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data.success;
      })
      .catch((error) => Promise.reject(error));
  }

  deleteUserFromPermissions(data) {
    const url = `${PERMISSIONS}/deleteUserFromPermissions`;

    return this.client
      .post(url, data)
      .then((res) => {
        return res.data.success;
      })
      .catch((error) => Promise.reject(error));
  }
}

export default PermissionAPI;
