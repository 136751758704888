<template>
    <div class="isLoadingDiv" v-if="isLoadingDiv">
		<div class="spinnerDiv" id="textnode">
			<ProgressSpinner/>
		</div>
	</div>
    <div class="card">
        <h3 class="mb-4"><b>Nº resultados: {{ files.length }}</b></h3> 
        <DataTable :globalFilterFields="['projectName']" :filters="filesSearch" :value="files" tableStyle="min-width: 50rem">
            <!-- <Column field="id" header="ID"></Column>
            <Column field="parentReference.id" header="id padre"></Column> -->
            <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filesSearch['global'].value" class="w-full" placeholder="Buscar proyecto..." />
                    </span>
                </div>
            </template>
            <Column field="folderName" :sortable="true" header="Carpeta" headerStyle="width:40%; min-width:10rem;"></Column>
            <Column field="projectName" :sortable="true" header="Proyecto" headerStyle="width:40%; min-width:10rem;"></Column>
            <Column field="name" :sortable="true" header="Nombre" headerStyle="width:40%; min-width:10rem;"></Column>
            <Column field="createdDateTime" header="Fecha creación" :sortable="true" headerStyle="width:20%; min-width:10rem;">
                <template #body="data">
                    <span class="p-column-title">Fecha creación</span>
                    {{formatDate(new Date(data.data.createdDateTime))}}
                </template>
            </Column>
            <!-- <Column field="file.mimeType" header="Tipo" :sortable="true" headerStyle="width:20%; min-width:10rem;">
                <template #body="data">
                    <span class="p-column-title">Tipo</span>
                    <font-awesome-icon :icon="typeIcon(data.data.file.mimeType)" size="2x" />
                </template>
            </Column>
            <Column field="size" header="Tamaño" headerStyle="width:20%; min-width:10rem;">
                <template #body="data">
                    <span class="p-column-title">Tamaño</span>
                    <span class="customer-badge status-new">{{formatBytes(data.data.size)}}</span>
                </template>
            </Column> -->
            <Column headerStyle="width:10%; min-width:10rem;">
                <template #body="data">
                    <Button :disabled="blockButton" icon="pi pi-eye" class="p-button-rounded p-button-jove-primary mr-2 md:mb-0 mb-2" type="submit" @click="getSharedLink(data.data.id, true)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>


<script>
	// import draggable from "vuedraggable";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        props: {
        },
		data() {
			return {
                files: [],
                blockButton: false,
                isLoadingDiv: true,
                filesSearch: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} },
            }
        },
        async mounted() {
            this.initFilters();

            if(this.$route.params.search) {

                

                this.files = [];

                await this.$store.dispatch('azure/searchFiles', {stringSearch: this.$route.params.search})
                    .then( (data) => {
                       console.log('busqueda:', data)
                       data.forEach(element => {
                            // if(element.file) {
                                this.files.push(element);
                            // }
                       });

                       this.isLoadingDiv = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        this.isLoading = false;
                    });

            }
        },
        methods: {
            initFilters() {
				this.filesSearch = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
            openUrl(url, anotherTab = false) {
				window.open(url, anotherTab ?'_blank' : '');
			},
            async getSharedLink(id) {
				this.blockButton = true;
				const link = await this.$store.dispatch('azure/getFiles', {
					id,
					sharedLink: true,
				})

				this.openUrl(link, true);
				this.blockButton = false;
			},
            formatBytes(bytes, decimals = 2) {
				if (!+bytes) return '0 Bytes'

				const k = 1024
				const dm = decimals < 0 ? 0 : decimals
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

				const i = Math.floor(Math.log(bytes) / Math.log(k))

				return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
			},
            typeIcon (mime_type) {
				const icon_classes = {
					// Media
					'image' : 'fa-file-image',
					'audio' : 'fa-file-audio',
					'video' : 'fa-file-video',
					// Documents
					'application/pdf' : 'fa-file-pdf',
					'application/msword' : 'fa-file-word',
					'application/vnd.ms-word' : 'fa-file-word',
					'application/rtf' : 'fa-file-word',
					'application/vnd.oasis.opendocument.text' : 'fa-file-word',
					'application/vnd.openxmlformats-officedocument.wordprocessingml' : 'fa-file-word',
					'application/vnd.ms-excel' : 'fa-file-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml' : 'fa-file-excel',
					'application/vnd.oasis.opendocument.spreadsheet' : 'fa-file-excel',
					'application/vnd.ms-powerpoint' : 'fa-file-powerpoint',
					'application/vnd.openxmlformats-officedocument.presentationml' : 'fa-file-powerpoint',
					'application/vnd.oasis.opendocument.presentation' : 'fa-file-powerpoint',
					'text/plain' : 'fa-file-text',
					'text/html' : 'fa-file-code',
					'application/json' : 'fa-file-code',
					// Archives
					'application/gzip' : 'fa-file-archive',
					'application/zip' : 'fa-file-archive',
				};

				return icon_classes[mime_type] ?? 'fa-file';
			},
            formatDate(value) {
				return value.toLocaleDateString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
        },
        watch: {
			async "$route.params.search"() {

                if(this.$route.params.search.length > 0) {

                    this.isLoadingDiv = true;
                    this.files = [];

                    await this.$store.dispatch('azure/searchFiles', {stringSearch: this.$route.params.search})
                        .then( (data) => {
                        console.log('busqueda:', data)
                        data.forEach(element => {
                                // if(element.file) {
                                    this.files.push(element);
                                // }
                        });

                        this.isLoadingDiv = false;
                        })
                        .catch((e) => {
                            console.log(e)
                            this.isLoading = false;
                        });
                }
            }
        },  
        computed: {
          
        },
        components: {
            // draggable
        }

    }
</script>

<style>
.color-chip-complete {
    background-color: #22C55E;
    color: white;
}

.p-panel .p-panel-header {
    color: white !important;
}

.p-panel-header-icon.p-panel-toggler {
    color: white !important;
}

.p-panel.green-500 .p-panel-header {
    background: var(--green-500);
}

.p-panel.orange-500 .p-panel-header {
    background: var(--orange-500);
}

.p-panel.red-500 .p-panel-header {
    background: var(--red-500);
}

.isLoadingDiv {
	/* positions to point 0,0 - required for z-index */
	position: fixed; top: 0; left: 0; 
    /* above all elements, even if z-index is used elsewhere
       it can be lowered as needed, but this value surpasses
       all elements when used on YouTube for example. */
    z-index: 9999999999;               
    /* takes up 100% of page */
    width: 100%; height: 100%;         
    /* dim the page with 50% black background when visible */
    background-color: rgba(0,0,0,0.5);
    /* a nice fade effect, visibility toggles after 175ms, opacity will animate for 175ms. note display:none cannot be animated.  */
    transition: visibility 175ms, opacity 175ms;
	display: table;
}

#textnode {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    transition: font-size 175ms;
}

.spinnerDiv {
	width: 50px;
	height: 50px;
}
</style>