<template>
	<div class="grid">
		<!-- <div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Usuarios</span>
						<div class="text-900 font-medium text-xl">4</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-user text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div> -->
		<div class="col-12 lg:col-8">
			<div class="card mb-0 flex align-items-center justify-content-center h-full">
				<div class="flex justify-content-center mb-3">
					<div>
						<div class="text-900 font-medium text-xl">Hola {{ getUser.name.charAt(0).toUpperCase() + getUser.name.slice(1) }} {{ getUser?.lastname ?? '' }} </div>
						<!-- <span class="wave">👋</span> -->
						<div>
							<Vue3Lottie :animationData="WelcomeAnimation" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-4">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Proyectos</span>
						<div class="text-900 font-medium text-xl">{{ getRootTreeCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-folder text-orange-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">%50+ </span>
				<span class="text-500">desde la última visita</span> -->
			</div>
		</div>
		<!-- <div class="col-12 lg:col-6">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Mensajes</span>
						<div class="text-900 font-medium text-xl">{{ countNotifications }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-inbox text-cyan-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div> -->

	<div class="col-12 xl:col-6">
		<div class="card">
			<h5>Eventos</h5>
            <FullCalendar :options="calendarOptions"/>
		</div>
		<!-- <div class="card">
			<h5>Estadísticas</h5>
			<Chart type="line" :data="lineData" :options="lineOptions" />
		</div> -->
	</div>
	<div class="col-12 xl:col-6">
		<div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>Últimos Proyectos</h5>
				<!-- <div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div> -->
			</div>
			<ul class="list-none p-0 m-0">
				<li v-for="(element, index) in projectsList" :key="index" class="cursor-pointer flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span @click="goToProject(element.id)" class="text-900 font-medium mr-2 mb-1 md:mb-0">{{element.name}}</span>
						<div @click="goToProject(element.id)" class="mt-1 text-600">{{ element.status && element.status != '' ? this.MainstatusOptions.find(x => x.code === element.status).name : element.projectStatus && element.projectStatus != '' ? this.statusOptions.find(x => x.code === element.projectStatus).name : element.id}}</div>
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div :class="`bg-${this.colorRange.find(x => x.start <= (element.progress ?? 0) && x.end >= (element.progress ?? 0) ).color} h-full`" :style="`width:${element.progress ?? 0}%`"></div>
						</div>
						<span :class="`text-${this.colorRange.find(x => x.start <= (element.progress ?? 0) && x.end >= (element.progress ?? 0) ).color} ml-3 font-medium`">{{element.progress ?? 0}}%</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Verificaciones</h5>
			</div>
			
			<div v-for="(element, index) in verifications" :key="index">
				<span class="block text-600 font-medium mb-3">{{ element.date === new Date().toLocaleDateString("es-ES") ? 'Hoy' : element.date }}</span>
				<ul class="p-0 mx-0 mt-0 mb-4 list-none">
					<!-- pi-flag-fill -->
					<li class="cursor-pointer flex align-items-center py-2 border-bottom-1 surface-border" v-for="(element2, index2) in element.verifications" :key="index2" @click="goToProject(element2.folder.id)">
						<div :class="`w-3rem h-3rem flex align-items-center justify-content-center bg${this.typeColor.find(x => x.code === element2.folder.type).color} border-circle mr-3 flex-shrink-0`">
							<i :class="`pi pi-folder text-xl text-white`"></i>
						</div>
						
						<span class="text-900 line-height-3">{{ element2.title }}
							<span class="text-700 mr-4">Proyecto: <span class="text-blue-500">{{ element2?.folder?.project?.name ?? element2.folder.name }}</span></span>
							<span v-tooltip.top="'Verificación fuera de tiempo estimado'"><font-awesome-icon v-if="new Date(element2.probablydate) <= new Date()" style="color: #c81919" icon="fa-sharp fa-regular fa-circle-exclamation" size="2x"/></span>
						</span>
					</li>
				</ul>
			</div>

		</div>
	</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es';
// import WelcomeAnimation from '../../public/animations/122304-saludando.json'

export default {
	components: {
		FullCalendar,
	},
	data() {
		return {
			// WelcomeAnimation,
			countNotifications: 0,
			typeColor: [
			{name: 'Proyecto', code: 'PROJECT', color: '-gray-500'},
			{name: 'Capítulo', code: 'CHAPTER', color: '-blue-500'},
			{name: 'Subcapítulo', code: 'SUBCHAPTER', color: '-green-500'},
			{name: 'Expediente', code: 'RECORD', color: '-orange-500'},
			],
			statusOptions: [
				{name: 'Abierto', code: 'Open'},
				{name: 'En proceso', code: 'In_process'},
				{name: 'Cerrado', code: 'Closed'},
				{name: 'Bloqueado', code: 'Blocked'},
			],
			colorRange: [
				{
					start: 0,
					end: 20,
					color: 'cyan-500'
				},
				{
					start: 21,
					end: 40,
					color: 'green-500'
				},
				{
					start: 41,
					end: 60,
					color: 'orange-500'
				},
				{
					start: 61,
					end: 80,
					color: 'pink-500'
				},
				{
					start: 81,
					end: 100,
					color: 'purple-500'
				},
			],
			lineData: {
				labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
				datasets: [
					{
						label: 'Proyectos',
						data: [3, 4, 10, 5, 7, 12, 4],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: 0.4
					},
					{
						label: 'Terminados',
						data: [2, 3, 8, 1, 2, 5, 2],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e',
						tension: 0.4
					}
				]
			},
			calendarOptions: {
				plugins: [ dayGridPlugin, interactionPlugin ],
				locale: esLocale,
				initialView: 'dayGridMonth',
				events: [],
				eventTimeFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                },
			},	
			items: [
                {label: 'Crear nuevo', icon: 'pi pi-fw pi-plus'},
                {label: 'Eliminar', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
			projectsList: [],
			verifications: [],
			MainstatusOptions: [
				{name: 'No planeado', code: 'unplanned'},
				{name: 'No empezado', code: 'not_started'},
				{name: 'No empezado a tiempo', code: 'not_started_on_time'},
				{name: 'Empezado', code: 'started'},
				{name: 'Empezado fuera de tiempo', code: 'started_out_of_time'},
				{name: 'Empezado a tiempo', code: 'not_closed_on_time'},
				{name: 'Cerrado', code: 'closed'},
				{name: 'Cerrado fuera de tiempo', code: 'closed_out_of_time'},
				{name: 'Crítico', code: 'critical'},
			],
		}
	},
	themeChangeListener: null,
	async mounted() {
		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);
		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }

		await this.$store.dispatch('event/getEventList', { user_id: this.getUser.id, email: this.getUser.email }).then( async(data) => {
            data.data.forEach((a) => {
				console.log(a)
                this.calendarOptions.events.push({title: a.title, start: new Date(a.startdate).toISOString().slice(0, -5), end: new Date(a.enddate).toISOString().slice(0, -5)});
            })

			if(data?.microsoft) data.microsoft.forEach((a) => {
				console.log(a)
                this.calendarOptions.events.push({title: a.subject, allDay: true, start: new Date(a.start.dateTime).toISOString().slice(0, -5), end: new Date(a.end.dateTime).toISOString().slice(0, -5), url: a.webLink});
            })
		})
		.catch((e) => {
			console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	computed: {
		getUser() {
			return this.$store.getters['user/getCurrentUser'];
		},		
		getRootTreeCount() {
			return this.$store.getters['azure/getRootTreeCount'];
		},		
	},
	async created() {
		await this.$store.dispatch('notification/getNotificationsCount', { userId: this.getUser.id })
			.then( async(data) => {
				console.log(data)
				this.countNotifications = data.count;
			})
			.catch((e) => {
				console.log(e)
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				// this.isLoading = false;
		});

		let counter = 0;

		await this.$store.dispatch('verification/getVerificationsByUserId', { userId: this.getUser.id })
			.then( async(data) => {
				console.log(data)
				// this.verifications.push(...data.data);

				data.data.forEach((a) => {
					counter++;

					if(counter <= 10 && a.status === 'started') {
						this.verifications.push(a);
					}
				})

				const groups = this.verifications.reduce((groups, game) => {
					const dateN = new Date(game.probablydate);
					const date = new Date(game.probablydate).toLocaleDateString('es-Es');
					if (!groups[date]) {
						groups[date] = [];
						groups[date].dateCom = dateN;
					}
					groups[date].push(game);
					return groups;
				}, {});

				console.log(groups);

				const groupArrays = Object.keys(groups).map((date, dateCom) => {
					return {
						date,
						dateCom,
						verifications: groups[date]
					};
				})

				this.verifications = [...groupArrays];
				// console.log(this.verifications);

				// // this.verifications.sort(function(a,b){
				// // 	return new Date(a.dateCom) - new Date(b.dateCom);
				// // });

				// console.log(this.verifications)

				//click go to url and color
				
				data.data.forEach((a) => {
					// textColor: '#000', backgroundColor: '#22c55e',
					let color = '#22c55e';
					if((new Date(a.probablydate) <= new Date()) && a.status === 'started')  color = '#f97316'
					else if (a.status === 'Closed') color = '#9E9E9E';
					this.calendarOptions.events.push({url: '/projects/' + a.folder.id ,color, allDay: true, title: '(' + (a.folder.deprecatedid) + ') ' + a.title, start: new Date(a.probablydate).toISOString(), end: new Date(a.probablydate).toISOString()});
				})
			})
			.catch((e) => {
				console.log(e)
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				// this.isLoading = false;
		});

		await this.$store.dispatch('project/getLastProjects', { userID: this.getUser.id })
			.then( async(data) => {
				data.forEach(element => {
					this.projectsList.push({ 
						id: element.id,
						name: element.name,
						progress: element.progress,
						status: element.estado,
						projectStatus: element.estadoproyecto,
					})
				});
			})
			.catch(() => {
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				// this.isLoading = false;
		});
	},
	methods: {
		goToProject(id){
			this.$router.push(
				{ path: `/projects/${id}` }
			); 
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>

<style scoped>
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) } 
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
</style>