// import Vue from 'vue';
// import Vuex from 'vuex';

// Vue.use(Vuex);

export function hasAuth(state) {
  console.log(state)
  return state.token !== null;
}

// export default function getId(state) {
//   return state.token;
// }

export function getToken(state) {
  return state.token;
}
