import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import RolFactory from './RolFactory';

const ROLES = '/api/v1/roles';

class RolAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getRolList(){
    const url = `${ROLES}/getRolList`;

    return this.client
      .get(url)
      .then((res) => RolFactory.getRolesList(res.data))
      .catch((error) => Promise.reject(error));
  }

  // getAllGroups() { //currentPage
  //   // const parameterUrl = getParameterUrl(GROUP, currentPage.page,
  //   //   [], [], currentPage.itemsPerPage);

  //   const url = `${ROLES}/getGroupsList`;

  //   return this.client
  //     .get(url)
  //     .then((res) => GroupFactory.getAllGroups(res.data))
  //     .catch((error) => Promise.reject(error));
  // }

  getGroupsListByUserId(data) {

  const url = `${ROLES}/getRolesListByUserId`;

  return this.client
    .post(url, data)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
  }

  createGroupWithUsers(data) {
    // const idData = getDataWithUuid(data);

    const url = `${ROLES}/createRolWithUsers`;

    return this.client
      .post(url, data)
      .then(() => data)
      .catch((error) => Promise.reject(error));
  }

  deleteGroups(data) {
    const url = `${ROLES}/deleteRols`;

    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteUserFromGroups(data) {
    const url = `${ROLES}/deleteUserFromRols`;

    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }
}
export default RolAPI;
