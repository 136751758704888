
<template>
	<div class="grid">
		<!--Listado Perfiles-->
		<div class="col-4">
			<Card>
				<template v-slot:content>
					<p class="permission-list-title">Listado de Perfiles</p>
					<ul class="jove-items-list">
						<li class="border-item-style" v-for="prof in profiles" v-bind:key="prof.id" @click="seeUsersWithProfile(prof)" >
							{{ prof.name }}
						</li>
					</ul>
				</template>
			</Card>
		</div>
		<!--// Listado Perfiles-->
		
		<!--Listado Usuarios-->
		<div v-if="showDataTable" id="usersDataTable" ref="usersDataTable" class="col-8">
			<Card>
				<template #header>
					<div class="header-buttons-container">
						<Button label="Añadir perfil" v-html="this.profileSelectedTxt" icon="pi pi-plus" class="p-button-jove-primary mr-2 bolder" @click="showUserWithoutProfileDialog()" />
					</div>
				</template>
				<template v-slot:content>
					<DataTable :value="usersListWithProfiles" :dataKey="id" paginator="true" :rows="15"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
						<!--Avatar-->
						<Column field="avatar" header="Usuario">
							<template #body="{data}">
								<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
								<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
							</template>
						</Column>
						<!--Nombre-->
						<Column field="name" header="Nombre">
							<template #body="{data}">
								{{data.firstname}}
							</template>
						</Column>
						<!--Email-->
						<Column field="email" header="Email">
							<template #body="{data}">
								{{data.email}}
							</template>
						</Column>
						<!--Eliminar permiso-->
						<Column field="delete" header="Eliminar perfil" class="">
							<template #body="{data}">
								<Button icon="pi pi-trash" class="p-button-rounded p-button-jove-tertiary mt-2" @click="confirmDeleteProfile(data.id)" />
							</template>
						</Column>
						<template #paginatorstart>
							<Button type="button" icon="pi pi-refresh" class="p-button-text" />
						</template>
						<template #paginatorend>
							<Button type="button" icon="pi pi-cloud" class="p-button-text" />
						</template>
					</DataTable>
				</template>
			</Card>
		</div>
		<!--// Listado Usuarios-->
	</div>

	<!--MODAL USUARIOS SIN PERMISO SELECCIONADO-->
	<Dialog v-model:visible="usersWithoutProfileDialog" :style="{width: '750px'}" header="Usuarios" :modal="true" class="p-fluid">
		<div class="header-buttons-container">
			<Button label="Añadir a Perfil" icon="pi pi-plus" class="p-button-jove-primary mr-2 bolder" :disabled="!this.usersIDToAddProfileList || !this.usersIDToAddProfileList.length" @click="addProfileToUsersSelected()" />
		</div>
		<div class="container mg-top-btn">
			<Card>
				<template v-slot:content>
					<DataTable :value="usersListWithoutProfiles" :dataKey="id" responsiveLayout="scroll">
						<Column field="checked" header="Seleccionar">
							<template #body="{data}">
							<input type="checkbox" class ="cb-size2" :value="data.id" :id="data.id" v-on:click="addUserToProfile($event)">
							</template>
						</Column>
						<!--Avatar-->
						<Column field="avatar" header="Usuario">
							<template #body="{data}">
							<Image v-if="data.avatar" src="images/profileTest.jpg" alt="Image Text" imageClass="imageProfile2"/>
							<Image v-else src="images/no-avatar-user.png" alt="Image Text" imageClass="imageProfile2"/>
							</template>
						</Column>
						<!--Nombre-->
						<Column field="name" header="Nombre">
							<template #body="{data}">
							{{data.firstname}}
							</template>
						</Column>
					</DataTable>
				</template>
			</Card>
		</div>
	</Dialog>
</template>


<script>
import {FilterMatchMode} from 'primevue/api';
import CustomerService from "../service/CustomerService";


//Change Product to users
export default {
	data() {
		return {
			profiles: null,
			profileList: null,
			currentProfile: null,
			profileSelectedTxt: null,
			usersList: null,
			usersIDToAddProfileList: [],
			usersListWithProfiles: null,
			usersListWithoutProfiles: null,
			usersSelectedToAddPerm: [],
			showDataTable: null,
			usersWithoutProfileDialog: false,
		}
	},
	customerService: null,
	created() {
		this.customerService = new CustomerService();
		this.initFilters();
	},
	async mounted() {
		//Crear flujo de session con token y refreshtoken
		// Get User/profile List
		Promise.all([
				await this.$store.dispatch('user/getUsersList'), 
				await this.$store.dispatch('profile/getProfileList'),
		])
		.then( async(data) => {
			this.usersList = data[0].usersList;
			this.profiles = data[1].profilesList;
		})
		.catch((e) => {
			console.log(e)
			this.isLoading = false;
		});
	},

	methods: {
		async seeUsersWithProfile(profile){
			const profileID = profile.id;
			this.currentProfile = profile;
			this.profileSelectedTxt = '+ Añadir ' + profile.name;
			this.showDataTable = true;

			// Get userList with that profile and userList without that profile
			await this.$store.dispatch('user/getUsersListByProfile', {profileID}
			).then( async(data) => {
				this.usersListWithProfiles = [];
				const usersListId = [];

				for (const user_has_profile of data.data) {
					this.usersListWithProfiles.push(user_has_profile.users);
					usersListId.push(user_has_profile.users.id);
				}
				//Users Without profile
				await this.$store.dispatch('user/getUsersListWithoutProfile', {profileID, usersListId}
				).then( async(dataPerm2) => {
					this.usersListWithoutProfiles = [...dataPerm2.data];

					// for (const user_has_permission of dataPerm2.data) {
					// 	if (!usersWithoutPerm.includes(user_has_permission)) {
					// 		usersWithoutPerm.push(user_has_permission.users);
					// 	}
					// }

					// Filter users by userId and delete duplicates
					// const result = usersWithoutPerm.filter(user => usersListId.indexOf(user.id) === -1)
					// 	.filter((user, index, self) =>
					// 		index === self.findIndex((t) => 
					// 			(JSON.stringify(t) === JSON.stringify(user)
					// 		)
					// 	)
					// );
					// this.usersListWithoutPermissions = [...usersWithoutPerm];
				})
				.catch((e) => {
					console.log(e)
					this.isLoading = false;
				});
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});
		},
		addUserToProfile (e) {
			if (e.target.checked) {
				this.usersIDToAddProfileList.push(Number(e.target.id));
			}else{
				for( let i=0; i < this.usersIDToAddProfileList.length; i++){     
					if ( this.usersIDToAddProfileList[i] === Number(e.target.id)) { 
						this.usersIDToAddProfileList.splice(i, 1);
						i--; 
					}
				}
			}
		},
		// Add the profile to the users selected
		async addProfileToUsersSelected(){
			let filteredUsers = [];
			this.usersSelectedToAddPerm = [];
			// Filter users by ID
			filteredUsers = this.usersListWithoutProfiles.filter(user => this.usersIDToAddProfileList.includes(user.id))
			// Prepare JSON data to send (profiles_id users_id) / this.currentProfile.id
			filteredUsers.forEach((userF) => {
				this.usersSelectedToAddPerm.push({users_profiles_id: Number(this.currentProfile.id), users_id: Number(userF.id)});
			});
			await this.$store.dispatch('profile/asignProfileToUsers', { userHasProfile: this.usersSelectedToAddPerm })
				.then(() => {
					this.$toast.add({severity:'success', summary: 'Añadidos', detail: 'Insercción realizada con éxito', life: 3000});
					this.usersWithoutProfileDialog = false;
					this.usersListWithProfiles.push(...filteredUsers);
					// if(res){
					// 	this.$toast.add({severity:'success', summary: 'Successful', detail: 'Insercción realizada con éxito', life: 3000});
					// 	this.usersWithoutProfileDialog = false;
					// 	// this.created();
					// 	// this.mounted();
					// }else{
					// 	this.$toast.add({severity:'info', summary: 'Successful', detail: 'La insercción no se pudo realizar', life: 3000});
					// }
				}
			)
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
			this.showDataTable = false;
        },
		notMe(data) {
			let me = this.$store.getters['user/getCurrentUser'] 
			return data.id != me.id;
		},
		showUserWithoutProfileDialog() {
			this.usersWithoutProfileDialog = true;
			this.usersIDToAddProfileList = [];	// Init users with future profiles added
		}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
