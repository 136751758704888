import {
  getGroupsList,
  createGroupWithUsers,
  deleteGroups,
  deleteUserFromGroups,
  getGroupsListByUserId,
} from './groupActions';

const group = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    getGroupsList,
    createGroupWithUsers,
    deleteGroups,
    deleteUserFromGroups,
    getGroupsListByUserId,
  },
  getters: {
  },
};

export default group;
