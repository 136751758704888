import axios from '../domain/services/index';
import StorageManager from './StorageManager';
// import i18n from '@/plugins/vue-i18n';

// axios.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;
//     if (originalConfig.url !== "/login" && err.response) {
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           console.log("perdio sesión");
//           // const rs =
//         //    await axios.post("auth/refresh", {
//         //   //   refreshToken: store.state.refreshToken
//         //   // });
//         //   // const {
//         //   //   token,
//         //   //   refreshToken
//         //   // } = rs.data;
//         //   // store.dispatch('setToken', token)
//         //   // store.dispatch('setRefToken', refreshToken)
//         //   // err.config.headers[
//         //   //   "Authorization"
//         //   // ] = `Bearer ${token}`;
//         //   // return new Promise((resolve, reject) => {
//         //   //   axios.request(originalConfig).then(response => {
//         //   //     resolve(response);
//         //   //   }).catch((err) => {
//         //   //     reject(err);
//         //   //   })
//         //   // });
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }
//     return Promise.reject(err);
//   }
// );

export default function getAxiosWithAuthHeader() {
  const token = StorageManager.getToken();
  //Todo: with data to send
  // axios.defaults.headers.common.locale = i18n.locale;

  if (token !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return axios;
}
