import RolModel from './RolModel';

class RolFactory {
  constructor() {
    this.rol = {};
  }

  getRolesList(data) {
    console.log(data)
    const rolesResponse = {
      rolesList: this.getCollection(data),
      pagination: 1,
    };
    return rolesResponse;
  }

  getCollection(rolesCollection) {
    const rolList = rolesCollection.map(
      (rol) => this.getRol(rol),
    );

    return rolList;
  }

  getRol(data) {
    console.log(data)
    this.rol = new RolModel({
      id: data.id,
      name: data.name,
      users: data.users_has_users_roles,
    });

    return this.rol;
  }
}

export default new RolFactory();