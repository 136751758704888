// import { getAvatarUrl } from '@/helpers/UserHelper';

export default class UserModel {
  constructor(userData) {
    this.id = userData.id;
    this.profilepicture = userData.profilepicture;
    this.name = userData.name;
    this.dni = userData.dni;
    this.lastname = userData.lastname;
    this.email = userData.email;
    this.phone = userData.phone;
    this.empresa = userData.empresa;
    this.users_has_permissions = userData.users_has_permissions;
    this.users_groups_has_users = userData.users_groups_has_users;
    this.users_has_eventos = userData.users_has_eventos;
    this.users_has_posts = userData.users_has_posts;
    this.users_has_users_profiles = userData.users_has_users_profiles;
    this.users_has_users_roles = userData.users_has_users_roles;
    // this.creation_date = userData.creation_date;
  }

  // getAvatarUrl() {
  //   if (this.profilepicture !== null && Object.prototype.hasOwnProperty.call(this.avatar, 'path')) {
  //     return getAvatarUrl(this.profilepicture);
  //   }
  //   return null;
  // }
}
