export function upFiles(state) {
  state.filesCount = state.filesCount + 1;
}

export function addFiles(state, id) {
  state.filesUploaded.push(id);
}

export function saveRootTree(state, rootTree) {
  state.root = rootTree;
  state.rootTreeCount = rootTree.length;
}

export function savePlainRoot(state, rootTree) {
  // console.log(rootTree);
  state.plainRoot = rootTree
}

export function saveNewRootTree(state, rootTree) {
  state.root = [...state.root, rootTree];
}

