/* eslint-disable no-unused-vars */
import GroupAPI from '../../domain/group/GroupApi';

export function getGroupsList({ commit }, data) {
  const groupAPI = new GroupAPI();
  const getAllRequest = groupAPI.getAllGroups(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getGroupsListByUserId({ commit }, data) {
  const groupAPI = new GroupAPI();
  const getAllRequest = groupAPI.getGroupsListByUserId(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createGroupWithUsers({ commit }, data) {
  const groupAPI = new GroupAPI();
  const createRequest = groupAPI.createGroupWithUsers(data);

  return createRequest
    .then()
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteGroups({ commit }, data) {
  console.log('deleteGroups from Actions -> ' + JSON.stringify(data));
  const groupAPI = new GroupAPI();
  const deleteRequest = groupAPI.deleteGroups(data);

  return deleteRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteUserFromGroups({ commit }, data) {
  const groupAPI = new GroupAPI();
  const deleteRequest = groupAPI.deleteUserFromGroups(data);

  return deleteRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}



