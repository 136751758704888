<template>
<!-- <div class="flex justify-content-center flex-wrap card-container mt-8">
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
          <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
          <div class="text-900 text-3xl font-medium mb-3">Bienvenidos</div>
      </div>

      <div>
          <label for="email" class="block text-900 font-medium mb-2">Email</label>
          <InputText id="email" type="text" :class="`w-full mb-3 ${v$.email.$invalid && submitted ? 'p-invalid' : ''}`" aria-describedby="email-error" @input="modelEmail"/>

          <label for="password" class="block text-900 font-medium mb-2">Contraseña</label>
          <InputText id="password" type="password" class="w-full mb-3" />

          <div class="flex align-items-center justify-content-end mb-6">
              <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">¿Has olvidado la contraseña?</a>
          </div>

          <Button label="Entrar" icon="pi pi-user" class="w-full" @click="login"></Button>
      </div>
  </div>
</div>
@input="modelPassword" -->

  <div class="flex justify-content-center flex-wrap card-container mt-8">
      <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
           <div class="text-center mb-5">
              <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
              <div class="text-900 text-3xl font-medium mb-3">Bienvenidos</div>
          </div>
          <form @submit.prevent="login(!v$.$invalid)" class="p-fluid">
              <div class="field mb-4">
                  <div class="p-float-label p-input-icon-right">
                      <i class="pi pi-envelope" />
                      <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                      <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email</label>
                  </div>
                  <!-- <span v-if="v$.email.$error && submitted">
                      <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                      <small class="p-error">El formato del email no es correcto</small>
                      </span>
                  </span> -->
                  <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">El email es requerido</small>
              </div>
              <div class="field mb-3">
                  <div class="p-float-label">
                      <Password id="password" :feedback="false" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask/>
                      <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Contraseña</label>
                  </div>
                  <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">La contraseña es requerida</small>
              </div>
              <!-- <div class="field-checkbox">
                  <Checkbox id="accept" name="accept" value="Accept" v-model="v$.accept.$model" :class="{'p-invalid':v$.accept.$invalid && submitted}" />
                  <label for="accept" :class="{'p-error': v$.accept.$invalid && submitted}">I agree to the terms and conditions*</label>
              </div> -->
              <Button type="submit" label="Entrar" class="mt-2" :loading="isLoading"/>
              <small v-if="credentialsError" class="p-error">El email o la contraseña no son correctos</small>
          </form>
          <div class="mt-4 flex justify-content-center">
            <a href="/forgotPassword">¿Olvidaste tu contraseña?</a>
          </div>
      </div>
  </div>
</template>

<script>

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "LoginView",
  components: {
    InputText,
    Button
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
      return {
          email: '',
          password: '',
          accept: null,
          submitted: false,
          isLoading: false,
          credentialsError: false,
      }
  },
  validations() {
      return {
          email: {
              required,
              email
          },
          password: {
              required
          },
      }
  },
  methods: {
    async login(isFormValid) {

      this.submitted = true;
      this.credentialsError = false;

      console.log(isFormValid)

      if (!isFormValid) {
        return;
      }

      this.isLoading = true;


      
      await this.$store.dispatch('auth/login', {password: this.password, email: this.email})
      .then( async(data) => {

        console.log("data:", data);
        
        this.isLoading = false;
        
        // check if password is expired then send to ResetPassword
        if(data?.changePassword) {
          this.$router.push({ name: 'resetPassword',  params: { email: this.email } });
        } else {
          // get info has to go in the url
          await this.$store.dispatch('user/getUser');
          this.$router.push({ name: 'Dashboard' });
        }

      })
      .catch((e) => {
        this.isLoading = false;

        if(e?.response?.data?.message === "Invalid login credentials.") {
          this.credentialsError = true;
        } else {
          console.log(e)
        }
        
        // this.resetState();
      });

      

    }
  }
};
</script>