import PermissionModel from './PermissionModel';

class PermissionFactory {
  constructor() {
    this.permission = {};
  }

  getPermissionsList(data) {
    //console.log(data)
    const permissionsResponse = {
      permissionsList: this.getCollection(data),
      pagination: 1,
    };

    return permissionsResponse;
  }

  getCollection(permisionsCollection) {
    const permissionList = permisionsCollection.map(
      (permission) => this.getPermission(permission),
    );

    return permissionList;
  }

  getPermission(data) {
    //console.log(data)
    this.permission = new PermissionModel({
      id: data.id,
      name: data.name
    });

    return this.permission;
  }
}

export default new PermissionFactory();