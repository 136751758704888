import AuthModel from './AuthModel';

class AuthFactory {
  constructor() {
    this.auth = {};
  }

  getAuth(authData) {
    console.log(authData)
    this.auth = {
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      changePassword: authData.changePassword,
      message: authData.message,
    };

    console.log(this.auth)

    return new AuthModel(this.auth);
  }
}

export default new AuthFactory();
