import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';
import 'floating-vue/dist/style.css'

import { createApp, reactive } from 'vue';
import router from './router';
import store from './store';
// import 'office-js';
import Vuex from 'vuex';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import DialogService from 'primevue/dialogservice';
import TabView from 'primevue/tabview';
import DynamicDialog from 'primevue/dynamicdialog';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import { createI18n } from 'vue-i18n'
import ganttastic from '@infectoone/vue-ganttastic'
import { Mentionable } from 'vue-mention'
import ProgressSpinner from 'primevue/progressspinner';
import FloatingVue from 'floating-vue'
import es from 'dayjs/locale/es'
import dayjs from 'dayjs';
import Vue3Lottie from 'vue3-lottie'
// import Dayjs from 'vue-dayjs';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCamera, faFileAudio, faFileImage, faFileVideo, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileCode, faFileText, faFile, faFileArchive, faCircleQuestion, faCircleExclamation, faEnvelope, faBug } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faFileAudio, faCamera, faFileImage, faFileVideo, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileCode, faFileText, faFile, faFileArchive, faCircleQuestion, faCircleExclamation, faEnvelope, faBug);

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

const i18n = createI18n({
    locale: 'es'
})

const app = createApp(AppWrapper);

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined', locale: {
    startsWith: 'Empieza por',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Termina por',
    equals: 'Igual a',
    notEquals: 'No igual a',
    noFilter: 'Sin filtro',
    lt: 'Menos que',
    lte: 'Menos o igual que',
    gt: 'Más que',
    gte: 'Más o igual que',
    dateIs: 'Fecha es',
    dateIsNot: 'Fecha no es',
    dateBefore: 'Fechas es antes de',
    dateAfter: 'Fecha es después de',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Igual en todo',
    matchAny: 'Igual en algo',
    addRule: 'Añadir regla',
    removeRule: 'Quitar regla',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    completed: 'Completed',
    pending: 'Pending',
    dayNames: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    dayNamesShort: ['Lun', 'Ma', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    dayNamesMin: ['L', 'Ma', 'M', 'J', 'V', 'S', 'D'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    chooseYear: 'Elige Años',
    chooseMonth: 'Elige Mes',
    chooseDate: 'Elige Fecha',
    prevDecade: 'Previous Decade',
    nextDecade: 'Next Decade',
    prevYear: 'Anterior Año',
    nextYear: 'Siguiente Año',
    prevMonth: 'Anterior Mes',
    nextMonth: 'Siguiente Mes',
    prevHour: 'Anterior Hora',
    nextHour: 'Siguiente Hora',
    prevMinute: 'Anterior Minuto',
    nextMinute: 'Siguiente Minuto',
    prevSecond: 'Anterior Segundo',
    nextSecond: 'Siguiente Segundo',
    am: 'am',
    pm: 'pm',
    today: 'Hoy',
    weekHeader: 'Semana',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    searchMessage: '{0} results are available',
    selectionMessage: '{0} items selected',
    emptySelectionMessage: 'No selected item',
    emptySearchMessage: 'No results found',
    emptyMessage: 'No available options',
    aria: {
        trueLabel: 'True',
        falseLabel: 'False',
        nullLabel: 'Not Selected',
        star: '1 star',
        stars: '{star} stars',
        selectAll: 'All items selected',
        unselectAll: 'All items unselected',
        close: 'Close',
        previous: 'Previous',
        next: 'Next',
        navigation: 'Navigation'
    }
} });

// app.use(Dayjs, {
//     lang: 'es',
// });

app.use(Vue3Lottie);

app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(i18n)
app.use(store);
app.use(DialogService);
app.use(ganttastic);

dayjs.locale(es);

app.use(Vuex);
app.use(FloatingVue)

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);
// app.directive('tooltip', VTooltip)
// app.directive('close-popper', VClosePopper)

// app.component('VDropdown', DropdownFloating)
// app.component('VTooltip', TooltipFloating)
// app.component('VMenu', MenuFloating)
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Mentionable', Mentionable);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('DynamicDialog', DynamicDialog);
app.component('Checkbox', Checkbox);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);


app.component('BlockViewer', BlockViewer);

app.mount('#app');