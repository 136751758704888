import { setUser, setUserAuth } from './userMutations';
import {
  getCurrentUser,
  isAdmin,
  getId
} from './userGetters';
import { resetPassword, getUser, updateUser, getUsersList, createUser, deleteUser, getUsersListByPermission, getUsersListWithoutPermission, getUsersListWithoutProfile, getUsersListByProfile } from './userActions';

const user = {
  namespaced: true,
  state: () => ({
    name: null,
    profilepicture: null,
    dni: null,
    lastname: null,
    email: null,
    users_has_permissions: null,
    users_groups_has_users: null,
    users_has_eventos: null,
    users_has_posts: null,
    users_has_users_profiles: null,
    users_has_users_roles: null,
    creation_date: null,
    id: null,
  }),
  mutations: {
    setUser,
    setUserAuth,
  },
  actions: {
    resetPassword,
    getUser,
    updateUser,
    getUsersList,
    createUser,
    deleteUser,
    getUsersListByPermission,
    getUsersListWithoutPermission,
    getUsersListWithoutProfile,
    getUsersListByProfile,
  },
  getters: {
    getCurrentUser,
    isAdmin,
    getId
  },
};

export default user;
