/* eslint-disable no-unused-vars */
import ProfileAPI from '../../domain/profile/ProfileApi';

// Return all the permissions
export function getProfileList({ commit }) {
  const profileApi = new ProfileAPI();
  const profileList = profileApi.getProfileList();

  return profileList
    .then((res) => res)
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function asignProfileToUsers({ commit }, data) {
  const  profileApi = new ProfileAPI();
  const createRequest = profileApi.asignProfileToUsers(data);

  return createRequest
    .then( (res) => { 
      return res
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}