<template>
	<!-- just if files tab is activated -->
	<!-- <div style="visibility:hidden; opacity:0" class="dropzone">
		<div id="textnode"><p style="font-size:32px; color:black;" class="font-bold">Subir archivos</p></div>
	</div> -->
	<div class="grid">
		<!--No proyects selected-->
		<div  class="col-12">
			<div class="card">
				<div class="flex justify-content-between align-items-center mb-5">
					<h5>Tus Proyectos</h5>
				</div>
				<!-- Create an overview page -->
					<ul class="list-none p-0 m-0">
						<li v-for="(element, index) in projectsList" :key="index" class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
							<div>
								<span @click="goToProject(element.id)" class="text-900 font-medium mr-2 mb-1 md:mb-0">{{element.name}}</span>
								<div @click="goToProject(element.id)" class="mt-1 text-600">{{ element.status && element.status != '' ? this.MainstatusOptions.find(x => x.code === element.status).name : element.projectStatus && element.projectStatus != '' ? this.statusOptions.find(x => x.code === element.projectStatus).name : element.id}}</div>
							</div>
							<div class="mt-2 md:mt-0 flex align-items-center">
								<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
									<div :class="`bg-${this.colorRange.find(x => x.start <= (element.progress ?? 0) && x.end >= (element.progress ?? 0) ).color} h-full`" :style="`width:${element.progress ?? 0}%`"></div>
								</div>
								<span :class="`text-${this.colorRange.find(x => x.start <= (element.progress ?? 0) && x.end >= (element.progress ?? 0) ).color} ml-3 font-medium`">{{element.progress ?? 0}}%</span>
							</div>
						</li>
					</ul>
			</div>
		</div>
		</div>
		<!--// No proyects selected-->
</template>

<script>

export default {
	components: {
	},
	data() {
		return {
			statusOptions: [
				{name: 'Abierto', code: 'Open'},
				{name: 'En proceso', code: 'In_process'},
				{name: 'Cerrado', code: 'Closed'},
				{name: 'Bloqueado', code: 'Blocked'},
			],
			colorRange: [
				{
					start: 0,
					end: 20,
					color: 'cyan-500'
				},
				{
					start: 21,
					end: 40,
					color: 'green-500'
				},
				{
					start: 41,
					end: 60,
					color: 'orange-500'
				},
				{
					start: 61,
					end: 80,
					color: 'pink-500'
				},
				{
					start: 81,
					end: 100,
					color: 'purple-500'
				},
			],
			lineOptions: null,
			projectsList: [],
			MainstatusOptions: [
				{name: 'No planeado', code: 'unplanned'},
				{name: 'No empezado', code: 'not_started'},
				{name: 'No empezado a tiempo', code: 'not_started_on_time'},
				{name: 'Empezado', code: 'started'},
				{name: 'Empezado fuera de tiempo', code: 'started_out_of_time'},
				{name: 'Empezado a tiempo', code: 'not_closed_on_time'},
				{name: 'Cerrado', code: 'closed'},
				{name: 'Cerrado fuera de tiempo', code: 'closed_out_of_time'},
				{name: 'Crítico', code: 'critical'},
			],
		}
	},
	computed: {
		getUser() {
			return this.$store.getters['user/getCurrentUser'];
		},	
	},
	async created() {

		await this.$store.dispatch('project/getAllProjects', { userID: this.getUser.id })
			.then( async(data) => {
				console.log(data)
				data.forEach(element => {
					this.projectsList.push({ 
						id: element.id,
						name: element.name,
						progress: element.progress,
						status: element.estado,
						projectStatus: element.estadoproyecto,
					})
				});

				console.log(this.projectsList);
			})
			.catch((e) => {
				console.log(e)
				// this.isLoading = false;
		});
	},
	methods: {
		goToProject(id){
			this.$router.push(
				{ path: `/projects/${id}` }
			); 
		},	
	}
}
</script>

<style>
.dropzone
{
    /* positions to point 0,0 - required for z-index */
    position: fixed; top: 0; left: 0; 
    /* above all elements, even if z-index is used elsewhere
       it can be lowered as needed, but this value surpasses
       all elements when used on YouTube for example. */
    z-index: 9999999999;               
    /* takes up 100% of page */
    width: 100%; height: 100%;         
    /* dim the page with 50% black background when visible */
    background-color: rgba(0,0,0,0.5);
    /* a nice fade effect, visibility toggles after 175ms, opacity will animate for 175ms. note display:none cannot be animated.  */
    transition: visibility 175ms, opacity 175ms;
	display: table;
}

#textnode {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    transition: font-size 175ms;
}

.p-chip.high-chip {
    background: rgb(226, 43, 43);
    color: #fff;
}

@keyframes strike{
  0%   { width : 0; }
  100% { width: 100%; }
}

.strike {
  position: relative;
}

.strike::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: black;
  animation-name: strike;
  animation-duration: .25s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}

.doneHeader {
 background-color: #22C55E;
}

.success-chip{
	background: #22C55E;
    color: #fff;
}
</style>