<template>
	<!-- just if files tab is activated -->
	<!-- <div style="visibility:hidden; opacity:0" class="dropzone">
		<div id="textnode"><p style="font-size:32px; color:black;" class="font-bold">Subir archivos</p></div>
	</div> -->
	<div class="isLoadingDiv" v-if="isLoadingDiv">
		<div class="spinnerDiv" id="textnode">
			<ProgressSpinner/>
		</div>
	</div>
	<ConfirmDialog></ConfirmDialog>

	<div class="grid">

		<!--One Proyect Selected-->
		<div v-if="this.nodeProyects.length >= 0" class="col-12">
			<div class="card">
				<div class="flex flex-column justify-content-center">
					<div class="flex xl:flex-row flex-column justify-content-between">
						<div class="flex flex-column justify-content-start">
							<Breadcrumb :home="home" :model="items" v-if="projectObject.type !== 'PROJECT'" />
							<br>
							<div class="font-medium text-3xl text-900 mb-3" @click="clipboardCopy(projectObject.id)">{{projectObject.deprecatedid ? `(${projectObject.deprecatedid}) - `: '' }}{{projectObject.name}}</div>
							<div class="text-500 mb-5">Información del proyecto {{$route.params.id}} <b> {{ projectObject?.assignedto?.id ? ' - Asignado a: ' + projectObject?.assignedto?.name : '' }}</b></div>
						</div>
						<div class="flex justify-content-end xl:justify-content-start align-items-stretch align-self-center align-items-center md:align-items-normal justify-content-center">
							<Button v-if="isOwner" label="Desactivar" icon="pi pi-trash" iconPos="left" class="p-button-sm p-button-danger create-project mr-3" @click="removeProject" />
							<Button v-if="(projectObject.estadoproyecto !== 'Closed' && projectObject.type === 'PROJECT') || (projectObject.estado !== 'closed' && projectObject.estado !== 'closed_out_of_time' && projectObject.type !== 'PROJECT')" label="Imputar horas" icon="pi pi-clock" iconPos="left" class="p-button-sm create-project mr-3" @click="openWorkingTimes" />
							<Button v-if="(isOwner || isEditor) && projectObject.type === 'PROJECT'" label="Guardar como plantilla" icon="pi pi-copy" iconPos="left" class="p-button-sm create-project mr-3" @click="openCreateTemplate" />
							<Button label="Nuevo" icon="pi pi-plus" iconPos="left" class="p-button-sm create-project" v-if="(isOwner || isEditor) && (projectObject.type && projectObject.type !== 'RECORD')" @click="openNewFolder" />
						</div>
					</div>
					<div class="py-2">
						<!-- <Button @click="active = 0" class="p-button-text" label="Trabajo 1" />
						<br>
						<br>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0 ml-2">Descripción</span>
						<br>
						<Textarea v-model="value" rows="5" cols="30" class="ml-2"/> -->
						<TabView v-model:activeIndex="activeTab" lazy scrollable @tab-click="tabChange">
							<TabPanel>
								<template #header>
									<i class="pi pi-check"></i>
									<span class="ml-2"> Verificación</span>
								</template>
								<VerificationView :projectId="projectObject.id" :isOwner="isOwner" :isEditor="isEditor" :isReader="isReader"/>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-inbox"></i>
									<span class="ml-2"> Comunicaciones</span>
								</template>
								<div class="flex justify-content-end">
									<Button label="Refrescar comunicaciones" icon="pi pi-refresh" iconPos="left" class="p-button-sm create-project mr-3" @click="loadComunications" />
								</div>
								<h2 v-if="messages.length == 0">No tienes comunicaciones</h2>
								<div class="flex flex-column" v-for="(item, index) in messages" :key="index">
									<div class=" flex flex-grow-1 flex-column card mb-0 mt-4">
										<div class="flex-1 flex align-items-center justify-content-left">
											<Avatar icon="pi pi-user" class="mr-2" style="background-color: #002680; color: #ffffff" />
											<div class="flex flex-1 align-items-center justify-content-left w-4rem h-4rem font-bold text-black border-round m-2">
												{{item.who}}
												<div class="text-500 ml-8">{{formatDateComunication(new Date(item.createddate))}}</div>
											</div>
											<Button v-if="isOwner || isEditor" label="Responder" icon="pi pi-reply" iconPos="left" @click="replyTo(item.who, item.id)" style="background-color:transparent; border:none; color: black;"/>
										</div>
										<p>{{item.post}}</p>
									</div>
									<div v-for="(itemReplies, index) in item.replies" :key="index">
										<!-- <Divider layout="vertical" /> -->
										<div class=" flex flex-grow-1 flex-column card ml-5 mb-0 mt-2">
											<div class="flex-1 flex align-items-center justify-content-left">
												<Avatar icon="pi pi-user" class="mr-2" style="background-color: #002680; color: #ffffff" />
												<div class="flex flex-1 align-items-center justify-content-left w-4rem h-4rem font-bold text-black border-round m-2">
													{{itemReplies.who}}
													<div class="text-500 ml-8">{{formatDateComunication(new Date(itemReplies.createddate))}}</div>
												</div>
													<!-- <Button label="Responder" icon="pi pi-reply" iconPos="left" style="background-color:transparent; border:none; color: black;"/> -->
											</div>
											<p>{{itemReplies.post}}</p>
										</div>
									</div>
								</div>
								<Chip v-if="replyWho" :label="`Responder a ${replyWho}`" @remove="cleanReplyTo" removable class="mt-4" />
								<div class="flex mt-5" v-if="isOwner || isEditor">
									<div class="flex flex-grow-1">
										<Mentionable
											:keys="['@', '#']"
											:items="items2"
											offset="6"
											insert-space
											@open="onOpen"
											@apply="handleApply($event)"
											class="w-full height-0"
										>
											<Textarea id="messageInput" ref="typeBox" v-model="messageText" :autoResize="true" class="w-full border-noround-right" rows="1" cols="30" @keypress.enter="sendMessage" />

											<template #no-result>
											<div class="dim">
												Sin resultados
											</div>
											</template>

											<template #item-@="{ item }">
											<div class="user">
												{{ item.value }}
												<span class="dim">
												({{ item.firstName }})
												</span>
											</div>
											</template>

											<template #item-#="{ item }">
											<div class="issue">
												<span class="number">
												#{{ item.value }}
												</span>
												<span class="dim">
												{{ item.label }}
												</span>
											</div>
											</template>
										</Mentionable>
									</div>
									<div class="flex flex-none">
										<Button label="Enviar comentario" class="border-noround-left" @click="sendMessage"/>
									</div>
								</div>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-file"></i>
									<span class="ml-2"> Ficheros</span>
								</template>

								<!-- TODO -->
								<!-- just if files tab is activated  no esta terminado -->
								<div id="dropzone" @dragenter.prevent @dragover.prevent @drop.prevent="(file) => onUploadDrop(file, false)" style="visibility:hidden; opacity:0" class="dropzone">
									<div id="textnode"><p style="font-size:32px; color:black;" class="font-bold">Subir archivos</p></div>
								</div>
								<div class="flex justify-content-end mb-2">
									<!-- v-if="isOwner || isEditor" -->
									<!-- accept="image/*" :maxFileSize="1000000" -->
									<FileUpload class="mr-3" v-if="isOwner || isEditor" uploadIcon="pi pi-save" ref="download" mode="basic" :multiple="false" :customUpload="true" :auto="true" chooseLabel="Reemplazar"   @uploader="(file) => onUpload(file, true)" />
									<FileUpload class="mr-3" mode="basic" :multiple="false" :customUpload="true" :auto="true" name="fileTemplate" chooseLabel="Subir archivos plantilla"  @uploader="onUploadTemplate" />
									<!-- <FileUpload class="mr-3" mode="basic" :multiple="false" :customUpload="true" :auto="true" name="fileTemplate" chooseLabel="Usar archivos plantilla"  @uploader="useTemplate" />  -->
									<Button label="Usar archivos plantilla" icon="pi pi-upload" iconPos="left" class="p-button-sm create-project mr-3 fakeUploadButton" @click="useFileTemplate" />

									<Button :disabled="selectedRows.length === 0" label="Enviar email" icon="pi pi-send" iconPos="left" class="p-button-sm create-project mr-3 fakeUploadButton" @click="sendEmail()" />
									<FileUpload mode="basic" :multiple="false" :customUpload="true" :auto="true" chooseLabel="Subir archivos" name="file" :disabled="blockUploadButton"  @uploader="(file) => onUpload(file, false)" />
								</div>
								<ContextMenu v-if="isOwner || isEditor" ref="cm" :model="menuModel" />
								<DataTable :rowClass="rowClass" contextMenu v-model:selection="selectedRows"  v-model:contextMenuSelection="selectedProduct" @rowContextmenu="onRowContextMenu" :filters="filesSearch" ref="dt" :value="files" dataKey="id" :paginator="true" :rows="10"
										paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
										currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} archivos" responsiveLayout="scroll">
								<template #header>
									<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
										<h5 class="m-0">Lista Archivos</h5>
										<span class="p-input-icon-left">
											<i class="pi pi-search" />
											<InputText class="w-full" v-model="filesSearch['global'].value" placeholder="Buscar archivo" />
										</span>
									</div>
								</template>
								<!-- <Column headerStyle="min-width:12rem;min-heigth:20rem;" v-if="isOwner || isEditor">
									<template #body="data">

									</template>
									Reemplazar
								</Column> -->
								<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
								<Column field="type" header="Tipo" :sortable="true" headerStyle="min-width:12rem;min-heigth:20rem;">
									<template #body="data">
										<span class="p-column-title">Tipo</span>
										<font-awesome-icon :icon="typeIcon(data.data.type)" size="2x" />
									</template>
								</Column>
								<Column field="name" header="Nombre" :sortable="true" headerStyle="width:55%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Nombre</span>
										{{data.data.name}} <i v-if="this.protectedFiles.find((e) => e.files_id === data.data.id)" class="ml-2 font-bold opacity-40 pi pi-fw pi-shield"></i>
									</template>
								</Column>
								<!-- <Column field="name" header="Subcapitulos" :sortable="true" headerStyle="width:30%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Subcapitulos</span>
										{{data.data.name}}
									</template>
								</Column>
								<Column field="name" header="Verificación" :sortable="true" headerStyle="width:30%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Verificación</span>
										{{data.data.name}}
									</template>
								</Column> -->
								<Column field="date" header="Fecha creación" :sortable="true" headerStyle="width:30%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Fecha creación</span>
										{{formatDate(new Date(data.data.creationDate))}}
									</template>
								</Column>
								<!-- <Column field="type" header="Tipo" :sortable="true" headerStyle="width:30%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Tipo</span>
										<font-awesome-icon :icon="typeIcon(data.data.type)" size="2x" />
									</template>
								</Column> -->
								<Column field="size" header="Tamaño" headerStyle="width:30%; min-width:10rem;">
									<template #body="data">
										<span class="p-column-title">Tamaño</span>
										<span class="customer-badge status-new">{{formatBytes(data.data.size)}}</span>
									</template>
								</Column>
								<Column headerStyle="min-width:10rem;min-heigth:20rem;">
									<template #body="data">
										<!-- <Button type="button" label="Menu" @click="toggle" />
										<Menu ref="menu" :model="data" :popup="true">
											<template>
												<a @click="openUrl(data.url, true)" >Ver en la nube</a>
												<a @click="openUrl(data.downloadUrl)">Descargar</a>
												<a :href="item.url" @click="onUpload(file, true)" >Reemplazar</a>
												<a @click="SaveAsImportant(data.url, true)" >Entregable</a>
												<a @click="linkFileToVerification(data.id, true)" >Unir a verificación</a>
												<a @click="openUrl(data.downloadUrl, false, true)" >Enviar en email</a>
											</template>
										</Menu> -->
										<Button :disabled="blockButton" v-if="canEditFile(data.data.id)" icon="pi pi-eye" class="p-button-rounded p-button-jove-primary mr-2 md:mb-0 mb-2" type="submit" @click="getSharedLink(data.data.url)" />
										<!-- <Button icon="pi pi-eye" class="p-button-rounded p-button-jove-primary mr-2 md:mb-0 mb-2" type="submit" @click="testAttachment(data.data.url)" /> -->
										<!-- {{ data.data.url }}  {{ data.data.downloadUrl }}-->
										<Button icon="pi pi-cloud-download" class="p-button-rounded p-button-jove-primary mr-2 md:mb-0 mb-2" type="submit" @click="openUrl(data.data.downloadUrl)"/>
										<!-- <Button v-if="isOwner || isEditor" icon="pi pi-star" class="p-button-rounded p-button-jove-primary mr-2" type="submit" @click="SaveAsImportant(data.data.id)"/> -->
										<!-- <Button v-if="isOwner || isEditor" icon="pi pi-check" class="p-button-rounded p-button-jove-primary mr-2" type="submit" @click="linkFileToVerification(data.data.id, data.data.name)"/> -->
										<!-- <Button v-if="isOwner" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-jove-primary mr-2" type="submit" @click="removeFile(data.data.id)"/> -->
									</template>
								</Column>
							</DataTable>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-calendar"></i>
									<span class="ml-2"> Eventos</span>
								</template>
								<CalendarView :noBorder="true" :projectID="projectObject.id" :projectName="projectObject.name"/>
							</TabPanel>
							<TabPanel v-if="isOwner">
								<template #header>
									<i class="pi pi-chart-line"></i>
									<span class="ml-2"> Prinex</span>
								</template>
							</TabPanel>
							<TabPanel v-if="projectObject.type !== 'RECORD'">
								<template #header>
									<i class="pi pi-chart-bar"></i>
									<span class="ml-2"> Gantt</span>
								</template>
									<GanttComponent :idparent="projectObject.id" :isOwner="isOwner" :isEditor="isEditor" :isReader="isReader"/>
							</TabPanel>
							<TabPanel v-if="projectObject.type !== 'RECORD'">
								<template #header>
									<i class="pi pi-clone"></i>
									<span class="ml-2"> Kanban</span>
								</template>
								<KanbanComponent :idparent="projectObject.id" :isOwner="isOwner" :isEditor="isEditor" :isReader="isReader"/>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-fw pi-info-circle "></i>
									<span class="ml-2"> Información</span>
								</template>
								<div class="surface-section">
									<ul class="list-none p-0 m-0 mt-5">
										<li class="flex align-items-center mb py-3 px-2 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Progreso</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
												<Knob :modelValue="projectObject?.progress ?? 0" readonly="true"/>
											</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openProgressDialog"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Identificador interno</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ projectObject.deprecatedid ?? ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" disabled icon="pi pi-pencil" class="p-button-text"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Tipo</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
												<Chip :label="`${projectObject.private ? 'Privado' : 'Público'}`"></Chip>
											</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isAdmin">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="changeProtection"></Button>
											</div>
										</li>
										<li class="flex align-items-center mb py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Nombre</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject.name}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openEditName"></Button>
											</div>
										</li>
										<li class="flex align-items-center mb py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Identificador Prinex</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject.prinexId}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openEditPrinexId"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Asignado a</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject?.assignedto?.name ?? ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openMainDialog"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Creado por</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ projectObject.createdby.name ?? ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" disabled icon="pi pi-pencil" class="p-button-text"></Button>
											</div>
										</li>
										<li v-if="projectObject.closedby?.name" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Cerrado por</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ projectObject.closedby.name ?? ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" disabled icon="pi pi-pencil" class="p-button-text"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Fecha creación</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ projectObject?.creationdate ? new Date(projectObject?.creationdate).toLocaleDateString("es-ES") : '' }}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" disabled icon="pi pi-pencil" class="p-button-text"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Prioridad</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
												<Chip :label="priorityOptions.find(x => x.code === projectObject?.priority)?.name ?? ''" :class='`mr-2 ${ priorityColor.find(x => x.code === projectObject?.priority)?.color }`'></Chip>
											</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openPriorityDialog"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Previsión de horas</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{minutesToString(projectObject.minutestodone)}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openHoursToDone"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Fecha Inicio y Finalización Previsión</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject?.probablyenddate && projectObject?.probablystartdate ? new Date(projectObject?.probablystartdate).toLocaleDateString("es-ES") + ' - ' + new Date(projectObject?.probablyenddate).toLocaleDateString("es-ES") : ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openDateDialog"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Fecha Inicio y Finalización Real</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject?.realenddate && projectObject?.realstartdate ? new Date(projectObject?.realstartdate).toLocaleDateString("es-ES") + ' - ' + new Date(projectObject?.realenddate).toLocaleDateString("es-ES") : ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openRealDateDialog"></Button>
											</div>
										</li>
										<li v-if="projectObject.type === 'PROJECT'" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Estado proyecto</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
												<Chip :label="statusOptions.find(x => x.code === projectObject?.estadoproyecto)?.name ?? ''" :class='`mr-2 ${ statusColor.find(x => x.code === projectObject?.estadoproyecto)?.color }`'></Chip>
											</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openStatusDialog"></Button>
											</div>
										</li>
										<li v-if="projectObject.type !== 'PROJECT'" class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Estado</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-if="isOwner || isEditor">
												<Chip :label="MainstatusOptions.find(x => x.code === projectObject?.estado)?.name ?? ''" class="mr-2"></Chip>
											</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openMainStatusDialog"></Button>
											</div>
										</li>
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Descripción</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{projectObject?.description ?? ''}}</div>
											<div class="w-6 md:w-2 flex justify-content-end" v-if="isOwner || isEditor">
												<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="openDescriptionDialog"></Button>
											</div>
										</li>

									</ul>
								</div>
							</TabPanel>
							<TabPanel v-if="isOwner">
								<template #header>
									<i class="pi pi-user"></i>
									<span class="ml-2"> Accesos</span>
								</template>
								<AccessProjectView :parentsId="parentsId" :projectId="projectId" :type="projectObject.type"/>
							</TabPanel>
							<TabPanel v-if="isOwner">
								<template #header>
									<i class="pi pi-calendar-times"></i>
									<span class="ml-2"> Tiempos</span>
								</template>
								<WorkingTimeView :projectId="projectObject.id" :usersList="usersList" :newTime="newTime" :type="projectObject.type"/>
							</TabPanel>
						</TabView>
					</div>
				</div>
			</div>
		</div>

		<!-- Menu Folder Start -->
		<Dialog v-model:visible="projectFolderDialog" :style="{width: '450px'}" :header="`Crear nuevo ${typeFolder(projectObject.type)}`" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Nombre</label>
				<InputText v-model="folderName" id="name" required="true" autofocus :class="{'p-invalid': submitted}" />
				<div class="mt-6"></div>
				<Checkbox id="privateCheckbox" v-model="checkedPrivate" :binary="true" />
				<label for="privateCheckbox" class="ml-2"> Privado </label>
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideFolderDialog"/>
				<Button label="Crear" icon="pi pi-check" class="p-button-text" @click="saveFolderProject" />
			</template>
		</Dialog>
		<!-- Menu Folder End -->

		<!-- Menu Folder Start -->
		<Dialog v-model:visible="createTemplateDialog" :style="{width: '450px'}" :header="`Crear nueva plantilla `" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Nombre</label>
				<InputText v-model="templateName" id="templateName" required="true" autofocus :class="{'p-invalid': submitted}" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideCreateTemplate"/>
				<Button label="Crear" icon="pi pi-check" class="p-button-text" @click="saveCreateTemplate" />
			</template>
		</Dialog>
		<!-- Menu Folder End -->

		<!-- Menu Protect To Start -->
		<Dialog v-model:visible="protectToDialog" :style="{width: '450px'}" header="Proteger para" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Asignado a</label>
				<Dropdown v-model="userSelected" id="assinegTo" :options="usersList" optionLabel="name" required="true" autofocus :class="{'p-invalid': submitted}" :filter="true" placeholder="Selecciona usuario" :showClear="true">
					<template #value="slotProps">
							<div v-if="slotProps.value" class="country-item country-item-value grid">

								<div class="field col-12 md:col-12 mb-0">
									<div>{{ slotProps.value.name }}</div>
								</div>
							</div>
						<span v-else>
							{{ slotProps.placeholder }}
						</span>
					</template>
					<template #option="slotProps">
						<div class="country-item grid">
							<div class="field col-12 md:col-12">
								<div>{{ slotProps.option.name }}</div>
							</div>
						</div>
					</template>
				</Dropdown>
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideProtectFileDialog"/>
				<Button :disabled="!userSelected" label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProtectFileProject" />
			</template>
		</Dialog>
		<!-- Menu Protect To End -->

		<!-- Menu AssignedTo Start -->
		<Dialog v-model:visible="projectMainDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Asignado a</label>
				<Dropdown v-model="userSelected" id="assinegTo" :options="usersList" optionLabel="name" required="true" autofocus :class="{'p-invalid': submitted}" :filter="true" placeholder="Selecciona usuario" :showClear="true">
					<template #value="slotProps">
							<div v-if="slotProps.value" class="country-item country-item-value grid">

								<div class="field col-12 md:col-12 mb-0">
									<div>{{ slotProps.value.name }}</div>
								</div>
							</div>
						<span v-else>
							{{ slotProps.placeholder }}
						</span>
					</template>
					<template #option="slotProps">
						<div class="country-item grid">
							<div class="field col-12 md:col-12">
								<div>{{ slotProps.option.name }}</div>
							</div>
						</div>
					</template>
				</Dropdown>
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideMainDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveMainProject" />
			</template>
		</Dialog>
		<!-- Menu AssignedTo End -->

		<!-- Menu probablydate End -->
		<Dialog v-model:visible="projectDateDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Fecha Inicio y Finalización Previsión</label>
				<Calendar v-model="dates" dateFormat="dd/mm/yy" inputId="range" selectionMode="range" :manualInput="false" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDateDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveDateProject" />
			</template>
		</Dialog>
		<!-- Menu probablydate End -->

		<!-- Menu Realdate End -->
		<Dialog v-model:visible="projectRealDateDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Fecha Inicio y Finalización Real</label>
				<Calendar v-model="realdates" dateFormat="dd/mm/yy" inputId="range" selectionMode="range" :manualInput="false" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideRealDateDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveRealDateProject" />
			</template>
		</Dialog>
		<!-- Menu Realdate End -->

		<!-- Menu description End -->
		<Dialog v-model:visible="projectDescriptionDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Descripción</label>
				<Textarea v-model="valueDescription" :autoResize="true" rows="5" cols="30" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDescriptionDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveDescriptionProject" />
			</template>
		</Dialog>
		<!-- Menu description End -->

		<!-- Menu Status End -->
		<Dialog v-model:visible="projectStatusDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Estado</label>
				<Dropdown v-model="statusMainSelected" :options="MainstatusOptions" optionLabel="name" placeholder="Selecciona el estado" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideMainStatusDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveMainStatusProject" />
			</template>
		</Dialog>
		<!-- Menu Status End -->

		<!-- Menu Private End -->
		<Dialog v-model:visible="changeProtectionDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Tipo</label>
				<Dropdown v-model="protectionSelected" :options="privateOptions" optionLabel="name" placeholder="Selecciona el tipo" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideProtectionDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProtectionProject" />
			</template>
		</Dialog>
		<!-- Menu Private End -->

		<!-- Menu Status End -->
		<Dialog v-model:visible="projectEditNameDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Nombre</label>
				<InputText type="text" v-model="nameProject" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditName"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveNewName" />
			</template>
		</Dialog>
		<!-- Menu Status End -->


		<!-- Menu rename File End -->
		<Dialog v-model:visible="renameFileDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Nombre</label>
				<InputText type="text" v-model="nameFile" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hiderenameFilePopUp"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saverenameFilePopUp" />
			</template>
		</Dialog>
		<!-- Menu rename File End -->

		<!-- Menu Status End -->
		<Dialog v-model:visible="projectPrinexIdDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Identificador Prinex</label>
				<InputText type="text" v-model="prinexId" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditPrinexId"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveNewPrinexId" />
			</template>
		</Dialog>
		<!-- Menu Status End -->

		<!-- Menu Priority End -->
		<Dialog v-model:visible="projectPriorityDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Prioridad</label>
				<Dropdown v-model="prioritySelected" :options="priorityOptions" optionLabel="name" placeholder="Selecciona la prioridad" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hidePriorityDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePriorityProject" />
			</template>
		</Dialog>
		<!-- Menu Priority End -->

		<!-- Menu progress End -->
		<Dialog v-model:visible="projectProgressDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Progreso</label>
				<InputNumber v-model="valueProgress" inputId="minmaxProgress" mode="decimal" :min="0" :max="100" />
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideProgressDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProgressProject" />
			</template>
		</Dialog>
		<!-- Menu progress End -->

		<!-- Menu ProjectStatus End -->
		<Dialog v-model:visible="projectProjectStatusDialog" :style="{width: '450px'}" header="Cambiar datos" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Estado Proyecto</label>
				<Dropdown v-model="statusSelected" :options="statusOptions" optionLabel="name" placeholder="Selecciona el estado del proyecto" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideStatusDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveStatusProject" />
			</template>
		</Dialog>
		<!-- Menu ProjectStatus End -->

		<!-- Menu Hours To Done End -->
		<Dialog v-model:visible="modalHoursToDone" :style="{width: '450px'}" header="Estimación de horas de trabajo" :modal="true" class="p-fluid">
			<div class="field">
				<label for="horizontal">Estimar horas</label>
				<!-- <InputMask v-model="hoursToDone" mask="999H 99m" /> -->
				<InputNumber v-model="hoursToDone" />
				<br>
				<label for="horizontal">Estimar minutos</label>
				<InputNumber v-model="minutesToDone" />
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideHoursToDone"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHoursToDone" />
			</template>
		</Dialog>
		<!-- Menu Hours To Done End -->

		<!-- Menu Working Time End -->
		<Dialog v-model:visible="modalWorkingTimes" :style="{width: '450px'}" header="Imputar horas de trabajo" :modal="true" class="p-fluid">
			<div class="field">
				<label for="horizontal">Hora inicio</label>
				<Calendar v-model="workingTimeStart" inputId="time24" :showTime="true"/>
				<br/>
				<label for="horizontal">Hora finalización</label>
				<Calendar v-model="workingTimeEnd" inputId="time24-2" :showTime="true"/>
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideWorkingTimes"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveWorkingTimes" />
			</template>
		</Dialog>
		<!-- Menu Working Time End -->

		<!-- Menu Link Verification -->
		<Dialog v-model:visible="modalLinkToVerification" :style="{width: '450px'}" header="Unir a una verificación" :modal="true" class="p-fluid">
			<div class="field">
				<label for="horizontal">Verificaciones</label>
				<Dropdown v-model="verificationsSelected" :options="verificationsList"
						optionLabel="title" optionGroupLabel="title" optionGroupChildren="milestones">
				</Dropdown>
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideLinkToVerification"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveLinkToVerification" />
			</template>
		</Dialog>
		<!-- Menu Link Verification -->

		<!-- <DynamicDialog /> -->
		<Toast />
		<!--//One Proyect Selected-->
	</div>
</template>

<script>
	// import { h } from 'vue';
	import EventBus from '../AppEventBus';
	import GanttComponent from './GanttComponent';
	import KanbanComponent from './KanbanComponent';
	import AccessProjectView from './AccessProjectView';
	import CalendarView from './Calendar';
	import VerificationView from './VerificationView';
	import WorkingTimeView from './WorkingTimeView';
	import TreeTemplatesComponent from './TreeTemplatesComponent';
	import { useClipboard } from '@vueuse/core'
	import TreeFilesTemplatesComponent from './TreeFilesTemplatesComponent';
	import {FilterMatchMode} from 'primevue/api';
	// import Button from 'primevue/button';

	export default {
		props: {
            socket: Object,
        },
		data() {
			return {
				//Crear data del proyecto para enviar a backend y guardar nuevos datos
				nodeProyects: [],
				nameProject: '',
				prinexId: '',
				home: {
				},
				items: [
				],
				tasks: [],
				blockButton: false,
				blockUploadButton: false,
				sliderValue: 25,
				workingTime: 0,
				workingTimeEnd: 0,
				protectToDialog: false,
				workingTimeStart: 0,
				hoursToDone: 0,
				// items: [
				// 	{
				// 		label: 'Opciones',
				// 		items: [{label: 'New', icon: 'pi pi-fw pi-plus', command:() => {} },
				// 				{label: 'Delete', icon: 'pi pi-fw pi-trash', url: 'https://www.primetek.com.tr'}]
				// 	}
				// ],
				minutesToDone: 0,
				usersList: null,
				checkedPrivate: false,
				userSelected: null,
				prioritySelected: null,
				dates: [],
				newTime: null,
				realdates: [],
				valueDescription: '',
				dates2: null,
				verificationsSelected: null,
				modalWorkingTimes: false,
				modalHoursToDone: false,
				valueProgress: 0,
				mentions : [],
				filesSearch: {},
				projectFolderDialog: false,
				projectPrinexIdDialog: false,
				projectMainDialog: false,
				projectDateDialog: false,
				projectRealDateDialog: false,
				createTemplateDialog: false,
				projectfolderDialog: false,
				renameFileDialog: false,
				nameFile: '',
				projectProjectStatusDialog: false,
				projectStatusDialog: false,
				projectProgressDialog: false,
				projectEditNameDialog: false,
				projectPriorityDialog: false,
				modalLinkToVerification: false,
				selectedRows: [],
				projectDescriptionDialog: false,
				statusSelected: null,
				statusMainSelected: null,
				folderName: '',
				statusColor: [
					{color: 'success-chip', code: 'Open'},
					{color: 'info-chip', code: 'In_process'},
					{color: 'danger-chip', code: 'Closed'},
					{color: 'warning-chip', code: 'Blocked'},
				],
				priorityColor: [
					{color: 'success-chip', code: 'Low'},
					{color: 'info-chip', code: 'Medium'},
					{color: 'danger-chip', code: 'Very_High'},
					{color: 'warning-chip', code: 'High'},
				],
				statusOptions: [
					{name: 'Abierto', code: 'Open'},
					{name: 'En proceso', code: 'In_process'},
					{name: 'Cerrado', code: 'Closed'},
					{name: 'Bloqueado', code: 'Blocked'},
				],
				priorityOptions: [
					{name: 'Muy alta', code: 'Very_High'},
					{name: 'Alta', code: 'High'},
					{name: 'Media', code: 'Medium'},
					{name: 'Baja', code: 'Low'},
				],
				MainstatusOptions: [
					{name: 'No planeado', code: 'unplanned'},
					{name: 'No empezado', code: 'not_started'},
					{name: 'No empezado a tiempo', code: 'not_started_on_time'},
					{name: 'Empezado', code: 'started'},
					{name: 'Empezado fuera de tiempo', code: 'started_out_of_time'},
					{name: 'No cerrado a tiempo', code: 'not_closed_on_time'},
					{name: 'Cerrado', code: 'closed'},
					{name: 'Cerrado fuera de tiempo', code: 'closed_out_of_time'},
					{name: 'Crítico', code: 'critical'},
				],
				isEditor: false,
				isReader: true,
				isOwner: false,
				files: [],
				projectObject: {
					// assignedTo: null,
					// createdBy: null,
					// createdDate: null,
					// priority: null,
					// datePrevision: null,
					// dateReal: null,
					// status: null,
					// description: null,
					// statusProject: null,
					// progress: 0,

					id: null,
					prinexId: null,
					idparent: null,
					minutestodone: null,
					type: null,
					creationdate: null,
					name: null,
					deleted: null,
					createdby: 1,
					assignedto: null,
					progress: null,
					assignedtogroup: null,
					estadoproyecto: null,
					realstartdate: null,
					realenddate: null,
					probablystartdate: null,
					probablyenddate: null,
					closedby: null,
					description: null,
					urlcloud: null,
					private: null,
					deprecatedid: null,
					templates_id: null,
					estado: null,
					visibilidad: null,
					planned: false,
					priority: null,
					folder_has_users: []
				},
				dragging: false,
				protectedFiles: [],
				selectedProduct: null,
				items2: [],
				projectId: null,
				priority: [
					{ name: 'Alta', code: 'High' },
					{ name: 'Media', code: 'Medium' },
					{ name: 'Baja', code: 'Low' },
				],
				collapsed: true,
				messages: [],
				protectionSelected: null,
				privateOptions: [
					{name: 'Privado', code: true},
					{name: 'Público', code: false},
				],
				messageText: '',
				changeProtectionDialog: false,
				users: [],
				replyId: null,
				templateName: '',
				replyWho: null,
				fileToLink: null,
				fileSelectedToProtect: null,
				fileNameToLink: null,
				activeTab: 0,
				parentsId: [],
				importantFiles: [],
				isLoadingDiv: true,
				verificationsList: [],
				accessList: [],
			}
		},
		nodeService: null,
		created() {
			this.initFilters();


			var lastTarget = null;

			// Solo quien tiene permisos.

			window.addEventListener("dragenter", function(e)
			{
				lastTarget = e.target; // cache the last target here
				// unhide our dropzone overlay
				if(document.querySelector(".dropzone")){
					document.querySelector(".dropzone").style.visibility = "";
					document.querySelector(".dropzone").style.opacity = 1;
				}
			});

			window.addEventListener("dragleave", function(e)
			{
				// this is the magic part. when leaving the window,
				// e.target happens to be exactly what we want: what we cached
				// at the start, the dropzone we dragged into.
				// so..if dragleave target matches our cache, we hide the dropzone.
				// `e.target === document` is a workaround for Firefox 57

				if((e.target === lastTarget || e.target === document) && document.querySelector(".dropzone"))
				{
					document.querySelector(".dropzone").style.visibility = "hidden";
					document.querySelector(".dropzone").style.opacity = 0;
				}
			});

			window.addEventListener('drop', function(e) {
				console.log(e);
				if((e.target === lastTarget || e.target === document) && document.querySelector(".dropzone")){
					document.querySelector(".dropzone").style.visibility = "hidden";
					document.querySelector(".dropzone").style.opacity = 0;
				}
			});
			EventBus.on('proyect-node', this.proyectNodeHandler);

		},
		beforeCreate() {
			// if(!this.$store.getters['azure/getTree'].root || !this.$store.getters['azure/getTree'].root.find(x => x.key === this.$route.params.id)) {
			// 	this.$router.push(
			// 			{ path: `/projects` }
			// 	);
			// } if exits and you have permissions
		},
		async mounted() {

			await this.$store.dispatch('user/getUsersList')
			.then( (data) => {
				this.usersList = data.usersList;
				this.isTableLloadingoading = false;
				data.usersList.forEach(element => {
                    this.users.push({ value: element.email.substring(0, element.email.indexOf("@")), firstName: element.name + ' ' + (element.lastname ?? ''), id: element.id })
                });
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});

			await this.$store.dispatch('project/getProject', {
					id: this.$route.params.id,
			}).then(
				(data) => {

					if(!data || data.deleted) this.$router.push("/"); // this.expandedKeys = {}; pass params to clean on dashboard

					this.projectObject = data
					this.projectObject.createdby = {
						id: data.createdby,
						name: (this.usersList.find(x => x.id === data.createdby)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.createdby)?.lastname ?? '')
					}

					this.projectObject.assignedto = {
						id: data.assignedto,
						name: data.assignedto ? ((this.usersList.find(x => x.id === data.assignedto)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.assignedto).lastname ?? '')) : ''
					}

					this.projectObject.closedby = {
						id: data.closedby,
						name: data.closedby ? ((this.usersList.find(x => x.id === data.closedby)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.closedby).lastname ?? '')) : ''
					}
				}
			)

			await this.$store.dispatch('project/getImportantFile', {folder_id: this.projectObject.id})
			.then( (data) => {
				console.log(data);
				this.importantFiles = data;
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});

			this.loadComunications();

			await this.$store.dispatch('azure/getFiles', {
				id: this.projectObject.id
			})
			.then( (data) => {
				this.files = data;
			});

			await this.getVerificationsAction();

			await this.$store.dispatch('project/getUsersPermisionInProject', { folder_id: this.$route.params.id })
			.then( async(data) => {

				data.forEach(element => {
					const user = this.usersList.find(val => val.id === element.users_id)
					if(user) {
						this.accessList.push({
							id: element.users_id,
							name: user.name + ' ' + (user.lastname ?? ''),
							isOwner: element.metadata,
							isReader: element.read,
							isEditor: element.write,
							accessType: element.rol,
						})
					}
				});

				if(this.projectObject.private && !this.accessList.find(x => x.id === this.getUser.id)){
					this.$router.push("/");
				}
				// this.isTableLloadingoading = false;
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});

			if(this.projectObject.type !== 'PROJECT') {
				this.parentsId = [];
				await this.$store.dispatch('project/getParentsProjectById', {
					id: this.projectObject.idparent
				}).then( (data) => {
					data.forEach(e => {
						if(e.type === 'PROJECT') {
							this.home = {label: e.name, to: `/projects/${e.id}`};
							this.projectId = e.id;
						}
						else this.items.push({label: e.name, to: `/projects/${e.id}`, order: e.order})

						this.parentsId.push(e.id);
					})

					this.items.sort(function(a, b) {
						return b.order - a.order;
					})
				});
			} else this.projectId = this.projectObject.id;
			// guardar sesion archivos subidos arbol, desplegados y selecionado del arbol
			// revisar todos catch y meter toast y quitar loading mas que watch y que desde server mandar 400, 401 o 500 y mensaje quitar await o usarlo sin then o cathc si es await try catch
			// servidor reestructurar solo mandar un res por query usar else if para mandar mas de uno dependiendo si es error o no

			this.$store.dispatch('azure/getProtectFile', { folder_id: this.projectObject.id }).then((data) => {
				this.protectedFiles.push(...data)
			})

			document.title = this.projectObject.name;

			this.loadIsEditor();
			this.loadIsReader();
			this.loadIsOwner();

			this.isLoadingDiv = false;
		},
		methods: {
			onRowContextMenu(event) {
				console.log(this.$refs)
				this.$refs.cm.show(event.originalEvent);
			},
			clipboardCopy(text) {
				const { copy } = useClipboard();

				copy(location.protocol + '//' + location.host + '/projects/' + text);
				// window.Office.context.mailbox.displayNewMessageForm({
				// attachments: [
				// 	{
				// 	type: 'file',
				// 	name: 'archivo1.txt',
				// 	url: 'https://ejemplo.com/ruta/al/archivo1.txt'
				// 	},
				// 	{
				// 	type: 'file',
				// 	name: 'archivo2.jpg',
				// 	url: 'https://ejemplo.com/ruta/al/archivo2.jpg'
				// 	}
				// ]
				// });
			},
			clickReplace(){
				console.log(this.$refs.fileInput)
				this.$refs.$.refs.fileInput.download.click();
			},
			canEditFile(id) {

				console.log(id)
				console.log('canEditFile')

				const file = this.protectedFiles.find(x => x.files_id === id) ?? false;

				console.log(file)

				if(file) {
					return file.owner === this.getUser.id;
				}

				return this.isOwner || this.isEditor;
			},
			async protectFile(file_id, userId) {
				await this.$store.dispatch('azure/protectFile', { file_id, userId, folder_id: this.projectObject.id})
					.then( async() => {
						this.protectedFiles.push({
							files_id: file_id, owner: userId, folder_id: this.projectObject.id
						})
						this.$toast.add({severity:'success', summary: 'Archivo protegido', detail:'Archivo protegido correctamente', life: 3000});
					})
					.catch((e) => {
						console.log(e)
						// this.isLoading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail:'Algo fue mal', life: 3000});
				});
			},
			async removeProtection(file_id) {
				await this.$store.dispatch('azure/removeProtectFile', { file_id })
					.then( async() => {
						this.protectedFiles = this.protectedFiles.filter(item => item.files_id !== file_id);
						this.$toast.add({severity:'success', summary: 'Acción realizada', detail:'Acción realizada correctamente', life: 3000});
					})
					.catch((e) => {
						console.log(e)
						// this.isLoading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail:'Algo fue mal', life: 3000});
					});
			},
			async protectFileTo(file_id) {
				this.protectToDialog = true;
				this.userSelected = null;
				this.fileSelectedToProtect = file_id;
			},
			hideProtectFileDialog() {
				this.protectToDialog = false;
			},
			async saveProtectFileProject() {
				await this.$store.dispatch('azure/protectFile', { file_id: this.fileSelectedToProtect, userId: this.userSelected.id, folder_id: this.projectObject.id})
					.then( async() => {
						this.protectedFiles.push({
							files_id: this.fileSelectedToProtect, owner: this.userSelected, folder_id: this.projectObject.id
						})
						this.protectToDialog = false;
						this.$toast.add({severity:'success', summary: 'Archivo protegido', detail:'Archivo protegido correctamente', life: 3000});
					})
					.catch((e) => {
						console.log(e)
						// this.isLoading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail:'Algo fue mal', life: 3000});
						this.protectToDialog = false;
				});
			},
			async tabChange(e){
				if(e.index == 2) {
					await this.getVerificationsAction();
				}
			},
			async getVerificationsAction() {
				this.verificationsList = [];
				await this.$store.dispatch('verification/getVerifications', { folder_id: this.projectObject.id })
					.then( async(data) => {
						data.data.forEach((e) => {
							this.verificationsList.push(e);
						})
					})
					.catch((e) => {
						console.log(e)
						// this.isLoading = false;
				});
			},
			getTimeDiff(start, end) {
				let startTime  = new Date(start);
				let endTime    = new Date(end);

				let diff = endTime - startTime;
				let diffDays = Math.floor(diff / 86400000); // days
				let diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
				let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes

				// console.log(diff/(1000*60*60) );

				return {minutes: diffMins , hours: ((diffDays * 24) + diffHrs)};
			},
			formatDate(value) {
				return value.toLocaleDateString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			formatDateComunication(value) {
				return value.toLocaleDateString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
                    minute: '2-digit'
				});
			},
			removeAt(idx) {
				this.list.splice(idx, 1);
			},
			openNewFolder() {
				this.submitted = false;
				this.projectFolderDialog = true;
				this.checkedPrivate = false;
			},
			hideFolderDialog() {
				this.projectFolderDialog = false;
				this.submitted = false;
			},
			typeFolder(type) {
				let newType = '';
				if(type == 'PROJECT') newType = 'capítulo';
				else if(type == 'CHAPTER') newType = 'subcapítulo';
				else if(type == 'SUBCHAPTER') newType = 'expediente';

				return newType;
			},
			initFilters() {
				this.filesSearch = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
			async loadComunications(){
				this.messages = [];
				await this.$store.dispatch('notification/getSendedNotificationsById', { folder_id: this.projectObject.id })
				.then( (data) => {
					console.log(data)
					data?.collection.forEach(element => {
						this.messages.push({ id: element.id, who: this.usersList.find(x => x.id === element.publisher).name, reply_to: element.reply_to, post: element.post, createddate: element.createddate, replies: [] })
					});

					this.messages.forEach((item) => { // index, object
						if(item.reply_to) {
							this.messages.find(i => i.id === item.reply_to).replies.push(item)
							// object.splice(index, 1);
						}
					})

					// this.messages = this.messages.filter(item => (item.reply_to !== undefined && item.reply_to !== null));

					// this.messages.forEach((item, index, object) => { // index, object
					// 	if(item.reply_to) {
					// 		// this.messages.find(i => i.id === item.reply_to).replies.push(item)
					// 		object.splice(index, 1);
					// 	}
					// })

					let index = this.messages.length - 1;

					while (index >= 0) {
						if ( this.messages[index].reply_to ) {
							this.messages.splice(index, 1);
						}

						index -= 1;
					}
				})
				.catch((e) => {
					console.log(e)
					// this.isLoading = false;
				});
			},
			async saveFolderProject() {

				if(this.folderName.trim() === '') {
					this.$toast.add({severity:'error', summary: 'Nombre no seleccionado', detail:'Escribe un nombre para la carpeta', life: 3000});
				} else {
					this.submitted = true;
					this.projectFolderDialog = false;
					//Reload folder tree
					let type = null;
					let type_text = '';

					if(this.projectObject.type == 'PROJECT') {
						type = 'CHAPTER';
						type_text = 'Capítulo';
					}
					else if(this.projectObject.type == 'CHAPTER') {
						type = 'SUBCHAPTER';
						type_text = 'Subcapítulo';
					}
					else if(this.projectObject.type == 'SUBCHAPTER') {
						type = 'RECORD';
						type_text = 'Expediente';
					}

					// Refactorizar código y comentar. Crear documentación
					// handleApply
					// load

					console.log(this.projectId)

					await this.$store.dispatch('azure/createSubFolder', {
						name: this.folderName.replace(/^\/\*-\.;,+/i, ''),
						id: this.projectObject.id,
						creationdate: new Date(),
						type,
						createdby: this.getUser.id,
						planned: false,
						project: this.projectId,
						priority: 'Low',
						idparent: this.projectObject.id,
						privateType: this.checkedPrivate,
					}).then(
						async () => {
							await this.$store.dispatch('azure/getRootTree', { userId: this.getUser.id })
							this.folderName = '';
							this.$toast.add({severity:'success', summary: `${type_text} creado`, detail:`${type_text} creado correctamente`, life: 3000});
						}
					)
				}
			},
			openCreateTemplate() {
				this.submitted = false;
				this.createTemplateDialog = true;
			},
			hideCreateTemplate() {
				this.createTemplateDialog = false;
				this.submitted = false;
			},
			async saveCreateTemplate(){
				if(this.templateName.replace(/\s+/g, '').length > 0) {
					await this.$store.dispatch('project/createTemplate', { folder_id: this.projectObject.id, name: this.templateName.replace(/^\/\*-\.;,+/i, ''), createdAt: new Date(), userId: this.getUser.id, privateType: this.checkedPrivate }).then( () => {
						this.$toast.add({severity:'success', summary: 'Creado correctamente', detail:'Copia creada correctamente', life: 3000});
					}).catch((e) => {
						console.log(e)
						this.$toast.add({severity:'error', summary: 'Algo fue mal', detail:'Ya existe ese nombre de plantilla', life: 3000});
						// this.isLoading = false;
					});
					this.createTemplateDialog = false;
				}
			},
			replyTo(who, id) {
				this.replyId = id;
				this.replyWho = who;
				document.getElementById("messageInput").scrollIntoView({ block: "start" });
				// this.$refs.typeBox[0].focus();
			},
			cleanReplyTo() {
				this.replyId = null;
				this.replyWho = null;
			},
			openDateDialog() {
				this.submitted = false;
				this.projectDateDialog = true;
				if(this.projectObject.probablystartdate && this.projectObject.probablyenddate){
					this.dates[0] = new Date(this.projectObject.probablystartdate);
					this.dates[1] = new Date(this.projectObject.probablyenddate);
				} else {
					this.dates[0] = new Date();
					this.dates[1] = new Date();
				}
			},
		onOpen (key) {
            // this.items2 = key === '@' ? this.users : issues;
                if(key === '@') {
                    this.items2 = this.users
                }
            },
        save() {
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Guardado correctamente', life: 3000 });
        },

			openWorkingTimes() {
				this.submitted = false;
				this.modalWorkingTimes = true;
				this.workingTime = 0;
			},
			hideWorkingTimes() {
				this.projectDialog = false;
				this.modalWorkingTimes = false;
			},
			async saveWorkingTimes() {
				this.submitted = true;
				this.modalWorkingTimes = false;
				// const hours = this.timeToMinutes(this.workingTime);
				// this.hoursToDone = hours;
				let timeDiff = this.getTimeDiff(this.workingTimeStart, this.workingTimeEnd)

				await this.$store.dispatch('tempo/saveWorkingTime', {
					starttime: this.workingTimeStart,
					endtime: this.workingTimeEnd,
					folder_id: this.projectObject.id,
					users_id: this.getUser.id,
				}).then( //arreglar pasando id padre si no es un proyecto
					() => {
						this.newTime = {
							who: this.getUser.name + ' ' + (this.getUser.lastname ?? ''),
							from: this.workingTimeStart,
							to: this.workingTimeEnd,
							hours: timeDiff.hours,
							minutes: timeDiff.minutes
						}
						this.starttime = 0;
						this.endtime = 0;
						this.workingTimeStart = 0;
						this.workingTimeEnd = 0;
						this.$toast.add({severity:'success', summary: 'Horas imputadas', detail:'Horas imputadas correctamente', life: 3000});
					}
				)
				// await this.saveProjectInformation();
			},
			openHoursToDone() {
				this.submitted = false;
				this.modalHoursToDone = true;
				this.hoursToDone = 0;
				this.minutesToDone = 0;
			},
			hideHoursToDone() {
				this.projectDialog = false;
				this.modalHoursToDone = false;
			},
			removeFile(file_id) {
				this.$confirm.require({
					message: '¿Seguro que quieres continuar?',
					header: 'Confirmar',
					acceptLabel: 'Sí',
					rejectLabel: 'No',
					icon: 'pi pi-exclamation-triangle',
					accept: async () => {
						await this.$store.dispatch('azure/removeFile', {folder_id: this.projectObject.id, userId: this.getUser.id, file_id}).then( async() => {
							this.$toast.add({severity:'success', summary: 'Archivo eliminado', detail:'Archivo eliminado correctamente', life: 3000});
							await this.$store.dispatch('azure/getFiles', {
								id: this.projectObject.id
							})
							.then( (data) => {
								this.files = data;
							});
						});
					},
					reject: () => {
						//callback to execute when user rejects the action
					},
					onShow: () => {
						//callback to execute when dialog is shown
					},
					onHide: () => {
						//callback to execute when dialog is hidden
					}
				})
			},
			removeProject(){
				this.$confirm.require({
					message: '¿Seguro que quieres continuar?',
					header: 'Confirmar',
					acceptLabel: 'Sí',
					rejectLabel: 'No',
					icon: 'pi pi-exclamation-triangle',
					accept: async () => {
						await this.$store.dispatch('azure/removeProject', {type: this.projectObject.type,folder_id: this.projectObject.id, userId: this.getUser.id}).then( async() => {
							await this.$store.dispatch('azure/getRootTree', { userId: this.getUser.id })
							this.$router.push(
								{ path: `/` }
							);
						});
					},
					reject: () => {
						//callback to execute when user rejects the action
					},
					onShow: () => {
						//callback to execute when dialog is shown
					},
					onHide: () => {
						//callback to execute when dialog is hidden
					}
				})
			},
			async saveHoursToDone() {
				this.submitted = true;
				this.modalHoursToDone = false;
				const hours = this.timeToMinutes(this.hoursToDone, this.minutesToDone);
				// console.log(hours);
				this.projectObject.minutestodone = hours;
				this.hoursToDone = 0;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			minutesToString(minutes) {

                let totalHours = Math.floor((minutes / 60));

                let totalMinutes = minutes % 60;

				return totalHours > 0 || totalMinutes > 0 ? totalHours.toString() + 'H' + ' ' + totalMinutes.toString() + 'm' : '' ; // is hours summary
			},
			timeToMinutes(timeInHour, timeInMinutes) {
				// var timeParts = timeInHour.split("H");
				// console.log(timeInHour)
				return Number(timeInHour) * 60 + Number(timeInMinutes);
			},
			openRealDateDialog() {
				this.submitted = false;
				this.projectRealDateDialog = true;
				if(this.projectObject.realstartdate && this.projectObject.realenddate){
					this.realdates[0] = new Date(this.projectObject.realstartdate);
					this.realdates[1] = new Date(this.projectObject.realenddate);
				} else {
					this.realdates[0] = new Date();
					this.realdates[1] = new Date();
				}
			},
			hideRealDateDialog() {
				this.projectDialog = false;
				this.projectRealDateDialog = false;
				console.log(this.realdates)
			},
			async saveRealDateProject() {
				this.submitted = true;
				this.projectRealDateDialog = false;
				if(this.realdates) {
					this.projectObject.realstartdate = this.realdates[0];
					this.projectObject.realenddate = this.realdates[1];
					this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

					await this.saveProjectInformation();
				}
			},
			hideDateDialog() {
				this.projectDialog = false;
				this.projectDateDialog = false;
			},
			async saveDateProject() {
				this.submitted = true;
				this.projectDateDialog = false;
				if(this.dates) {
					this.projectObject.probablystartdate = this.dates[0];
					this.projectObject.probablyenddate = this.dates[1];
					this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

					await this.saveProjectInformation();
				}
			},
			openStatusDialog() {
				this.submitted = false;
				this.statusSelected = this.statusOptions.find(x => x.code === this.projectObject.estadoproyecto);
				this.projectProjectStatusDialog = true;
			},
			hideStatusDialog() {
				this.projectDialog = false;
				this.projectProjectStatusDialog = false;
			},
			async saveStatusProject() {
				this.submitted = true;
				this.projectProjectStatusDialog = false;
				this.projectObject.estadoproyecto = this.statusSelected.code;
				if(this.statusSelected.code === 'Closed') {
					this.projectObject.closedby = {
						id: this.getUser.id,
						name: this.usersList.find(x => x.id === this.getUser.id).name
					}
				}
				else {
					this.projectObject.closedby = null;
				}
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			openPriorityDialog() {
				this.submitted = false;
				this.prioritySelected = this.priorityOptions.find(x => x.code === this.projectObject.priority);
				this.projectPriorityDialog = true;
			},
			hidePriorityDialog() {
				this.projectDialog = false;
				this.projectPriorityDialog = false;
			},
			async savePriorityProject() {
				this.submitted = true;
				this.projectPriorityDialog = false;
				this.projectObject.priority = this.prioritySelected.code;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			openMainStatusDialog() {
				this.submitted = false;
				this.statusMainSelected =  this.MainstatusOptions.find(x => x.code === this.projectObject.estado);
				this.projectStatusDialog = true;
			},
			hideMainStatusDialog() {
				this.projectDialog = false;
				this.projectStatusDialog = false;
			},
			async saveMainStatusProject() {
				this.submitted = true;
				this.projectStatusDialog = false;
				this.projectObject.estado = this.statusMainSelected.code;
				if(this.statusMainSelected.code === 'closed' || this.statusMainSelected.code === 'closed_out_of_time') {
					this.projectObject.closedby = {
						id: this.getUser.id,
						name: this.usersList.find(x => x.id === this.getUser.id).name
					}
				}
				else {
					this.projectObject.closedby = null;
				}
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			openProgressDialog() {
				this.submitted = false;
				this.valueProgress = this.projectObject.progress ?? 0;
				this.projectProgressDialog = true;
			},
			hideProgressDialog() {
				this.projectDialog = false;
				this.projectProgressDialog = false;
			},
			async saveProgressProject() {
				this.submitted = true;
				this.projectProgressDialog = false;
				this.projectObject.progress = this.valueProgress;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			linkFileToVerification(file_id, file_title) {
				this.fileToLink = file_id;
				this.fileNameToLink = file_title;
				this.modalLinkToVerification = true;
			},
			hideLinkToVerification(){
				this.modalLinkToVerification = false;
			},
			async saveLinkToVerification(){
				if (this.fileToLink && this.verificationsSelected) {
					await this.$store.dispatch('verification/addLinkVerificationFile', { data: {
						id: parseInt(this.verificationsSelected.id), verificationsprocesses_id: parseInt(this.verificationsSelected.verificationsprocesses_id), file_id: this.fileToLink, file_title: this.fileNameToLink
					} }).then(() => { this.verificationsSelected = null });
					this.modalLinkToVerification = false;
					this.$toast.add({severity:'success', summary: 'Archivo añadido', detail:'Archivo añadido a verificación', life: 3000});
				}
			},
			rowClass(file) {
				return this.importantFiles.find((e) => e.files_id === file.id) ? 'row-accessories': null
			},
			openDescriptionDialog() {
				this.submitted = false;
				this.valueDescription = this.projectObject.description ?? '';
				this.projectDescriptionDialog = true;
			},
			hideDescriptionDialog() {
				this.projectDialog = false;
				this.projectDescriptionDialog = false;
			},
			async saveDescriptionProject() {
				this.submitted = true;
				this.projectDescriptionDialog = false;
				this.projectObject.description = this.valueDescription;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			openMainDialog() {
				this.submitted = false;
				console.log(this.projectObject.assignedto)
				this.userSelected = this.projectObject.assignedto.id ?? null;
				this.projectMainDialog = true;
			},
			hideMainDialog() {
				this.projectDialog = false;
				this.projectMainDialog = false;
			},
			async saveMainProject() {
				this.submitted = true;
				this.projectMainDialog = false;
				this.projectObject.assignedto = this.userSelected;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			openEditName() {
				this.submitted = false;
				this.nameProject = this.projectObject.name;
				this.projectEditNameDialog = true;
			},
			hideEditName() {
				this.projectDialog = false;
				this.projectEditNameDialog = false;
			},
			async saveNewName() {
				this.submitted = true;
				this.projectEditNameDialog = false;
				this.projectObject.name = this.nameProject;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveNameProjectName();
			},
			openEditPrinexId() {
				this.submitted = false;
				this.prinexId = this.projectObject.prinexId;
				this.projectPrinexIdDialog = true;
			},
			hideEditPrinexId() {
				this.projectDialog = false;
				this.projectPrinexIdDialog = false;
			},
			hideProtectionDialog() {
				this.projectDialog = false;
				this.changeProtectionDialog = false;
			},
			changeProtection() {
				this.submitted = true;
				this.protectionSelected = this.projectObject.private ? { name: 'Privado', code: true } : { name: 'Público', code: false };
				this.changeProtectionDialog = true;
			},
			async saveProtectionProject() {
				this.submitted = true;
				this.changeProtectionDialog = false;
				this.projectObject.private = this.protectionSelected.code;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.saveProjectInformation();
			},
			async saveNewPrinexId() {
				this.submitted = true;
				this.projectPrinexIdDialog = false;
				this.projectObject.prinexId = this.prinexId;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				// await this.saveProjectInformation();
			},
			formatBytes(bytes, decimals = 2) {
				if (!+bytes) return '0 Bytes'

				const k = 1024
				const dm = decimals < 0 ? 0 : decimals
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

				const i = Math.floor(Math.log(bytes) / Math.log(k))

				return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
			},
			toggle(event) {
				this.$refs.menu.toggle(event);
			},
			async onUpload(e, replace) {
				console.log(e)
				if (e.files[0] && e.files[0].name) {
					console.log( e.files[0]);

					let formy = new FormData();
					formy.append('file', e.files[0]);
					formy.append('folder_id', this.projectObject.id);
					formy.append('name', e.files[0].name);
					formy.append('replace', replace);
					formy.append('userId', this.getUser.id);

					// this.isLoadingDiv = true;

					this.blockUploadButton = true;

					await this.$store.dispatch('azure/upLoadFile', formy).then( async() => {
						// this.files = data;
						// this.isLoadingDiv = false;
						this.blockUploadButton = false;
					}).catch(() => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
						this.blockUploadButton = false;
					});

					await this.$store.dispatch('azure/getFiles', {
					id: this.projectObject.id
					}).then( async(data) => {
						this.files = data;
					});

					this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
				}
			},
			async onUploadDrop(e) { // replace
				console.log(e)
				// if (e.dataTransfer.items) {
				// 	console.log(e.dataTransfer.items);
				// 	console.log(JSON.stringify(e.dataTransfer.items))
				// 	// Use DataTransferItemList interface to access the file(s)
				// 	// [...e.dataTransfer.items].forEach((item, i) => {
				// 	// // If dropped items aren't files, reject them
				// 	// if (item.kind === "file") {
				// 	// 	// const file = item.getAsFile();
				// 	// 	// console.log(file)
				// 	// 	// console.log(`… file[${i}].name = ${file.name}`);
				// 	// }
				// 	// });
				// } else {
				// 	// Use DataTransfer interface to access the file(s)
				// 	[...e.dataTransfer.files].forEach((file, i) => {
				// 	console.log(`… file[${i}].name = ${file.name}`);
				// 	});
				// }
				if (e.dataTransfer.files[0] && e.dataTransfer.files[0].name) {
					// console.log( e.files[0]);

					let formy = new FormData();
					formy.append('file', e.dataTransfer.files[0]);
					formy.append('folder_id', this.projectObject.id);
					formy.append('name', e.dataTransfer.files[0].name);
					formy.append('replace', false);
					formy.append('userId', this.getUser.id);

					// this.isLoadingDiv = true;

					this.blockUploadButton = true;

					await this.$store.dispatch('azure/upLoadFile', formy).then( async() => {
						// this.files = data;
						// this.isLoadingDiv = false;
						this.blockUploadButton = false;
					}).catch(() => {
						this.blockUploadButton = false;
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
					});

					await this.$store.dispatch('azure/getFiles', {
					id: this.projectObject.id
					}).then( async(data) => {
						this.files = data;
						this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
					}).catch(() => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
					});
				}
			},
			async useFileTemplate() {
				this.$dialog.open(TreeFilesTemplatesComponent, {
					props: {
						header: 'Carpetas',
						style: {
							width: '50vw'
						},
						breakpoints: {
							'960px': '75vw',
							'640px': '90vw'
						},
						modal: true
					},
					templates: {
						footer: () => {
							return [
								// h(Button, { label: 'Cerrar', icon: 'pi pi-times', onClick: () => dialogRef.close({ buttonType: 'No' }), class: 'p-button-text' }),
								// h(Button, { label: 'Yes', icon: 'pi pi-check', onClick: () => dialogRef.close({ buttonType: 'Yes' }), autofocus: true })
							];
						}
					},
					onClose: async (options) => {
						const data = options.data;
						if (data) {

							this.isLoadingDiv = true;

							await this.$store.dispatch('azure/copyFileToAnotherFolder', { name: data.rename, fileId: data.key, folderId: this.projectObject.id }).then( async() => {
								// this.files = data;
								this.isLoadingDiv = false;
							});

							await this.$store.dispatch('azure/getFiles', {
							id: this.projectObject.id
							}).then( async(data) => {
								this.files = data;
							});

							this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
						}
					}
				});
			},
			async sendEmail() {

				console.log(this.selectedRows);

				const e = await this.$store.dispatch('project/sendEmailWithFile', {files: this.selectedRows, email: this.getUser.email});

				// console.log(e);

				this.openUrl(e.webLink);

			},
			async onUploadTemplate(e) {

				// eslint-disable-next-line no-unused-vars
				const dialogRef = this.$dialog.open(TreeTemplatesComponent, {
						props: {
							header: 'Carpetas',
							style: {
								width: '50vw'
							},
							breakpoints: {
								'960px': '75vw',
								'640px': '90vw'
							},
							modal: true
						},
						templates: {
							footer: () => {
								return [
									// h(Button, { label: 'Cerrar', icon: 'pi pi-times', onClick: () => dialogRef.close({ buttonType: 'No' }), class: 'p-button-text' }),
									// h(Button, { label: 'Yes', icon: 'pi pi-check', onClick: () => dialogRef.close({ buttonType: 'Yes' }), autofocus: true })
								];
							}
						},
						onClose: async (options) => {
							const data = options.data;
							if (data) {
								if (e.files[0] && e.files[0].name) {

									let formy = new FormData();
									formy.append('file', e.files[0]);
									formy.append('folder_id', data.key);
									formy.append('name', e.files[0].name);

									this.isLoadingDiv = true;

									await this.$store.dispatch('azure/upLoadFile', formy).then( async() => {
										// this.files = data;
										this.isLoadingDiv = false;
									});

									await this.$store.dispatch('azure/getFiles', {
									id: this.projectObject.id
									}).then( async(data) => {
										this.files = data;
									});

									this.$toast.add({ severity: 'info', summary: 'Subido', detail: 'Archivo subido', life: 3000 });
								}
								// const summary_and_detail = buttonType ? { summary: 'No has seleccionado carpeta', detail: `Cerraste la ventana antes de seleccionar carpeta` } : { summary: 'Carpeta seleccionado correctamente', detail: data.label };
								// this.$toast.add({ severity: 'info', ...summary_and_detail, life: 3000 });
							}
						}
					});
				// if (e.files[0] && e.files[0].name) {
				// 	console.log( e.files[0]);

				// 	let formy = new FormData();
				// 	formy.append('file', e.files[0]);
				// 	formy.append('folder_id', this.projectObject.id);
				// 	formy.append('name', e.files[0].name);

				// 	this.isLoadingDiv = true;

				// 	await this.$store.dispatch('azure/upLoadFile', formy).then( async(data) => {
				// 		this.files = data;
				// 		this.isLoadingDiv = false;
				// 	});

				// 	await this.$store.dispatch('azure/getFiles', {
				// 	id: this.projectObject.id
				// 	}).then( async(data) => {
				// 		this.files = data;
				// 	});

				// 	this.$toast.add({ severity: 'info', summary: 'Success', detail: 'Archivo subido', life: 3000 });
				// }
			},
			async saveProjectInformation() {
				let sendedProject = Object.assign({}, this.projectObject);
				sendedProject.createdby = sendedProject.createdby.id;
				if(sendedProject.closedby) sendedProject.closedby = sendedProject.closedby.id;
				if(sendedProject.assignedto) {
					await this.saveAccessAccess(sendedProject.assignedto);
					sendedProject.assignedto = sendedProject.assignedto.id;
				}

				delete sendedProject.folder_has_users;

				// console.log(sendedProject);

				await this.$store.dispatch('project/saveProject', {
					project: sendedProject
				});
			},
			async saveAccessAccess(user) {
				let userId = this.accessList.find(val => val.id === user.id);

				if (!userId) {
					this.accessList.push({
						id: user.id,
						name: user.name + ' ' + (user.lastname ?? ''),
						isOwner: true,
						isReader: false,
						isEditor: false,
						accessType: 'Owner',
					})

					await this.$store.dispatch('project/addUsersPermisionToProject', { folder_id: this.$route.params.id,
					users_id: user.id,
					read: false,
					write: false,
					metadata: true,
					rol: 'Owner',
					})
					.then( async() => {
							// this.loadingSave = true;
							// console.log(data);
						})
						.catch((e) => {
							console.log(e)
							// this.isLoading = false;
					});
				}
			},
			async saveNameProjectName() {
				await this.$store.dispatch('project/renameProject', {
					name: this.projectObject.name , folder_id: this.projectObject.id
				});
			},
			typeIcon (mime_type) {
				console.log('mime:', mime_type);
				const icon_classes = {
					// Media
					'image' : 'fa-file-image',
					'audio' : 'fa-file-audio',
					'video' : 'fa-file-video',
					// Documents
					'application/pdf' : 'fa-file-pdf',
					'application/msword' : 'fa-file-word',
					'application/vnd.ms-word' : 'fa-file-word',
					'application/rtf' : 'fa-file-word',
					'application/vnd.oasis.opendocument.text' : 'fa-file-word',
					'application/vnd.openxmlformats-officedocument.wordprocessingml' : 'fa-file-word',
					'application/vnd.ms-excel' : 'fa-file-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml' : 'fa-file-excel',
					'application/vnd.oasis.opendocument.spreadsheet' : 'fa-file-excel',
					'application/vnd.ms-powerpoint' : 'fa-file-powerpoint',
					'application/vnd.openxmlformats-officedocument.presentationml' : 'fa-file-powerpoint',
					'application/octet-stream' : 'fa-envelope',
					'message/rfc822' : 'fa-envelope',
					'application/vnd.oasis.opendocument.presentation' : 'fa-file-powerpoint',
					'text/plain' : 'fa-file-text',
					'text/html' : 'fa-file-code',
					'application/json' : 'fa-file-code',
					// Archives
					'application/gzip' : 'fa-file-archive',
					'application/zip' : 'fa-file-archive',
				};

				return icon_classes[mime_type] ?? 'fa-file';
			},
			async SaveAsImportant(fileId) {
				const isIn = this.importantFiles.findIndex((e) => e.files_id === fileId)

				console.log(fileId);
				console.log(isIn);

				if(isIn < 0) {
					await this.$store.dispatch('project/addImportantFile', {folder_id: this.projectObject.id, files_id: fileId})
					.then( () => {
						this.importantFiles.push({ folder_id: this.projectObject.id, files_id: fileId})
						console.log(this.importantFiles);
					})
					.catch((e) => {
						console.log(e)
						this.isLoading = false;
					})
				}
				else {
					await this.$store.dispatch('project/deleteImportantFile', {folder_id: this.projectObject.id, files_id: fileId})
					.then( () => {
					// 	const position = this.importantFiles.find((e, i) => {
					// 	if(e.files_id === fileId) return i;
					// })
						console.log(this.importantFiles);
						this.importantFiles.splice(isIn, 1)
						console.log(this.importantFiles);
					})
					.catch((e) => {
						console.log(e)
						this.isLoading = false;
					})

				}

				// console.log()

				// const copy = [...this.files];
				// this.files = [...copy];

				// console.log(this.importantFiles)
			},
			openUrl(url, anotherTab = false) { //email = false
				window.open(url, anotherTab ?'_blank' : '');
				// if(!email) {
				// 	window.open(url, anotherTab ?'_blank' : '');
				// } else {
				// 	window.open('mailto:ruben.lopez@wearevanguard.eu?subject=Prueba test&body=Esto es una prueba' + ' \n Archivos: ' + url);
				// }
			},
			async getSharedLink(id) {
				this.blockButton = true;
				const link = await this.$store.dispatch('azure/getFiles', {
					id,
					sharedLink: true,
				})

				this.openUrl(link, true);
				this.blockButton = false;
			},
			testAttachment(url){
				// if (Office.somePropertyOrFunction) {
					// eslint-disable-next-line no-undef
					Office.context.mailbox.item.addFileAttachmentAsync(
						url, // Archivo que deseas adjuntar
						'test', // Nombre del archivo adjunto
						// { asyncContext: datosAdicionales },
						function (asyncResult) {
							// eslint-disable-next-line no-undef
							if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
								// Archivo adjunto correctamente
								console.log('Archivo adjuntado con éxito.');
							} else {
								// Ocurrió un error al adjuntar el archivo
								console.log('Error al adjuntar el archivo:', asyncResult.error.message);
							}
						}
					);
				// }
			},
			renameFilePopUp() {
				this.submitted = false;
				this.nameFile = this.selectedProduct.name;
				this.renameFileDialog = true;
			},
			hiderenameFilePopUp() {
				this.renameFileDialog = false;
			},
			async saverenameFilePopUp() {
				this.submitted = true;
				this.renameFileDialog = false;
				this.selectedProduct.name = this.nameFile;
				this.$toast.add({severity:'success', summary: 'Datos cambiados', detail:'datos cambiados correctamente', life: 3000});

				await this.renameFile();
			},
			async renameFile() {
				await this.$store.dispatch('project/renameProject', {
					name: this.selectedProduct.name , folder_id: this.selectedProduct.id, isFile: true
				});
			},
			proyectNodeHandler (node) {
				if(!this.nodeProyects.includes(node)){
					this.nodeProyects.push(node);
				}
				console.log(this.nodeProyects.length)
			},
			handleApply(e){
				console.log(e)
				this.mentions.push(e);
				// this.socket.emit('notification', {
				// 	post: this.text,
				// 	to: this.selectedUsers[0],
				// 	who: userId
				// });
				// save in an array and on send emit in this socket
			},
			async sendMessage(e){
				if(this.messageText.length > 0){
					await this.$store.dispatch('notification/saveNotificationsById', {
						publisher: this.getUser.id,
						post: this.messageText,
						reply_to: this.replyId ? parseInt(this.replyId) : null,
						folder_id: this.projectObject.id,
					}).then( (e) => {
						console.log(e);

						this.mentions.forEach((e) => {
							if(this.messageText.includes('@' + e.value)) {
								this.socket.emit('notification', {
									post: this.messageText,
									to: {code: e.id},
									who: this.getUser.id,
								});
							}
						})
						this.mentions = [];




						if (this.replyId) {
							this.messages.find(i => i.id === this.replyId).replies.unshift({id: e.data.id, reply_to: this.replyId, who: this.getUser.name + (this.getUser.lastname !== null ? this.getUser.lastname : ''), post: this.messageText, createddate: new Date() })
						} else {
							this.messages.unshift({id: e.data.id, reply_to: this.replyId, who: this.getUser.name + (this.getUser.lastname !== null ? this.getUser.lastname : ''), post: this.messageText, createddate: new Date(), replies: [] });
						}
						this.messageText = '';
						this.replyId = null;
						this.replyWho = null;
						this.$toast.add({ severity: 'success', summary: 'Mensaje enviado', detail: 'Mensaje enviado correctamente', life: 3000 });
					})
					.catch((e) => {
						console.log(e);
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
					})
					console.log(this.replyId);
				}

				e.preventDefault();
			},
			loadIsEditor(){
				let info = this.accessList.find(x => x.id === this.getUser.id)
				this.isEditor = info ? info.isEditor : false;
			},
			loadIsReader(){
				let info = this.accessList.find(x => x.id === this.getUser.id)
				this.isReader = info ? info.isReader : false;
			},
			loadIsOwner(){
				let info = this.accessList.find(x => x.id === this.getUser.id)
				this.isOwner = info ? info.isOwner : false;
			},
		},
		computed: {
            getAzureList() {
                return this.$store.getters['azure/getTree'].plainRoot.find(x => x.key === this.projectObject.id);
            },
			getUser() {
				return this.$store.getters['user/getCurrentUser'];
			},
			isAdmin() {
				return this.$store.getters['user/isAdmin'];
			},
			myFiles() {
				console.log(this.$store.getters['azure/getUplaodedFiles']);
				return this.$store.getters['azure/getUplaodedFiles'];
			},
			menuModel() {
				return [
					// {label: 'View', icon: 'pi pi-fw pi-search', command: () => console.log(this.selectedProduct)},
					// ilegible!!!
					// ...this.selectedProduct && (this.protectedFiles.find(x => x.files_id === this.selectedProduct.id)?.owner === this.getUser.id || !this.protectedFiles.find(x => x.files_id === this.selectedProduct.id)) ? [{label: 'Reemplazar', icon: 'pi pi-fw pi-save', command: () => this.clickReplace()}] : [],
					...this.selectedProduct ? [{label: 'Añadir a verificaciones', icon: 'pi pi-fw pi-check', command: () => this.linkFileToVerification(this.selectedProduct.id, this.selectedProduct.name)}] : [],
					...this.selectedProduct &&  !this.protectedFiles.find(x => x.files_id === this.selectedProduct.id) && this.myFiles.includes(this.selectedProduct.id)  ? [{label: 'Proteger', icon: 'pi pi-fw pi-shield', command: () => this.protectFile(this.selectedProduct.id, this.getUser.id)}] : [],
					...this.selectedProduct &&  !this.protectedFiles.find(x => x.files_id === this.selectedProduct.id) && (this.isAdmin || this.isOwner || this.projectObject.assignedto.id === this.getUser.id)  ? [{label: 'Proteger para', icon: 'pi pi-fw pi-shield', command: () => this.protectFileTo(this.selectedProduct.id)}] : [],
					...this.selectedProduct && this.protectedFiles.find(x => x.files_id === this.selectedProduct.id)?.owner === this.getUser.id ? [{label: 'Eliminar protección', icon: 'pi pi-fw pi-shield', command: () => this.removeProtection(this.selectedProduct.id)}] : [],
					// [{label: 'No proteger', icon: 'pi pi-fw pi-shield', command: () => this.protectFile(this.selectedProduct.id, this.getUser.id)}] si esta protegido y es tuyo.
					...this.selectedProduct ? [{label: 'Añadir como importante', icon: 'pi pi-fw pi-star', command: () => this.SaveAsImportant(this.selectedProduct.id)}] : [], // o quitar cambiar texto si esta archivo en array
					...this.selectedProduct ? [{label: 'Renombrar', icon: 'pi pi-fw pi-pencil', command: () => this.renameFilePopUp()}] : [],
					...this.selectedProduct ? [{label: 'Eliminar', icon: 'pi pi-fw pi-times', command: () => this.removeFile(this.selectedProduct.id)}] : [],

				];
			}
        },
		watch: {
			// isLoading(value) {
			// 	if (value === false) {
			// 		return
			// 	}

			// 	const timeout = setTimeout(() => {
			// 		this.isLoading= false;
			// 		this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
			// 	}, 8000)

			// 	return () => {
			// 		clearTimeout(timeout)
			// 	}
			// },
			// isLoadingDiv(value) {
			// 	if (value === false) {
			// 		return
			// 	}

			// 	const timeout = setTimeout(() => {
			// 		this.isLoadingDiv= false;
			// 		this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Algo fue mal', life: 3000 });
			// 	}, 8000)

			// 	return () => {
			// 		clearTimeout(timeout)
			// 	}
			// },
			async "$route.params.id"() {
				this.activeTab = 0;
				this.projectObject = {
					// assignedTo: null,
					// createdBy: null,
					// createdDate: null,
					// priority: null,
					// datePrevision: null,
					// dateReal: null,
					// status: null,
					// description: null,
					// statusProject: null,
					// progress: 0,

					id: null,
					idparent: null,
					prinexId: null,
					minutestodone: null,
					type: null,
					creationdate: null,
					name: null,
					deleted: null,
					progress: null,
					createdby: 1,
					assignedto: null,
					assignedtogroup: null,
					estadoproyecto: null,
					realstartdate: null,
					realenddate: null,
					probablystartdate: null,
					probablyenddate: null,
					closedby: null,
					description: null,
					urlcloud: null,
					private: null,
					deprecatedid: null,
					templates_id: null,
					estado: null,
					visibilidad: null,
					planned: false,
					priority: null,
					folder_has_users: []
				};

				this.messages = [];

				if(this.$route.params.id) {

					this.isLoadingDiv = true;

					await this.$store.dispatch('project/getProject', {
					id: this.$route.params.id,
					}).then(
						(data) => {
							if(!data || data.deleted) this.$router.push("/");

							this.projectObject = data
							this.projectObject.createdby = {
								id: data.createdby,
								name: (this.usersList.find(x => x.id === data.createdby)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.createdby)?.lastname ?? '')
							}

							this.projectObject.assignedto = {
								id: data.assignedto,
								name: data.assignedto ? ((this.usersList.find(x => x.id === data.assignedto)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.assignedto).lastname ?? '')) : ''
							}

							this.projectObject.closedby = {
								id: data.closedby,
								name: data.closedby ? ((this.usersList.find(x => x.id === data.closedby)?.name ?? '') + ' ' + (this.usersList.find(x => x.id === data.closedby).lastname ?? '')) : ''
							}
						}
					)

					await this.$store.dispatch('notification/getSendedNotificationsById', { folder_id: this.projectObject.id })
						.then( async(data) => {
							console.log(data)
							data?.collection.forEach(element => {
								this.messages.push({ who: this.usersList.find(x => x.id === element.publisher).name, reply_to: element.reply_to, post: element.post, createddate: element.createddate })
							});
						})
						.catch((e) => {
							console.log(e)
							// this.isLoading = false;
					});

					this.files = [];

					await this.$store.dispatch('azure/getFiles', {
					id: this.projectObject.id
					}).then( async(data) => {
						this.files = data;
					});

					this.verificationsList = [];

					await this.$store.dispatch('verification/getVerifications', { folder_id: this.projectObject.id })
						.then( async(data) => {
							data.data.forEach((e) => {
								this.verificationsList.push(e);
							})
						})
						.catch((e) => {
							console.log(e)
							// this.isLoading = false;
					});

					this.accessList = [];

					await this.$store.dispatch('project/getUsersPermisionInProject', { folder_id: this.$route.params.id })
					.then( async(data) => {

						data.forEach(element => {
							const user = this.usersList.find(val => val.id === element.users_id)
							if(user) {
								this.accessList.push({
									id: element.users_id,
									name: user.name + ' ' + (user.lastname ?? ''),
									isOwner: element.metadata,
									isReader: element.read,
									isEditor: element.write,
									accessType: element.rol,
								})
							}
						});

						if(this.projectObject.private && !this.accessList.find(x => x.id === this.getUser.id)){
							this.$router.push("/");
						}
						// this.isTableLloadingoading = false;
					})
					.catch((e) => {
						console.log(e)
						this.isLoading = false;
					});

					this.items = [];

					if(this.projectObject.type !== 'PROJECT') {
						this.parentsId = [];
						await this.$store.dispatch('project/getParentsProjectById', {
							id: this.projectObject.idparent
						}).then( (data) => {
							data.forEach(e => {
								if(e.type === 'PROJECT') {
									this.home = {label: e.name, to: `/projects/${e.id}`};
									this.projectId = e.id;
								}
								else this.items.push({label: e.name, to: `/projects/${e.id}`, order: e.order})

								this.parentsId.push(e.id);
							})

							this.items.sort(function(a, b) {
								return b.order - a.order;
							})
						});
					}

					document.title = this.projectObject.name;


					this.loadIsEditor();
					this.loadIsReader();
					this.loadIsOwner();

					this.isLoadingDiv = false;
				}
			}
		},
		components: {
			GanttComponent,
			KanbanComponent,
			VerificationView,
			CalendarView,
			AccessProjectView,
			WorkingTimeView,
			// eslint-disable-next-line vue/no-unused-components
			TreeTemplatesComponent,
			// eslint-disable-next-line vue/no-unused-components
			TreeFilesTemplatesComponent,
		}
	}
</script>

<style>
.dropzone
{
    position: fixed; top: 0; left: 0;
    z-index: 9999999999;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.5);
    transition: visibility 175ms, opacity 175ms;
	display: table;
}

.isLoadingDiv {
	/* positions to point 0,0 - required for z-index */
	position: fixed; top: 0; left: 0;
    /* above all elements, even if z-index is used elsewhere
       it can be lowered as needed, but this value surpasses
       all elements when used on YouTube for example. */
    z-index: 9999999999;
    /* takes up 100% of page */
    width: 100%; height: 100%;
    /* dim the page with 50% black background when visible */
    background-color: rgba(0,0,0,0.5);
    /* a nice fade effect, visibility toggles after 175ms, opacity will animate for 175ms. note display:none cannot be animated.  */
    transition: visibility 175ms, opacity 175ms;
	display: table;
}

#textnode {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    transition: font-size 175ms;
}

.spinnerDiv {
	width: 50px;
	height: 50px;
}

.p-chip.high-chip {
    background: rgb(226, 43, 43);
    color: #fff;
}

@keyframes strike{
  0%   { width : 0; }
  100% { width: 100%; }
}

.strike {
  position: relative;
}

.strike::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: black;
  animation-name: strike;
  animation-duration: .25s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.doneHeader {
 background-color: #22C55E;
}

.success-chip{
	background: #22C55E;
    color: #fff;
}

.info-chip{
	background: #3B82F6;
    color: #fff;
}

.fakeUploadButton {
	font-size: 1rem!important;
}

.fakeUploadButton .p-button-icon {
	font-size: 20px!important;
}


.warning-chip{
	background: #F59E0B;
    color: #fff;
}


.danger-chip{
	background: #EF4444;
    color: #fff;
}

.row-accessories {
    background-color: rgba(101, 196, 93, 0.15)!important;
}

.height-0 {
	height: 0px;
}

.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #002680!important;
    }
    40% {
        stroke: #002680!important;
    }
    66% {
        stroke: #002680!important;
    }
    80%,
    90% {
        stroke: #002680!important;
    }
}
</style>