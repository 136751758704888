import { getUserData } from '../../helpers/UserHelper';

export function setUser(state, user) {
  Object.assign(state, user);
  // state.role = getRole(user.roles);
}

export function setUserAuth(state, authState) {
  if (authState.token) {
    console.log(authState)
    const userData = getUserData(authState.token);

    setUser(state, userData);
  }
}
