<template>
  <div class="flex justify-content-center flex-wrap card-container mt-8">
      <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
           <div class="text-center mb-5">
              <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
              <div class="text-900 text-3xl font-medium mb-3" v-if="!submitted">Escribe el email</div>
          </div>
          <form @submit.prevent="getPassword()" class="p-fluid">
              <div class="field mb-3">
                  <div class="p-float-label">
                      <div class="p-float-label p-input-icon-right" v-if="!submitted">
                          <i class="pi pi-envelope" />
                          <InputText id="email" type="text" v-model="email"/>
                          <label for="email">Email</label>
                          
                          <!-- falvarez5@joveconsulting.es -->
                      </div>
                      <div class="flex justify-content-center flex-column" v-else>
                        <h4 class="text-center">Si existe una cuenta con ese email se te enviará un correo con tu nueva contraseña.
                        </h4>
                        <h4 class="text-center"><a href="/login">Volver atrás</a></h4>
                      </div>
                  </div>
              </div>
              <!-- <div class="field-checkbox">
                  <Checkbox id="accept" name="accept" value="Accept" v-model="v$.accept.$model" :class="{'p-invalid':v$.accept.$invalid && submitted}" />
                  <label for="accept" :class="{'p-error': v$.accept.$invalid && submitted}">I agree to the terms and conditions*</label>
              </div> -->
              <Button v-if="!submitted" type="submit" label="Recuperar contraseña" class="mt-2" :loading="isLoading"/>
              <!-- <small v-if="credentialsError" class="p-error">{{ message }}</small> -->
          </form>
      </div>
  </div>
</template>

<script>

import Button from 'primevue/button';

export default {
  name: "ForgotPasswordView",
  components: {
    Button
  },
  data() {
      return {
          email: '',
          submitted: false,
          isLoading: false,
      }
  },
  methods: {
    async getPassword() {

      this.submitted = true;

      this.isLoading = true;
      
      await this.$store.dispatch('auth/sendforgotPassword', {email: this.email})
      .then( async() => {

        this.isLoading = false;
      
      })
      .catch(() => {
        this.isLoading = false;

        // if(e.response.data.message === "Invalid login credentials.") {
        //   this.credentialsError = true;
        // }
        
        // this.resetState();
      });

      

    }
  }
};
</script>