import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
// import AzureFactory from './AzureFactory';

const VERIFICATION = '/api/v1/verification';

class VerificationAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  createVerification(data) {
    const url = `${VERIFICATION}/createVerification`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getVerifications(data) {
    const url = `${VERIFICATION}/getVerifications`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  getVerificationsByUserId(data) {
    const url = `${VERIFICATION}/getVerificationsByUserId`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  createMilestones(data) {
    const url = `${VERIFICATION}/createMilestones`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  updateMilestones(data) {
    const url = `${VERIFICATION}/updateMilestones`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  updateVerification(data) {
    const url = `${VERIFICATION}/updateVerification`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  addLinkVerificationFile(data) {
    const url = `${VERIFICATION}/addLinkVerificationFile`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  removeLinkVerificationFile(data) {
    const url = `${VERIFICATION}/removeLinkVerificationFile`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  deleteVerification(data) {
    const url = `${VERIFICATION}/deleteVerification`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  deleteMilestone(data) {
      const url = `${VERIFICATION}/deleteMilestone`;

      return this.client
        .post(url, data)
        .then((res) => res.data)
        .catch((error) => Promise.reject(error));
    }

  updateMilestonesChecked(data) {
    const url = `${VERIFICATION}/updateMilestonesChecked`;

    return this.client
      .post(url, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }
  
}

export default VerificationAPI;
