/* eslint-disable no-unused-vars */
import tempoAPI from '../../domain/tempo/TempoApi';

export function saveWorkingTime({ commit }, data) {
  const TempoAPI = new tempoAPI();
  const tempoObject = TempoAPI.saveWorkingTime(data);

  return tempoObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getWorkingTime({ commit }, data) {
  const TempoAPI = new tempoAPI();
  const tempoObject = TempoAPI.getWorkingTime(data);

  return tempoObject
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      // commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

