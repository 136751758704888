export function upNotification(state) {
  state.count = state.count + 1;
}

export function setNotification(state, count) {
  state.count = count;
}

export function addNotificationArrayToArray(state, array) {
  state.notificationsList = array;
}