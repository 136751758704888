import {getEvent} from './eventGetters';
import {  getEventList, asignEventToUsers, createEvent } from './eventActions';

const event = {
  namespaced: true,
  state: () => ({
    id: null,
    title: null,
    description: null,
    startDate: null,
    endDate: null,
    owner: null,
    folder: null
  }),
  actions: {
    getEventList,
    asignEventToUsers,
    createEvent
  },
  getters: {
    getEvent
  },
};

export default event;
