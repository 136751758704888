import {getRol} from './rolGetters';
import { getRolList,
  // getGroupsList,
  createGroupWithUsers,
  deleteGroups,
  deleteUserFromGroups,
  getGroupsListByUserId 
} from './rolActions';

const rol = {
  namespaced: true,
  state: () => ({
    id: null,
    name: null
  }),
  actions: {
    getRolList,
    // getGroupsList,
    createGroupWithUsers,
    deleteGroups,
    deleteUserFromGroups,
    getGroupsListByUserId,
  },
  mutations: {},
  getters: {
    getRol
  },
};

export default rol;
