<script>
    import '@fullcalendar/core/vdom'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import FullCalendar from '@fullcalendar/vue3'
    import esLocale from '@fullcalendar/core/locales/es';
    
    export default {
      components: {
        FullCalendar // make the <FullCalendar> tag available
      },
      props: {
        projectID: {
            type: String,
            default: null,
        },
        projectName: {
            type: String,
            default: null,
        },
        noBorder: {
            type: Boolean,
            default: false,
        }
      },
      data() {
        return {
            eventDialog: false,
            checked: true,
            // users
            usersList: null,
			selectedUsers: [],
			filteredUsersList: null,
            //calendar
            calendarOptions: {
                dateClick: this.handleDateClick,
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                selectable:true,
                locale: esLocale,
                events: [],
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                },
                weekends: false, // initial value
                eventClick: this.handleSelect,
            },
            //event Data
            event: {},
            tittle: null,
            description: null,
            dateStart: null,
            dateEnd: null,
            usersNonRegistered: null,
            notEdited: false,
        }
      },
      async mounted() {
		//Crear flujo de session con token y refreshtoken
		await this.$store.dispatch('user/getUsersList')
		.then( async(data) => {
			this.usersList = data.usersList;
		})
		.catch((e) => {
            console.log(e)
			this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});

        await this.$store.dispatch('event/getEventList', { user_id: this.getUser.id, folder_id: this.projectID, email: this.getUser.email }).then( async(data) => {
            data.data.forEach((a) => {
                this.calendarOptions.events.push({title: a.title, start: new Date(a.startdate).toISOString().slice(0, -5), end: new Date(a.enddate).toISOString().slice(0, -5), description: a.description});
            })

            if(data?.microsoft) data.microsoft.forEach((a) => {
				console.log(a)
                //url: a.webLink
                this.calendarOptions.events.push({title: a.subject, allDay: true, start: new Date(a.start.dateTime).toISOString().slice(0, -5), end: new Date(a.end.dateTime).toISOString().slice(0, -5), });
            })
		})
		.catch((e) => {
			console.log(e)
            this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
			this.isLoading = false;
		});

        await this.$store.dispatch('verification/getVerificationsByUserId', { userId: this.getUser.id })
			.then( async(data) => {
				console.log(data)
				// this.verifications.push(...data.data);
				
				data.data.forEach((a) => {
					// textColor: '#000', backgroundColor: '#22c55e', 
                    //url: '/projects/' + a.folder.id
                this.calendarOptions.events.push({allDay: true, title: '(' + (a.folder.deprecatedid) + ') ' + a.title, start: new Date(a.probablydate).toISOString(), end: new Date(a.probablydate).toISOString(), description: a.folder.name});
            })
			})
			.catch((e) => {
				console.log(e)
				this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
				// this.isLoading = false;
		});
	},
    computed: {
        getUser() {
            return this.$store.getters['user/getCurrentUser'];
        },		
    },
        methods:{
            toggleWeekends: function() {
                this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
            },
            formatDate(value) {
                if(value)
                return value.toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
					hour: '2-digit',
                    minute: '2-digit'
                });
            },
            handleDateClick(e){
                this.eventDialog = true;
                this.dateStart = e.date;
                this.dateEnd = e.date;
                console.log(e);
            },
            handleSelect(e){
                console.log(e)
                this.tittle = e.event._def.title;
                this.description = e.event._def.extendedProps.description;
                this.dateStart = e.event._instance.range.start;
                console.log(e.event._instance.range.start)
                this.dateEnd = e.event._instance.range.end;
                this.notEdited = true;
                this.eventDialog = true;
                console.log(this.eventDialog);
            },
            hideEventDialog(){
                this.eventDialog = false;
                this.tittle = '';
                this.description = '';
                this.dateStart = null;
                this.dateEnd = null
                this.notEdited = false;
            },
            searchUser(event) {
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredUsersList = [...this.usersList];
                    }
                    else {
                        this.filteredUsersList = this.usersList.filter((group) => {
                            return group.name.toLowerCase().startsWith(event.query.toLowerCase());
                        });
                    }
                }, 250);
            },
            async saveCalendarEvent(){
                if(!this.tittle || !this.description ){
                    this.$toast.add({severity:'info', summary: 'INFORMACIÓN', detail: 'Debe completar primero todos los campos', life: 3000});
                }else{
                    this.event = {
                        title: this.tittle,
                        description: this.description,
                        selectedUsers: this.selectedUsers,
                        otherUsers: this.usersNonRegistered,
                        startdate: this.dateStart.toISOString(),
                        enddate: this.dateEnd.toISOString(),
                        owner: this.getUser.id,
                        folder: this.projectID,
                    }

                    // puede ser un start o start sin fecha   o bien start and end con o sin fecha 4 tipos

                    //create event
                    await this.$store.dispatch('event/createEvent', { event: this.event }).then(() => {
                        this.$toast.add({severity:'success', summary: 'Evento guardado', detail: 'Evento guardado correctamente', life: 3000});
                        this.calendarOptions.events.push({title: this.tittle, start: this.dateStart.toISOString().slice(0, -5), end: this.dateEnd.toISOString().slice(0, -5)});
                        this.tittle = '';
                        this.description = '';
                        this.dateStart = null;
                        this.dateEnd = null;
                        this.eventDialog = false;
                    }).catch(() => {
                        this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
                        this.tittle = '';
                        this.description = '';
                        this.dateStart = null;
                        this.dateEnd = null;
                        this.eventDialog = false;
                    });
                    
                }
            }
        }
    }
    </script>
    <template>
        <div class="card flex flex-column" v-if="!noBorder">
            <div class="flex align-items-start">
                <ToggleButton v-model="checked" onLabel="Mostrar Fines de Semana" offLabel="Mostrar Lunes a Viernes" onIcon="pi pi-calendar-plus" class="p-button p-component" offIcon="pi pi-calendar" @click="toggleWeekends"/>
            </div>
            <div>
                <FullCalendar defaultView="dayGridMonth" :plugins="calendarPlugins" :options="calendarOptions"
                    :events="events"
                    :selectable="true"
                    @clickDate="handleDateClick"
                    />
                    <!-- @select="handleSelect" -->
                </div>
        </div>
        <div class="flex flex-column" v-else>
            <div class="flex align-items-start">
                <ToggleButton v-model="checked" onLabel="Mostrar Fines de Semana" offLabel="Mostrar Lunes a Viernes" onIcon="pi pi-calendar-plus" class="p-button p-component" offIcon="pi pi-calendar" @click="toggleWeekends"/>
            </div>
            <div>
                <FullCalendar defaultView="dayGridMonth" :plugins="calendarPlugins" :options="calendarOptions"
                    :events="events"
                    :selectable="true"
                    @clickDate="handleDateClick"
                    />
            </div>
        </div>

    <!--Dialog to create an event-->
    <Dialog v-model:visible="eventDialog" :style="{width: '650px'}" :modal="true" class="p-fluid" @after-hide="hideEventDialog">
        <h5 class="test" v-if="!notEdited">Crear Evento para el día {{this.dateStart.toLocaleDateString()}} {{projectName ? `para ${projectName}` : ''}}</h5>
        <h5 class="test" else>Información del evento {{ tittle }} {{projectName ? `para ${projectName}` : ''}}</h5>
        <div class="align-items-center justify-content-center">
            <div class="mg2-rem">
                <span class="p-float-label" v-if="!notEdited">
                    <InputText id="tittle" type="text" v-model="tittle" />
                    <label for="tittle">Nombre del evento</label>
                </span>
                <span class="p-float-label" v-else>
                    <p class="font-bold">Nombre del evento</p>
                    <p>{{  tittle  }}</p>
                </span>
            </div>
            <div class="mg2-rem">
                <span v-if="!notEdited">
                    <label class="description-label" for="description">Descripción del evento</label>
                    <Textarea v-model="description" :autoResize="true" rows="5" cols="30" />
                </span>
                <span class="p-float-label" v-else>
                    <p class="font-bold">Descripción del evento</p>
                    <p>{{  description  }}</p>
                </span>
            </div>
            <div class="mg3-2-rem">
                <span class="p-float-label" v-if="!notEdited">
                    <Calendar id="dateStart" v-model="dateStart" :showIcon="true" dateFormat="d/m/yy" :showTime="true"/>
                    <label for="dateStart">Fecha Inicio</label>
                </span>
                <span class="p-float-label" v-else>
                    <p class="font-bold">Fecha Inicio</p>
                    <p>{{  formatDate(new Date(dateStart))  }}</p>
                </span>
            </div>
            <div class="mg3-2-rem">
                <span class="p-float-label" v-if="!notEdited">
                    <Calendar id="dateEnd" v-model="dateEnd" :showIcon="true" dateFormat="d/m/yy" :showTime="true"/>
                    <label for="dateEnd">Fecha Fin</label>
                </span>
                <span class="p-float-label" v-else>
                    <p class="font-bold">Fecha Fin</p>
                    <p>{{  formatDate(new Date(dateEnd))  }}</p>
                </span>
            </div>
        </div>
            <template #footer v-if="!notEdited">
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEventDialog"/>
                <Button label="Crear Evento" icon="pi pi-check" class="p-button-text" @click="saveCalendarEvent"/>
            </template>
    </Dialog>
    </template>

    <style>
        h5.test {
        text-align-last: center;
        margin: 1rem 0rem 3rem;
        color: #fff;
        background: #002680;
        padding: 0.5rem;
        border-radius: 5px;
    }
    </style>