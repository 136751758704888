<template>
   <DataTable :value="times" :scrollable="true" scrollHeight="400px">
        <Column header="Quién">
            <template #body="slotProps">
                {{slotProps.data.who}}
            </template>
        </Column>
        <Column header="Dónde">
            <template #body="slotProps">
                {{slotProps.data.project}}
            </template>
        </Column>
        <Column header="Fecha inicio">
            <template #body="slotProps">
                {{formatDate(new Date(slotProps.data.from))}}
            </template>
        </Column>
        <Column header="Fecha finalización">
            <template #body="slotProps">
                {{formatDate(new Date(slotProps.data.to))}}
            </template>
        </Column>
        <Column header="Horas imputadas">
            <template #body="slotProps">
                {{slotProps.data.hours}} Hrs {{slotProps.data.minutes}} min
            </template>
        </Column>
    </DataTable>
    <div class="flex justify-content-end mr-5 mt-5">
        <p><strong>Totales:</strong> {{totalHours()}}</p>
    </div>
</template>

<script>

    export default {
        props: {
            projectId: {
                type: String,
                default: null
            },
            usersList: {
                type: Array,
                default: null,
            },
            newTime: {
                type: Object || null,
                default: null
            },
            type: {
                type: String,
                default: 'PROJECT'
            }
        },
		data() {
			return {
              times: [],
              isLoading: false,
            }
        },
        async mounted() {

            await this.$store.dispatch('tempo/getWorkingTime', { folder_id: this.projectId, userId: this.getUser.id, project: this.type })
			.then( (data) => {
				data.data.forEach(e => {
                    let userName = this.usersList.find(x => x.id === e.users_id);
                    let time = this.getTimeDiff(e.starttime, e.endtime)
                    this.times.push({
                        who: userName.name + ' ' + (userName.lastname ?? ''),
                        from: e.starttime,
                        to: e.endtime,
                        hours: time.hours,
                        minutes: time.minutes,
                        project: e.folder.name
                    })
                })
			})
			.catch((e) => {
				console.log(e)
				this.isLoading = false;
			});

        },
        methods: {
            totalHours() {
				let totalHours = 0;
                let totalMinutes = 0;

				for(let time of this.times) {
					totalHours += time.hours;
                    totalMinutes += time.minutes;
				}

                totalHours += Math.floor((totalMinutes / 60));

                totalMinutes = totalMinutes % 60;

				return totalHours.toString() + 'Hrs' + ' ' + totalMinutes.toString() + 'min' ; // is hours summary
			},
            formatDate(value) {
				return value.toLocaleDateString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
				});
			},
            getTimeDiff(start, end) {
				let startTime  = new Date(start);
				let endTime    = new Date(end);

				let diff = endTime - startTime;
				let diffDays = Math.floor(diff / 86400000); // days
				let diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
				let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes

				// console.log(diff/(1000*60*60) );
				
				return {minutes: diffMins , hours: ((diffDays * 24) + diffHrs)};
			},
        },
        computed: {
            getUser() {
				return this.$store.getters['user/getCurrentUser'];
			},
        },
        watch: {
            newTime(newValue) {
                this.times.push(newValue);
            }
        },
        components: {
        }

    }
</script>