import {getPermission} from './permissionGetters';
import {  getPermissionList, asignPermissionToUsers, deleteUserFromPermissions, getPermissionListByUserId } from './permissionActions';

const permission = {
  namespaced: true,
  state: () => ({
    id: null,
    name: null
  }),
  actions: {
    getPermissionList,
    asignPermissionToUsers,
    deleteUserFromPermissions,
    getPermissionListByUserId,
  },
  getters: {
    getPermission
  },
};

export default permission;
