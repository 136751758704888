<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<div class="flex justify-content-between align-items-center mb-5">
					<h5>Proyecto {{this.projectId}}</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				projectId: null,
			}
		},
		mounted() {
			this.projectId = this.$route.params.id;
			// this.$http.get('//api.trax.dev/tracks/' + itemId, function (data) {
			// 	this.$set('track', data.track)
			// }
		},
	}
</script>