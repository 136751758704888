
<template>
	<div class="surface-section p-5 mb-4 card">
		<DataTable v-model:filters="filters" ref="dt" :value="customers" paginator showGridlines :rows="10" dataKey="id"
        filterDisplay="menu" :loading="loading" :globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">
    <template #header>
        <div class="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Limpiar filtros" outlined @click="clearFilter()" />
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText class="w-full" v-model="filters['global'].value" placeholder="Buscar..." />
            </span>
			<Button icon="pi pi-external-link" label="Exportar datos" @click="exportCSV($event)" />
        </div>
    </template>
    <template #empty> Datos no encontrados. </template>
    <template #loading> Cargando datos. </template>
    <Column field="title" header="Título" style="min-width: 12rem">
        <template #body="{ data }">
            {{ data.title }}
        </template>
        <template #filter="{ filterModel }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Buscar por" />
        </template>
    </Column>
	<Column field="description" header="Descripción" style="min-width: 12rem">
        <template #body="{ data }">
            {{ data.description }}
        </template>
        <template #filter="{ filterModel }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Buscar por" />
        </template>
    </Column>
    <Column header="Creada por" filterField="representative" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
        <template #body="{ data }">
            <div class="flex align-items-center gap-2">
                <span>{{ data.representative.name }}</span>
            </div>
        </template>
        <template #filter="{ filterModel }">
            <MultiSelect v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Algo" class="p-column-filter">
                <template #option="slotProps">
                    <div class="flex align-items-center gap-2">
                        <img :alt="slotProps.option.name" :src="`https://primefaces.org/cdn/primevue/images/avatar/${slotProps.option.image}`" style="width: 32px" />
                        <span>{{ slotProps.option.name }}</span>
                    </div>
                </template>
            </MultiSelect>
        </template>
    </Column>
    <Column header="Creada el" filterField="date" dataType="date" style="min-width: 10rem">
        <template #body="{ data }">
            {{ formatDate(data.date) }}
        </template>
        <template #filter="{ filterModel }">
            <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
        </template>
    </Column>
    <Column header="Estado" field="status" :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
        <template #body="{ data }">
            <Tag :value="data.status" :severity="getSeverity(data.status)" />
        </template>
        <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Selecciona uno" class="p-column-filter" showClear>
                <template #option="slotProps">
                    <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                </template>
            </Dropdown>
        </template>
    </Column>
    <Column field="verified" header="Aceptada" dataType="boolean" bodyClass="text-center" style="min-width: 8rem">
        <template #body="{ data }">
            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': data.verified, 'pi-times-circle text-red-500': !data.verified }"></i>
        </template>
        <template #filter="{ filterModel }">
            <label for="verified-filter" class="font-bold"> Aceptada </label>
            <TriStateCheckbox v-model="filterModel.value" inputId="verified-filter" />
        </template>
    </Column>
</DataTable>
	</div>
</template>


<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
	data() {
		return {
			customers: null,
			filters: {},
			representatives: [
				{ name: 'Amy Elsner', image: 'amyelsner.png' },
				{ name: 'Anna Fali', image: 'annafali.png' },
				{ name: 'Asiya Javayant', image: 'asiyajavayant.png' },
				{ name: 'Bernardo Dominic', image: 'bernardodominic.png' },
				{ name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
				{ name: 'Ioni Bowcher', image: 'ionibowcher.png' },
				{ name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
				{ name: 'Onyama Limba', image: 'onyamalimba.png' },
				{ name: 'Stephen Shaw', image: 'stephenshaw.png' },
				{ name: 'XuXue Feng', image: 'xuxuefeng.png' }
			],
			statuses: ['unqualified', 'status', 'new', 'negotiation', 'renewal', 'proposal'],
			loading: true,
		}
	},
	created() {
		this.initFilters();
		this.customers = [
			{
				title: 'Error login',
				description: 'Al logear no entra en la app',
				representative: { name: 'testing' },
				date: '10/06/2023',
				status: 'status',
				activity: 50,	
				balance: 10000,
				verified: true,
			},
			{
				title: 'Crear tabla precios',
				description: 'Sería añadir una tabla con los diferentes precios',
				representative: { name: 'testing' },
				date: '10/06/2023',
				status: 'status',
				activity: 22,	
				balance: 10000,
				verified: true,
			},
			{
				title: 'Error en perfil',
				description: 'no se muestra la foto de perfil correctamente falla y sale todo en negro ahora no va como deberia y esto da mas fallos al cambiar datos del usuario tira error 500 ahora viene texto sin sentido: afsfasfsdfs d sgljleio n oo nonl nlnol nl nln  ',
				representative: { name: 'testaaing' },
				date: '10/06/2023',
				status: 'new',
				activity: 0,	
				balance: 10000,
				verified: false,
			}
		]
	},
	mounted() {
		this.loading = false;
		
	},

	methods: {
		initFilters () {
			this.filters = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				representative: { value: null, matchMode: FilterMatchMode.IN },
				date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				activity: { value: [0, 100], matchMode: FilterMatchMode.BETWEEN },
				verified: { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},
		formatDate (value) {
			return new Date(value).toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric'
			});
		},
		formatCurrency (value){
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		clearFilter () {
			this.initFilters();
		},
		cgetCustomers (data) {
			return [...(data || [])].map((d) => {
				d.date = new Date(d.date);

				return d;
			});
		},
		getSeverity (status){
			switch (status) {
				case 'unqualified':
					return 'danger';

				case 'qualified':
					return 'success';

				case 'new':
					return 'info';

				case 'negotiation':
					return 'warning';

				case 'renewal':
					return null;
			}
		},
		exportCSV () {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
