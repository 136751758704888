import {
  addNotification,
  getListNotifications,
  getNewNotifications,
  addNotificationToArrayAction,
  getSendedNotifications,
  getSendedNotificationsById,
  saveNotificationsById,
  getNotificationsCount,
  changeToRead,
} from './notificationActions';

import { getCount, getListArrayNotifications } from './notificationGetters';
import { upNotification , setNotification, addNotificationArrayToArray } from './notificationMutations';

const notification = {
  namespaced: true,
  state: () => ({
    socket: null,
    count: 0,
    notificationsList: [],
  }),
  mutations: {
    upNotification,
    setNotification,
    addNotificationArrayToArray,
  },
  actions: {
    addNotification,
    getListNotifications,
    getNewNotifications,
    changeToRead,
    addNotificationToArrayAction,
    getSendedNotifications,
    getSendedNotificationsById,
    saveNotificationsById,
    getNotificationsCount,
  },
  getters: {
    getCount,
    getListArrayNotifications,
  },
};

export default notification;
