<template>
    <div v-if="hasProjects">
      <div class="flex justify-content-between">
        <div class="flex justify-content-end align-items-stretch align-self-center align-items-center justify-content-center">
          <Calendar v-model="dates" view="month" dateFormat="mm/yy" inputId="range" selectionMode="range" :manualInput="false" class="mb-5"/>
        </div>
        <div v-if="isEditor || isOwner" class="flex justify-content-end align-items-stretch align-self-center align-items-center justify-content-center">
          <Button label="Guardar información" icon="pi pi-copy" iconPos="left" class="p-button-sm create-project mr-3" @click="saveInformation" />
        </div>
      </div>
      
      <g-gantt-chart
      :chart-start="chartStart"
      :chart-end="chartEnd"
        precision="month"
        bar-start="myBeginDate"
        bar-end="myEndDate"
        row-height="70"
        color-scheme="material-blue"
      >
        <g-gantt-row v-for="(element, id) in rowList"
          :label="element.label"
          :bars="element.row"
          :key="id"
        />
      </g-gantt-chart>
    </div>
    <h2 v-else>No tienes datos para gestionar en el Kanban</h2>
  </template>
  
  <script>

  export default {
    setup() {

      let d = new Date(new Date().setDate(new Date().getDate() - 60));
      let d2 = new Date(new Date().setDate(new Date().getDate() + 365));

      const chartStart = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

      const chartEnd = d2.getFullYear() + "-" + ("0"+(d2.getMonth()+1)).slice(-2) + "-" + ("0" + d2.getDate()).slice(-2)
       + " " + ("0" + d2.getHours()).slice(-2) + ":" + ("0" + d2.getMinutes()).slice(-2);

      return {
        chartStart,
        chartEnd,
      };
    },
    props: {
      idparent: {
          type: String,
          default: null,
      },
      isOwner: {
          type: Boolean,
          default: false,
      },
      isEditor: {
          type: Boolean,
          default: false,
      },
      isReader: {
          type: Boolean,
          default: false,
      },
    },
    async mounted() {
      let idList = [];

      await this.$store.dispatch('azure/getChildTree', {id: this.idparent, users_id: this.getUser.id, onlyID: true})
      .then( async(data) => {
        data.forEach(e => idList.push(e));
        this.isLoading = false;
      })
      .catch(() => {
        // console.log(e)
        this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
        this.isLoading = false;
      });
      await this.$store.dispatch('project/getProjectDays', {
				idList
			})
			.then( async(data) => {
        data.forEach((e) => {
          if(e.probablystartdate && e.probablyenddate) { 
            this.rowList.push({
              label: e.name,
              row: [{
                myBeginDate: this.formatDay(e.probablystartdate),
                myEndDate: this.formatDay(e.probablyenddate, "end"),
                ganttBarConfig: {
                  id: e.id,
                  label: e.name,
                  immobile: this.isReader,
                  hasHandles: (this.isEditor || this.isOwner),
                  style: {     // arbitrary CSS styling for your bar
                    background: "#002680",
                    // borderRadius: "20px",
                    color: "white"
                  }
                }
              }]
            });
          }
        })
			}).catch(() => {
        this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
      });

      if(this.rowList.length > 0) this.hasProjects = true;

      console.log(this.rowList);
      // this.data.forEach((e) => {
      //   console.log(e)
      //   this.list.push({name: e.label, id: e.key});
      // });
    },
    methods: {
      async saveInformation () {
        let projects = [];

        this.rowList.forEach(data => {
          projects.push({ id: data.row[0].ganttBarConfig.id, start: new Date(data.row[0].myBeginDate), end: new Date(data.row[0].myEndDate) })
        })
        await this.$store.dispatch('project/saveProjectProbablyDate', {
					projects
				}).catch(() => {
          this.$toast.add({severity:'error', summary: 'Error', detail: 'Algo fue mal', life: 3000});
        });
      },
      formatDay(date, limit = "start") {
        let d = new Date(date);

        const day = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
        ("0" + d.getDate()).slice(-2) + " " + (limit === "start" ? '00:00' : '23:59');

        return day;
      }
    },
    computed: {
        getUser() {
          return this.$store.getters['user/getCurrentUser'];
        },
    },
    watch: {
      dates(newValue) {
        if(newValue[0] && newValue[1]) {
          let d = new Date(newValue[0]);
          let d2 = new Date(newValue[1]);

          this.chartStart = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
          ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

          this.chartEnd = d2.getFullYear() + "-" + ("0"+(d2.getMonth()+1)).slice(-2) + "-" + ("0" + d2.getDate()).slice(-2)
          + " " + ("0" + d2.getHours()).slice(-2) + ":" + ("0" + d2.getMinutes()).slice(-2);
        }
      }
    },
    data() {
      return {
        hasProjects: false,
        dates: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date(new Date().setDate(new Date().getDate() + 365))],
        rowList: [],
      };
    },
  }
  </script>