<template>
    <div class="grid" v-if="hasProjects">
      <div class="col-12 mb-5 flex md:flex-row flex-column">
        <div v-for="(el, index) in colors" :key="index"> 
          <div v-if="el.show" class="mr-4">
            <div :class='`box bg-${el.color} mr-2`'></div> {{MainstatusOptions.find(x => x.code === el.code)?.name}}
          </div> 
        </div>
      </div>
      <div class="col-12 xl:col-4 border-none xl:border-right-1 border-solid px-1 xl:px-5 todo-box">
        <h3 class="text-center">Pendientes</h3>
  
        <draggable
            class="list-group"
            @add="toOpen"
            :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
            }"
            v-model="listToDo"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            group="a" 
            item-key="name"
            v-if="isOwner || isEditor"
        >
          <template #item="{ element }">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
              <template #title> 
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
              </template>
                <template #content>
                    <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                        {{ element.name }}
                    </p>
                </template>
                <template #footer><Dropdown v-model="element.code" @change="ChangeStatus(element)" :options="ToDoOptions" optionValue="code" optionLabel="name" placeholder="Selecciona estado" /></template>
            </Card>
          </template>
  
          <template #header>
            <div class="btn-group list-group-item my-4" role="group">
            </div>
          </template>
        </draggable>
        <div class="list-group" v-else>
          <div v-for="(element, index) in listToDo" :key="index">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
                <template #title> 
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
                </template>
                  <template #content>
                      <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                          {{ element.name }}
                      </p>
                  </template>
                  <template #footer>
                    <p class="font-bold text-white mb-1">Estado</p>
                    <p class="text-white">
                        {{ ToDoOptions.find(x => x.code === element.code)?.name }}
                    </p>
                  </template>
              </Card>
          </div>
        </div>
      </div>

      <div class="col-12 xl:col-4 border-none xl:border-right-1 border-solid px-1 xl:px-5 in-process-box">
        <h3 class="text-center">En proceso</h3>
  
        <draggable 
          class="list-group"
            @add="toInProcess"
            :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
            }"
             v-model="listInProcess"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            group="a" 
            item-key="name"
            v-if="isOwner || isEditor"
            >
            <template #item="{ element }">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
              <template #title> 
                <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                        {{ element.name }}
                </p>
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
              </template>
                <!-- <template #content>
                    <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                        {{ element.name }}
                    </p>
                </template> -->
                <template #footer><Dropdown v-model="element.code" @change="ChangeStatus(element)" :options="InProcessOptions" optionValue="code" optionLabel="name" placeholder="Selecciona estado" /></template>
            </Card>
          </template>
  
          <template #header>
            <div
              class="btn-group list-group-item my-4"
              role="group"
              aria-label="Basic example"
            >
            </div>
          </template>
        </draggable>
        <div class="list-group" v-else>
          <div v-for="(element, index) in listInProcess" :key="index">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
                <template #title> 
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
                </template>
                  <template #content>
                      <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                          {{ element.name }}
                      </p>
                  </template>
                  <template #footer>
                    <p class="font-bold text-white mb-1">Estado</p>
                    <p class="text-white">
                        {{ InProcessOptions.find(x => x.code === element.code)?.name }}
                    </p>
                  </template>
              </Card>
          </div>
        </div>
      </div>
  
      <div class="col-12 xl:col-4 px-1 xl:px-5 done-box">
        <h3 class="text-center">Terminadas</h3>
  
        <draggable 
          class="list-group"
            @add="toClosed"
            :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
            }"
              v-model="listDone"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            group="a"
            item-key="name"
            v-if="isOwner || isEditor"
            >
            <template #item="{ element }">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
              <template #title> 
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
              </template>
                <template #content>
                    <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                        {{ element.name }}
                    </p>
                </template>
                <template #footer><Dropdown v-model="element.code" @change="ChangeStatus(element)" :options="DoneOptions" optionValue="code" optionLabel="name" placeholder="Selecciona estado" /></template>
            </Card>
          </template>
  
          <template #header>
            <div
              class="btn-group list-group-item my-4"
              role="group"
              aria-label="Basic example"
            >
            </div>
          </template>
        </draggable>
        <div class="list-group" v-else>
          <div v-for="(element, index) in listDone" :key="index">
            <Card @click="goToProject(element.id)" :class="`list-group-item cardTask bg-${colors.find(x => x.code === element.code).color}`">
                <template #title> 
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-if="!element?.probablystartdate || !element?.probablyenddate"> Sin fecha prevista</span>
                  <span :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` " v-else> Fecha: {{new Date(element?.probablystartdate).toLocaleDateString("es-ES")}} - {{new Date(element?.probablyenddate).toLocaleDateString("es-ES")}}</span>  
                </template>
                  <template #content>
                      <p :class="`${colors.find(x => x.code === element.code)?.text ?? ''}` ">
                          {{ element.name }}
                      </p>
                  </template>
                  <template #footer>
                    <p class="font-bold text-white mb-1">Estado</p>
                    <p class="text-white">
                        {{ DoneOptions.find(x => x.code === element.code)?.name }}
                    </p>
                  </template>
              </Card>
          </div>
        </div>
      </div>
    </div>
    <h2 v-else>No tienes datos para gestionar en el Kanban</h2>
    <Toast />
    
  </template>
  
  <script>
  import draggable from "vuedraggable";
  // let id = 1;
  export default {
    name: "two-list-headerslots",
    display: "Two list header slot",
    order: 14,
    components: {
      draggable
    },
    props: {
      idparent: {
          type: String,
          default: null,
      },
      isOwner: {
          type: Boolean,
          default: false,
      },
      isEditor: {
          type: Boolean,
          default: false,
      },
      isReader: {
          type: Boolean,
          default: false,
      },
    },
    async mounted() {
      let idList = [];

      await this.$store.dispatch('azure/getChildTree', {id: this.idparent, users_id: this.getUser.id, onlyID: true})
      .then( async(data) => {
        data.forEach(e => idList.push(e));
        // console.log(data)
        this.isLoading = false;
      })
      .catch((e) => {
        console.log(e)
        this.isLoading = false;
      });
      // console.log(this.data)
      await this.$store.dispatch('project/getProjectsStatus', {
				idList
			})
			.then( async(data) => {
				this.projectInformation = data;
			});

      this.projectInformation.forEach((e) => {
        if(e?.estado === "started" || e?.estado === "started_out_of_time" || e?.estado === "not_closed_on_time") this.listInProcess.push({name: e.name, id: e.id, code: e.estado, probablyenddate: e.probablyenddate, probablystartdate: e.probablystartdate});
        else if(e?.estado === "not_started_on_time" || e?.estado === "not_started") this.listToDo.push({name: e.name, id: e.id, code: e.estado, probablyenddate: e.probablyenddate, probablystartdate: e.probablystartdate});
        else if(e?.estado === "closed" || e?.estado === "closed_out_of_time") this.listDone.push({name: e.name, id: e.id, code: e.estado, probablyenddate: e.probablyenddate, probablystartdate: e.probablystartdate});
      });

      if(this.listInProcess.length > 0 || this.listToDo.length > 0 || this.listDone.length > 0) this.hasProjects = true;
    },
    data() {
      return {
        hasProjects: false,
        listToDo: [],
        listInProcess: [],
        listDone: [],
        drag: false,
        projectInformation: null,
        DoneOptions: [
          {name: 'Cerrado', code: 'closed'}, // Closed
					{name: 'Cerrado fuera de tiempo', code: 'closed_out_of_time'}, // Closed
        ],
        ToDoOptions: [
          {name: 'No empezado', code: 'not_started'}, // Open
					{name: 'No empezado a tiempo', code: 'not_started_on_time'}, // Open
        ],
        InProcessOptions: [
          {name: 'Empezado', code: 'started'}, // In_process
					{name: 'Empezado fuera de tiempo', code: 'started_out_of_time'}, // In_process
					{name: 'No cerrado a tiempo', code: 'not_closed_on_time'}, // In_process
        ],
        colors: [
        {
          code: 'critical',
					color: 'white',
          text: false,
          show: false,
				},
        {
          code: 'unplanned',
					color: 'white',
          text: false,
          show: false,
				},
				{
          code: 'not_started',
					color: 'cyan-500',
          text: 'text-white',
          show: true,
				},
        {
          code: 'not_started_on_time',
					color: 'blue-500',
          text: 'text-white',
          show: true,
				},
        {
          code: 'started',
					color: 'green-300',
          text: 'text-white',
          show: true,
				},
        {
          code: 'started_out_of_time',
					color: 'green-500',
          text: 'text-white',
          show: true,
				},
        {
          code: 'not_closed_on_time',
					color: 'orange-300',
          text: 'text-white',
          show: true,
				},
        {
          code: 'closed',
					color: 'gray-500',
          text: 'text-white',
          show: true,
				},
        {
          code: 'closed_out_of_time',
					color: 'gray-700',
          text: 'text-white',
          show: true,
				},
			],
        MainstatusOptions: [
					{name: 'No planeado', code: 'unplanned'}, // unplanned
					{name: 'No empezado', code: 'not_started'}, // Open
					{name: 'No empezado a tiempo', code: 'not_started_on_time'}, // Open
					{name: 'Empezado', code: 'started'}, // In_process
					{name: 'Empezado fuera de tiempo', code: 'started_out_of_time'}, // In_process
					{name: 'No cerrado a tiempo', code: 'not_closed_on_time'}, // In_process
					{name: 'Cerrado', code: 'closed'}, // Closed
					{name: 'Cerrado fuera de tiempo', code: 'closed_out_of_time'}, // Closed
					{name: 'Crítico', code: 'critical'}, // unplanned
				],
      };
    },
    methods: {
      toOpen: async function(e) {
        await this.$store.dispatch('project/changeProjectsStatus', {
          id: this.listToDo[e.newIndex - 1].id, estado: 'not_started'
        })
        this.listToDo[e.newIndex - 1].code = 'not_started';
      },
      toInProcess: async function(e) {
        await this.$store.dispatch('project/changeProjectsStatus', {
          id: this.listInProcess[e.newIndex - 1].id, estado: 'started'
        })
        this.listInProcess[e.newIndex - 1].code = 'started';
      },
      toClosed: async function(e) {
        await this.$store.dispatch('project/changeProjectsStatus', {
          id: this.listDone[e.newIndex - 1].id, estado: 'closed'
        })
        this.listDone[e.newIndex - 1].code = 'closed';
      },
      goToProject(id){
        this.$router.push(
          { path: `/projects/${id}` }
        ); 
      },
      ChangeStatus: async function(element) {
        await this.$store.dispatch('project/changeProjectsStatus', {
          id: element.id, estado: element.code
        })
      },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        getUser() {
          return this.$store.getters['user/getCurrentUser'];
        },
    }
  };
  </script>
 <style>
 .box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
}
.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 200px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
.done-box {
    background: url('../../public/images/tiny-checkers.png');
    /* border: 1px dashed;
    border-radius: 4px; */
    padding: 24px;
    background-color: #f6fbf6;
}
.in-process-box{
    background: url('../../public/images/tiny-checkers.png');
    /* border: 1px dashed;
    border-radius: 4px; */
    padding: 24px;
    background-color: #f4fafe;
}
.todo-box{
    background: url('../../public/images/tiny-checkers.png');
    /* border: 1px dashed;
    border-radius: 4px; */
    padding: 24px;
    background-color: #fffcf5;
}
.cardTask{
    width: 18rem; 
    margin-bottom: 2em;
}
@media (max-width: 600px) {
    .cardTask{
        width: 18rem; /* 12 */
        margin-bottom: 1em;
    }
}
</style>