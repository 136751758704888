import {
  saveWorkingTime,
  getWorkingTime,
} from './tempoActions';

const tempo = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    saveWorkingTime,
    getWorkingTime,
  },
  getters: {
  },
};

export default tempo;
