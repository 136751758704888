<template>
    <div class="flex justify-content-between align-items-center mb-5">
        <h3 class="mt-2">Lista de verificación </h3> 
        <!-- <font-awesome-icon v-tooltip="'Click to proceed'" icon="fa-solid fa-circle-question" /> -->
    </div>
    <div class="flex justify-content-end mb-3" v-if="isOwner || isEditor">
        <Button label="Nueva verificación" icon="pi pi-plus" iconPos="left" class="p-button-sm create-project" @click="openVerificationModal" />
    </div>
    <input type="file" ref="uploadVerificationFile" style="display:none;">
    <div v-for="(element, index) in verificationsList" :key="index" class="mb-4">
        <Panel :class="`${(element.probablydate && (new Date(element.probablydate) < new Date()) && element.status !== 'Closed') ? 'orange-500' : (verificationColors.find(x => x.code === element.status)?.color ?? '')} text-white`" :header="`${element.title} ${element.probablydate ? ' - Fecha estimada: ' + (new Date(element.probablydate).toLocaleDateString('es-ES')) : ''} ${element.assignedto ? ('&nbsp;'.repeat(100) + (userslist.find(x => x.code === element.assignedto).name)) : ''}`" :toggleable="true">
            <template #icons v-if="isOwner || isEditor">
                <button :class="`p-panel-header-icon p-plus mr-2 text-white`" @click="editVerification(element)">
                    <span class="pi pi-pencil"></span>
                </button>
                <button :class="`p-panel-header-icon p-plus mr-2 text-white`" @click="addTasks(element.id)">
                    <span class="pi pi-plus-circle"></span>
                </button>
                <button :class="`p-panel-header-icon p-link mr-2 text-white`" @click="deleteVerification(index)">
                    <span class="pi pi-trash"></span>
                </button>
                <!-- <Menu ref="menu" id="config_menu" :model="items" :popup="true" /> -->
            </template>
            <!-- <div v-if="changeStep[index]" class="flex justify-content-end mb-3">
                <Button label="Nueva tarea" icon="pi pi-plus" iconPos="left" class="p-button-sm create-project" @click="addTasks(element.id)" />
            </div> -->
            <!-- <draggable 
            :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
            }"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            :list="element.milestones" class="list-group" group="a" item-key="name" handle=".handle" v-if="changeStep[index]"
            >
                <template #item="{ task }"> -->
                    <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                    <!-- <div class="m-4" v-for="(task, index2) in element.milestones" :key="`tasks-${index2}`" v-if="changeStep[index]"> -->
                        
                    <!-- <i class="pi pi-cloud-upload mr-3" @click="$refs.uploadVerificationFile[0].click()"></i> -->
                        <!-- <i class="pi pi-align-justify list-group-item handle p-0 mr-3"></i> -->

                        <!-- <Checkbox v-model="cities" :inputId="`city${element.id}`" name="city" :value="`city${element.id}`" /> -->
                        <!-- <label for="city1" :class="`${ cities.includes('city1') ? 'strike' : ''}`">Paso 1</label> -->
                        <!-- <span class="text">{{ element.name }} </span> -->

                        <!-- <input type="text" class="form-control" v-model="task.title" />

                        <i class="pi pi-trash close" @click="deleteMilestone(index, index2, task.id)"></i>
                </div> -->
                <!-- </template>
            </draggable> -->
            <div class="field-checkbox" v-for="(item, index3) in element.milestones" :key="index3">
                <div class="flex flex-row">
                    <Checkbox v-if="isOwner || isEditor" class="mr-4" @change="changeSatusMilestone(index, index3, item.id)" v-model="item.checked" :inputId="`itemTasks-${item.id}`" name="tasks" :binary="true" />
                    <Checkbox v-else v-model="item.checked" class="mr-4" :inputId="`itemTasks-${item.id}`" :disabled="true" name="tasks" :binary="true"  />     
                    <p>{{ item.title }}</p>
                    <span v-if="isOwner || isEditor" @click="editMilestone(index, index3, item)" class="pi pi-pencil mr-2 ml-4"></span>
                    <span v-if="isOwner || isEditor" @click="deleteMilestone(index, index3, item.id)" class="pi pi-trash"></span>
                    <!-- <Inplace class="ml-2" :closable="true">
                        <template #display>
                            <label :for="`itemTasks-${item.id}`"  :class="`${ list.includes(`itemTasks-${item.id}`) ? 'strike' : ''}`">{{item.title}}</label>
                        </template>
                        <template #content>
                            <InputText class="" v-model="item.title" autofocus />
                        </template>
                    </Inplace> -->
                    <Chip v-if="item.file_title" :label="item.file_title" icon="pi pi-file" :class="`ml-2 ${item.checked ? 'color-chip-complete' : ''}`"/>
                </div>
            </div>
        </Panel>
    </div>

    <!-- Menu Verificacion Start -->
		<Dialog v-model:visible="verificationDialog" :style="{width: '450px'}" header="Crear nueva verificación" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Título</label>
				<InputText v-model="verificationTitle" id="name" required="true" autofocus :class="{'p-invalid': submitted}" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideVerification"/>
				<Button label="Guardar" :disabled="verificationTitle.length < 3" :loading="loadingButton" icon="pi pi-check" class="p-button-text" @click="saveVerification" />
			</template>
		</Dialog>
    <!-- Menu Verificacion End -->

   <!-- EDIT -->


   <Dialog v-model:visible="editVerificationDialog" :style="{width: '450px'}" header="Editar verificación" :modal="true" class="p-fluid">
        <div class="field">
            <label for="name">Descripción</label>
            <InputText v-model="verificationEditTitle" id="name" required="true" autofocus :class="{'p-invalid': submitted}" />
            <!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
        </div>
        <!-- <div class="field">
            <label for="time">Fecha estimada</label>
            <Calendar id="time" v-model="date" />
        </div>
        <div class="field">
            <label for="assignedto">Asignar a</label>
            <Dropdown v-model="selectedUsers" :options="userslist" optionLabel="name" id="assignedto" placeholder="Selecciona usuarios" required="true" autofocus :class="{'p-invalid': submitted}" />
        </div> -->
        <div class="field">
            <label for="status">Estado</label>
            <Dropdown v-model="statusOptionsSelected" :options="statusOptions" optionLabel="label" id="status" placeholder="Selecciona estado" required="true" autofocus >
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    <div :class="slotProps.value.color" style="height: 25px;width: 25px;margin-right: 8px;"></div>
                    <div>{{ slotProps.value.label }}</div>
                </div>
                <span v-else>
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <div :class="slotProps.option.color" style="height: 25px;width: 25px;margin-right: 8px;"></div>
                    <div>{{ slotProps.option.label }}</div>
                </div>
            </template>
        </Dropdown>
        </div>
        <div class="field">
            <label for="assignedTo">Asignar a:</label>
            <Dropdown id="assignedTo" v-model="selectedUsers" :options="userslist" optionLabel="name" placeholder="Selecciona usuarios" required="true" autofocus :class="{'p-invalid': submitted}" />
        </div>
        <div class="field">
            <label for="time">Fecha estimada</label>
            <Calendar id="time" v-model="date" dateFormat="dd/mm/yy"  />
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditVerification"/>
            <Button label="Guardar" :loading="loadingButton" icon="pi pi-check" class="p-button-text" @click="saveEditVerification" />
        </template>
    </Dialog>

   <!-- EDIT -->

    <!-- Menu Milestone Start -->
		<Dialog v-model:visible="milestonDialog" :style="{width: '450px'}" header="Crear nueva tarea" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Descripción</label>
				<InputText v-model="milestoneTitle" id="name" required="true" autofocus :class="{'p-invalid': submitted}" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideMilestone"/>
				<Button label="Guardar" :loading="loadingButton" icon="pi pi-check" class="p-button-text" @click="saveMilestone" />
			</template>
		</Dialog>
    <!-- Menu Milestone End -->

     <!-- Menu Milestone Start -->
		<Dialog v-model:visible="milestonEditDialog" :style="{width: '450px'}" header="Editar tarea" :modal="true" class="p-fluid">
			<div class="field">
				<label for="name">Descripción</label>
				<InputText v-model="milestoneEditTitle" id="name" required="true" autofocus :class="{'p-invalid': submitted}" />
				<!-- <small class="p-invalid" v-if="submitted">Nombre es obligatorio.</small> -->
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditMilestone"/>
				<Button label="Guardar" :loading="loadingButton" icon="pi pi-check" class="p-button-text" @click="updateMilestone" />
			</template>
		</Dialog>
    <!-- Menu Milestone End -->

</template>

<script>
	// import draggable from "vuedraggable";

    export default {
        props: {
            projectId: {
                type: String,
                default: null,
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            isEditor: {
                type: Boolean,
                default: false,
            },
            isReader: {
                type: Boolean,
                default: false,
            },
        },
		data() {
			return {
                changeStep: [],
                userslist: [],
                verificationSelected: null,
                drag: false,
                editVerificationDialog: false,
                statusOptionsSelected: {label: 'Empezado', value:'started', color: 'bg-green-500'},
                statusOptions: [
                    {label: 'Empezado', value:'started', color: 'bg-green-500'},
                    // {label: 'En proceso', value:'In_process', color: 'bg-orange-500'},
                    {label: 'Cerrado', value:'Closed', color: 'bg-gray-500'},
                ],
                verificationTitle: '',
                verificationEditTitle: '',
                selectedUsers: null,
                milestoneEditTitle: '',
                milestonEditDialog: false,
                loadingButton: false,
                verificationsList: [],
                list: [],
                date: null,
                submitted: false,
                indexVerification: null,
                indexMilestone: null,
                verificationDialog: false,
                verificationColors: [
                    {code: 'started', color: 'green-500'},
                    {code: 'In_process', color: 'orange-500'},
                    {code: 'Closed', color: 'gray-500'},
                ],
                tasksCreator: null,
                milestonDialog: false,
                idSelected: null,
            }
        },
        async mounted() {
            if (this.projectId) {
                await this.$store.dispatch('verification/getVerifications', { folder_id: this.projectId })
                    .then( async(data) => {
                        console.log(data.data)
                        data.data.forEach((e) => {
                            this.verificationsList.push(e);
                            this.changeStep.push(false);
                        })
                        this.loadingButton = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });
            }

            await this.$store.dispatch('user/getUsersList')
            .then( async(data) => {
                console.log('usuarios:',data)
                data.usersList.forEach(element => {
                    this.userslist.push({ name: element.name + ' ' + (element.lastname ?? ''), code: element.id })
                });
                console.log(this.userslist)
                this.pagination = data.pagination;
                this.isTableLloadingoading = false;
            })
            .catch((e) => {
                console.log(e)
                this.isLoading = false;
            });
        },
        methods: {
            async saveEditVerification() {
                this.editVerificationDialog = false;

                this.submitted = false;

                console.log(this.verificationsList);

                const position = this.verificationsList.indexOf(this.verificationsList.find(x => x.id === this.verificationSelected));

                console.log(position);
                this.verificationsList[position].title = this.verificationEditTitle;
                this.verificationsList[position].status = this.statusOptionsSelected?.value ?? {label: 'Empezado', value:'started', color: 'bg-green-500'};
                this.verificationsList[position].assignedto = this.selectedUsers?.code ?? null;

                console.log(this?.date)
                this.verificationsList[position].probablydate = this?.date;

                // console.log(new Date(this?.date))

                await this.$store.dispatch('verification/updateVerification', {
                    id: this.verificationsList[position].id,
                    title: this.verificationsList[position].title,
                    status: this.verificationsList[position].status,
                    assignedto: this.verificationsList[position].assignedto,
                    probablydate: this.verificationsList[position].probablydate,
                })
                .then( async(data) => {
                    console.log(data)
                    // Loading??

                    this.loadingButton = false;
                })
                .catch((e) => {
                    console.log(e)
                    // this.isLoading = false;
                    this.loadingButton = false;
                });
                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Datos actualizados', life: 3000 });
            },
            hideEditVerification() {
                this.editVerificationDialog = false;
            },
            async addTasks(id) {
                this.milestonDialog = true;
                this.milestoneTitle = '';
                this.idSelected = id;
            },
            async editVerification(verification) {
                console.log(verification)
                this.submitted = false
                this.verificationSelected = verification.id;
                this.verificationEditTitle = verification.title;
                this.selectedUsers = this.userslist.find(x => x.code === verification.assignedto);
                // falta que se resalte en calendario
                this.date = verification.probablydate ? new Date(verification.probablydate) : new Date();
                // console.log(typeof this.date)
                this.statusOptionsSelected = this.statusOptions.find(x => x.value === verification.status);
                this.editVerificationDialog = true;
            },  
            hideMilestone() {
                this.milestonDialog = false;
                this.idSelected = null;
            },
            hideEditMilestone(){
                this.milestonEditDialog = false;
                this.milestoneEditTitle = '';
            },
            async updateMilestone() {
                this.loadingButton = true;
                

                this.verificationsList[this.indexVerification].milestones[this.indexMilestone].title = this.milestoneEditTitle;
                await this.$store.dispatch('verification/updateMilestones', {data: this.verificationsList[this.indexVerification].milestones})
                .then( async(data) => {
                    console.log(data)
                    // Loading??

                    this.loadingButton = false;
                })
                .catch((e) => {
                    console.log(e)
                    // this.isLoading = false;
                    this.loadingButton = false;
                });

                this.milestonEditDialog = false;
                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Datos actualizados', life: 3000 });
            },  
            editMilestone(indexVerification, indexMilestone, item) {
                this.milestonEditDialog = true;
                this.submitted = false;
                this.indexVerification = indexVerification;
                this.indexMilestone = indexMilestone;
                this.milestoneEditTitle = item.title;
            },  
            async saveMilestone() {

                console.log(this.idSelected);
                
                const idUnique = new Date().valueOf();
                let verification = this.verificationsList.find(x => x.id === this.idSelected);


                // sin sentido
                if(verification) {
                    verification.milestones.push({
                        id: idUnique,
                        verificationsprocesses_id: this.idSelected,
                        title: this.milestoneTitle,
                        checked: false,
                        status: this.statusOptionsSelected,
                        file_id: this.projectId,
                        order: 1,
                    })
                } else {
                    verification.milestones = [{
                        id: idUnique,
                        verificationsprocesses_id: this.idSelected,
                        title: this.milestoneTitle,
                        status: 'started',
                        checked: false,
                        file_id: this.projectId,
                        order: 1,
                    }]
                }
                
                await this.$store.dispatch('verification/createMilestones', {data: {
                        id: idUnique,
                        verificationsprocesses_id: parseInt(this.idSelected),
                        title: this.milestoneTitle,
                        checked: false,
                        file_id: this.projectId,
                        order: 1,
                    }})
                    .then( async(data) => {
                        console.log(data)
                        
                        // Loading??
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });

                this.milestonDialog = false;
            }, 
            async deleteMilestone(indexMain, index, id) {
                await this.$store.dispatch('verification/deleteMilestone', {data: {id: parseInt(id), verificationsprocesses_id: parseInt(this.verificationsList[indexMain].id)}})
                    .then( async(data) => {
                        console.log(data)
                        // Loading??
                        this.verificationsList[indexMain].milestones.splice(index, 1)
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });
                this.$toast.add({ severity: 'success', summary: 'Borrado', detail: 'Datos Borrados', life: 3000 });
            },
			hideVerification() {
				this.verificationDialog = false;
				this.submitted = false;
			},
            async deleteVerification(index) {
                await this.$store.dispatch('verification/deleteVerification', {data: parseInt(this.verificationsList[index].id)})
                    .then( async(data) => {
                        console.log(data)
                        // Loading??
                        this.verificationsList.splice(index, 1)
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });
                this.$toast.add({ severity: 'success', summary: 'Borrado', detail: 'Datos Borrados', life: 3000 });
            },
            async changeSteps(index) {
				this.changeStep[index] = !this.changeStep[index];
                if(!this.changeStep[index]) {
                    await this.$store.dispatch('verification/updateMilestones', {data: this.verificationsList[index].milestones})
                        .then( async(data) => {
                            console.log(data)
                            // Loading??
                        })
                        .catch((e) => {
                            console.log(e)
                            // this.isLoading = false;
                    });
                    this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Datos actualizados', life: 3000 });
                }
			},
            async changeSatusMilestone(indexMain, index, id) {
                await this.$store.dispatch('verification/updateMilestonesChecked', {data: {
                    id: parseInt(id),
                    verificationsprocesses_id: parseInt(this.verificationsList[indexMain].id),
                    checked: this.verificationsList[indexMain].milestones[index].checked
                }}).then( async(data) => {
                        console.log(data)
                        // Loading??
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });
            },
			async saveVerification() {
				this.submitted = true;
				this.verificationDialog = false;
                this.loadingButton = true;

                await this.$store.dispatch('verification/createVerification', { title: this.verificationTitle, folder_id: this.projectId })
                    .then( async(data) => {
                        console.log(data)
                        this.verificationsList.push({
                            id: data.data.id,
                            title: this.verificationTitle, 
                            status: 'started',
                            folder_id: this.projectId,
                            milestones: [],
                        })
                        this.loadingButton = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        // this.isLoading = false;
                });

				this.$toast.add({severity:'success', summary: 'Tarea creada', detail:'Tarea creada correctamente', life: 3000});
			},
            openVerificationModal() {
                this.verificationTitle = '';
                this.submitted = false;
                this.verificationDialog = true;
			},
            toggle(index) {
                this.changeSteps(index);
            },
        },
        watch: {
            async projectId(){
                if (this.projectId) {
                    this.verificationsList = [];
                    this.changeStep = [];
                    await this.$store.dispatch('verification/getVerifications', { folder_id: this.projectId })
                        .then( async(data) => {
                            console.log(data.data)
                            data.data.forEach((e) => {
                                this.verificationsList.push(e);
                                this.changeStep.push(false);
                            })
                            this.loadingButton = false;
                        })
                        .catch((e) => {
                            console.log(e)
                            // this.isLoading = false;
                    });
                }
            }
        },  
        computed: {
            dragOptions() {
				return {
					animation: 200,
					group: "description",
					disabled: false,
					ghostClass: "ghost"
				};
			},
        },
        components: {
            // draggable
        }

    }
</script>

<style>
.color-chip-complete {
    background-color: #22C55E;
    color: white;
}

.p-panel .p-panel-header {
    color: white !important;
}

.p-panel.text-black .p-panel-header {
    color: black !important;
}

.p-panel-header-icon.p-panel-toggler {
    color: white !important;
}

.p-panel.text-black .p-panel-header .p-panel-header-icon.p-panel-toggler {
    color: black !important;
}

.p-panel.green-500 .p-panel-header {
    background: var(--green-500);
}

.p-panel.orange-500 .p-panel-header {
    background: var(--orange-500);
}

.p-panel.red-500 .p-panel-header {
    background: var(--red-500);
}

.p-panel.gray-500 .p-panel-header {
    background: var(--gray-500);
}
</style>